@import '~@palette/styles/colors.less';

.wrapper {
  .payout {
    display: flex;
    align-items: center;

    .percent {
      color: @palette-color-neutrals-grey2;
    }

    .amount {
      margin-left: .5rem;
    }
  }

  .pagination {
    margin-top: 4.4rem;
  }

  .emptyStateContainer {
    margin-top: 4rem;

    :global(.ant-empty-image) {
      height: 22rem;
    }
  }
}
