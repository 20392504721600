@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  .ruleNumber {
    .bodyBase1Bold();
    color: @palette-color-neutrals-grey2;

    margin-bottom: 1.6rem;
  }

  .description {
    .bodyBase2Medium();
    color: @palette-color-neutrals-dark;

    strong {
      .bodyBase2Bold();
      color: @palette-color-neutrals-dark;
    }

    mark {
      padding: 0.2rem;
      border-radius: 0.4rem;
      background-color: @palette-color-fill-white-grape;
    }

    .detailWrapper {
      margin-top: 1.4rem;
      display: flex;
      flex-direction: row;
      align-items: center;

      &:first-child {
        margin-top: 0;
      }

      .detailTitle {
        .bodyBase2Bold();
        color: @palette-color-neutrals-dark;
      }

      .detailValue {
        margin-left: 0.6rem;

        .bodyBase2Medium();
        color: @palette-color-neutrals-grey1;
      }

      &.detailFormulaWrapper {
        flex-direction: column;
        align-items: flex-start;

        .detailValue {
          margin-left: 0;
          margin-top: 0.5rem;
        }
      }
    }
  }
}
