@import '~@palette/styles/palette-theme.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  height: 100vh;
  max-width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin: auto;

  h1 {
    margin-top: 4rem;
    margin-bottom: 2rem;
  }

  .googleAuthButton {
    margin-top: 3rem;
  }

  .intermediaryTitle {
    margin-top: 3rem;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    max-width: 30.8rem;

    .dots {
      flex: 1;
      border-top: 0.1rem dotted @palette-color-neutrals-grey2;
    }

    .title {
      .caption1();
      color: @palette-color-neutrals-grey2;

      margin: 0 1rem;
    }
  }

  .passwordAuthWrapper {
    margin-top: 3rem;
    margin-bottom: 1rem;

    width: 100%;
    max-width: 30.8rem;
  }

  .registerLink {
    margin-top: 2rem;
  }

  @media(min-width: @screen-xl) {
    max-width: 110rem;
  }

  @media(min-width: @screen-xxl) {
    max-width: 140rem;
  }
}
