@import '~@palette/styles/typographies.less';

.wrapper {
  height: 100%;
  width: 100%;
  padding-top: 4rem;

  .breadcrumb {
    margin-left: 4rem;
  }

  .titleWithCurrency {
    padding: 3.3rem 4rem 2.2rem 4rem;

    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;

    .title {
      padding: 0;
    }

    .currencySelect {
      min-width: 16.5rem;
    }
  }

  .container {
    position: relative;
    padding: 0 4rem 4rem;

    .navigation {
      margin-bottom: 2.6rem;
    }
  }
}
