@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';
@import '~@palette/styles/mixins.less';
@import '~@palette/styles/palette-theme.less';

.wrapper {
  width: 100%;
  background-color: transparent;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;

  .cellContent {
    .bodyBase2Medium();
    color: @palette-color-neutrals-grey1;

    .falseValueIcon {
      .colorSvg(@palette-color-secondary-red);
      min-width: 1.8rem;
    }

    .trueValueIcon {
      .colorSvg(@palette-color-primary-teal);
      min-width: 1.8rem;
    }

    .valueArrayItem + .valueArrayItem {
      margin-top: 0.7rem
    }

    .emptyList {
      .bodyBase2MediumItalic();
      color: @palette-color-neutrals-grey1;
    }
  }

  .actions {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    .showHistoryBtn {
      margin-left: 0.4rem;
    }
  }

  @media(min-width: @screen-lg-min) {
    .actions {
      display: none;
    }

    &:hover {
      .actions {
        display: flex;
      }
    }
  }
}
