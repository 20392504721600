@import '~@palette/styles/mixins.less';

.wrapper {
  .widgetSizeMedium();
  padding-bottom: .4rem;

  .content {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    overflow-x: auto;
    width: calc(100% + 1rem);
    margin-left: -.5rem;
    flex: 1;
    padding-bottom: 2rem;

    .emptyState {
      margin: 0 auto;
    }
  }
}
