@import '~@palette/styles/colors.less';
@import '~@palette/styles/mixins.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  overflow-y: auto;
  height: 100%;
  padding-bottom: 4rem;

  .list {
    .listItem {
      padding: 1.2rem;
      border-radius: @border-radius-base;
      cursor: pointer;
      .ellipsis();

      + .listItem {
        margin-top: .8rem;
      }

      .bodyBase1Medium();
      color: @palette-color-neutrals-dark;

      &:hover {
        background-color: @palette-color-fill-sky;
      }
    }
  }
}
