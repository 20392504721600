@import '~@palette/styles/typographies.less';
@import '~@palette/styles/colors.less';

.wrapper {
  overflow: auto;
  border: 0.2rem solid @palette-color-border-normal;
  border-radius: 1.2rem;
  padding: 0.3rem 0.3rem 0.3rem 1.6rem;

  &.isOpen {
    padding-bottom: 1.6rem;
  }

  .displayedQueryWrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    .displayedQuery {
      align-self: center;
      width: calc(100% - 12rem);

      .bodyBase2MediumItalic();
      color: @palette-color-neutrals-grey2;
    }
  }

  .editor {
    margin-top: 1.5rem;
  }
}
