@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  .tabs {
    width: 90%;
    margin: 0 auto;

    :global(.ant-tabs-nav) {
      margin-bottom: 1.6rem;
    }
  }
}
