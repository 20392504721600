@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.modal {
  :global(.ant-modal) {
    max-width: 60rem;
  }

  .titleWrapper {
    .headlineH2-2();
    color: @palette-color-neutrals-dark;
  }

  .details {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2.8rem;

    .chevronRight {
      .colorSvg(@palette-color-neutrals-grey1);
    }
  }

  .listWrapper {
    margin-bottom: 1.6rem;

    .laoderIcon {
      .colorSvg(@palette-color-secondary-light-blue);
    }

    .table {
      width: 98%;
      margin: 0 auto;

      .cell {
        padding: .8rem 1.4rem;
        width: 100%;

        &.dateCell {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          .bodyBase2Medium();
          color: @palette-color-neutrals-grey1;

          .tagCurrent {
            margin-left: 1rem;
          }
        }
    
        &.rateCell {
          .bodyBase2Bold();
          color: @palette-color-neutrals-dark;
        }
  
        &.trashCell {
          display: flex;
          justify-content: center;

          .icon {
            height: auto;
            width: auto;
          }
        }
      }
    }
  }

  .newRateButton {
    height: auto;
    padding: 0;
    margin: 1.6rem auto 0;
  }
}
