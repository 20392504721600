.ColumnEditor_wrapper {
  .ant-table .ant-table-container .ant-table-content {
    overflow: auto;

    .ant-table-tbody > tr > td.ant-table-cell {
      &:first-child, &:last-child {
        min-width: auto;
      }
    }
  }

  .ColumnEditor_tag {
    margin-right: 4px;
    color: #0A1F38;
    background-color: #F5F5F5;
    border: 1px solid #F0F0F0;
    display: flex;
    align-items: center;
    height: 24px;
    line-height: 22px;
    font-size: 14px;

    .ant-tag-close-icon .anticon {
      display: flex;
      align-items: center;

      svg * {
        fill: #878787 !important;
      }
    }
  }

  .ColumnEditor_delete_wrapper {
    display: flex;
    justify-content: space-between;

    button {
      display: flex;
      align-items: center;

      svg * {
        fill: #878787 !important;
      }
    }
  }
}
