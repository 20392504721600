@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  .prefix {
    .bodyBase2Medium();
    color: @palette-color-neutrals-grey1;
  }

  .condition {
    margin-left: 0.2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    .bodyBase2Medium();
    color: @palette-color-neutrals-grey1;
  }

  .variableName {
    margin-left: 0.2rem;
    .bodyBase2Bold();
    color: @palette-color-neutrals-dark;
  }

  .formula {
    margin-left: 0.2rem;
    .bodyBase2Bold();
    color: @palette-color-neutrals-grey1;
  }
}
