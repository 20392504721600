@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';
@import '~@palette/styles/mixins.less';

.wrapper {
  position: relative;
  padding: 0 4rem 4rem;

  .customizeDashboardWrapper {
    position: relative;
    padding: 2rem 3rem;
    border: 0.1rem solid @palette-color-border-light;
    border-radius: 1.6rem;
    background-color: @palette-color-fill-cultured;
    margin-bottom: 2.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    overflow: hidden;

    .customizeDashboardIcon {
      position: absolute;
      left: 0;
      bottom: -14rem;
    }

    .customizeDashboardCloseBtn {
      position: absolute;
      top: 0;
      right: 0;
    }

    .customizeDashboardTitle {
      .headlineH2-2();
      color: @palette-color-neutrals-dark;
      z-index: 2;
    }

    .customizeDashboardDescription {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      z-index: 2;

      .customizeDashboardDescriptionPart {
        margin-top: 1.5rem;

        .bodyBase1Medium();
        color: @palette-color-neutrals-dark;
      }

      .customizeDashboardCTA {
        margin-top: 2rem;
      }
    }
  }

  .customizeDashboardInlineWrapper {
    display: flex;
    justify-content: flex-end;
  }

  .widgets {
    .widgetsGrid();
  }
}
