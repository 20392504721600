@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 2rem 1.5rem;
  border: 0.1rem solid @palette-color-border-light;
  border-radius: 1.6rem;

  width: 31rem;

  .roleAndMoreMenu {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 13.3rem;

    .teamLeadTag {
      margin-left: 1rem;
    }

    .moreMenuWrapper {
      margin-left: 1rem;
      width: 2.4rem;

      .moreMenu {
        display: none;
      }

      &.moreMenuIsOpen {
        .moreMenu {
          display: block;
        }
      }
    }
  }

  &:hover {
    border-color: @palette-color-shades-navy-sky-blue;

    .roleAndMoreMenu {
      .moreMenuWrapper {
        .moreMenu {
          display: block;
        }
      }
    }
  }
}
