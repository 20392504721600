.wrapper {
  height: 100%;
  width: 100%;

  .titleWrapper {
    flex-flow: row wrap;
    justify-content: space-between;

    .titlesContainer {
      display: flex;
      flex-direction: column;
      margin-right: 1.6rem;
      margin-bottom: 1.6rem;
    }

    .actions {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 1.6rem;

      .newRoleBtn {
        margin-left: 1.6rem;
      }
    }
  }
}
