@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';
@import '~@palette/styles/palette-theme.less';

.wrapper {
  .header {
    margin-bottom: 2.6rem;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;

    .title {
      width: 100%;
      margin-bottom: 1.4rem;
      color: @palette-color-neutrals-dark;
      .title2Medium();
    }

    .filters {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      flex: 1;

      .userSearch {
        width: 100%;
        max-width: 28rem;
        margin-right: 1.2rem;
        margin-bottom: 1rem;

        @media(max-width: @screen-xs-max) {
          max-width: 100%;
          margin-right: 0;
        }
      }
  
      .selector {
        width: 20rem;
        margin-right: 1.2rem;
        margin-bottom: 1rem;

        @media(max-width: @screen-xs-max) {
          width: 100%;
          max-width: 100%;
          margin-right: 0;
        }
      }
    }

    .actions {
      display: flex;
      flex-flow: row wrap;
      align-items: center;

      @media(max-width: @screen-xs-max) {
        justify-content: center;
      }

      .actionButton {
        margin-bottom: 1rem;

        &:not(:last-child) {
          margin-right: 1rem;
        }
      }
    }
  }
}
