@import '~@palette/styles/colors.less';

.wrapper {
  position: relative;
  width: 100%;

  .editWrapper {
    position: absolute;
    bottom: -1.2rem;
    left: -1.2rem;
    background-color: @palette-color-neutrals-white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .6rem;
    margin: 0;
    height: auto;
    width: auto;

    &.editWrapperBig {
      bottom: -.2rem;
      left: -.2rem;
    }

    &:hover, &:focus, &:active {
      background-color: @palette-color-neutrals-white;
    }
  }
}
