@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  border: 0.1rem solid @palette-color-border-light;
  border-radius: 1.8rem;

  display: flex;
  flex-direction: column;

  .paymentsNb {
    border-bottom: 0.1rem solid @palette-color-border-light;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    padding: 2rem;

    .nb {
      margin-left: 1rem;

      .title1Bold();
      color: @palette-color-neutrals-dark;
    }

    .label {
      margin-left: 0.6rem;
      padding-top: 0.5rem;

      .caption1();
      color: @palette-color-neutrals-grey1;
    }
  }

  .splits {
    display: flex;
    flex-direction: row;

    .paymentsDone {
      flex: 1;
      border-right: 0.1rem solid @palette-color-border-light;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      padding: 2rem;
    }

    .paymentsToBeDone {
      flex: 1;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      padding: 2rem;
    }

    .splitInfo {
      margin-left: 1rem;

      display: flex;
      flex-direction: column;

      .amount {
        .title1Bold();
        color: @palette-color-neutrals-dark;
      }

      .label {
        margin-top: 0.2rem;

        .caption1();
        color: @palette-color-neutrals-grey1;
      }

      .paymentsPercentage {
        margin-top: 0.2rem;

        .caption1();
        color: @palette-color-neutrals-grey2;
      }
    }
  }
}
