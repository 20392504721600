@import '~@palette/styles/colors.less';
@import '~@palette/styles/fonts.less';
@import '~@palette/styles/mixins.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  position: relative;

  .chartWrapper {
    width: 100%;
    padding-bottom: 50%;
    position: relative;

    .chart {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 200%;
    }
  }

  .textualProgressWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);

    &::before {
      content: ' ';
      position: absolute;
      width: 16rem;
      height: 8rem;
      background-color: rgba(255, 255, 255, 0.6);
      border-top-left-radius: 110px;
      border-top-right-radius: 110px;
      border-bottom: 0;
      bottom: 0;
      z-index: -1;
    }

    .percentageProgress {
      .bodyBase1Bold();
      color: @palette-color-neutrals-dark;

      &.gradient_green {
        color: @palette-color-secondary-light-teal;
      }

      &.gradient_orange {
        color: @palette-color-secondary-brown-yellow;
      }

      &.gradient_red {
        color: @palette-color-banners-red;
      }
    }

    .valueDefinition {
      margin-top: .7rem;
      max-width: 14rem;
      .ellipsis();

      .bodyBase1Bold();
      color: @palette-color-neutrals-dark;
    }

    .target {
      margin-top: 0.2rem;

      .bodyBase2Medium();
      color: @palette-color-neutrals-grey1;

      span + span {
        margin-left: 0.5rem;
      }

      span:first-child {
        font-weight: @palette-font-weight-bold;
      }
    }
  }
}
