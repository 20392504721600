.wrapper {
  .titleActionWrapper {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
  }

  .emptyState {
    margin-left: auto;
    margin-right: auto;
  }


  .list {
    margin-top: 2rem;

    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;

    .resourceObjectItem {
      margin: 0.5rem;
    }
  }
}
