@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  height: 100%;
  width: 100%;

  .titleWrapper {
    justify-content: space-between;

    .titlesContainer {
      display: flex;
      flex-direction: column;

      h2 {
        margin-top: 0.5rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        color: @palette-color-neutrals-dark;
        .title2Medium();
      }
    }

    .newNotebookBtn {
      margin-left: 1.6rem;
    }
  }
}
