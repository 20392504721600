.MyStatement {
  &.div-wrapper {
    padding-top: 0px;
}
}

.Shape_wrapper{
  position: absolute;
  top: auto;
  bottom: -1px;
  left: 0;
  right: 0;
  width: 100%;
  height: 70px;
  z-index: 1;
  transform: translateZ(0);

  .nectar-shape-divider {
    fill: #fff;
    width: 100%;
    left: 0;
    bottom: -1px;
    height: 100%;
    position: absolute;
    overflow: hidden;
  }
}
.My_statement_period_select_shadow{
  box-shadow:0px 4px 7px 0px #dadada4a;
}
.My_statement_personal{
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 400px;
  max-width: 100%;
  margin: auto;
  padding-bottom: 20px;
}
.My_statement_list_top{
  padding: 12px 24px;
  text-align: center;
  display: flex;
  height:100%;
  flex-direction: column;
  justify-content: center;
  .Statement_validation_buttons_commissions{
    display: flex;
  }

  &:hover{
    background-color: #f5f5f5;
  }

  .My_statement_list_top_month{
    text-transform: capitalize;
  }
  .My_statement_list_top_value{
    font-weight: bold;
  }

  &.My_statement_list_top_selected{
    background: #48b1b521;
    border-bottom: 2px solid #48b1b5;
  }
  &.My_statement_list_top_icon{
    cursor: pointer;
    & > div {
      line-height: 44px;
    }
  }
}

@media screen and (max-width: 500px) {
  .My_statement_personal{
   flex-wrap: wrap;
  }
  .Not_under_400{
    display: none !important;
  }
}
