@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';
@import '~@palette/styles/palette-theme.less';

.wrapper {
  padding: .5rem 2rem .5rem .5rem;
  border-radius: @border-radius-base;
  border: 0.1rem solid @palette-color-border-normal;
  background-color: @palette-color-fill-table-group;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .loadingWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    .loadingIcon {
      .colorSvg(@palette-color-primary-blue);
    }

    .loadingLabel {
      margin-left: 1rem;

      .bodyBase2Bold();
      color: @palette-color-primary-blue;
    }
  }

  .neverRunLabel {
    .bodyBase2Medium();
    color: @palette-color-neutrals-dark;
  }
}
