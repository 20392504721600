@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .header {
    flex: none;

    display: flex;
    align-items: center;

    .input {
      margin-right: 1.3rem;
    }
  }

  .results {
    flex: 1;
    margin-top: 3rem;

    overflow: auto;

    .result {
      margin-top: 1.3rem;
    }

    .seeAllbtn {
      margin: 2rem auto 0;
    }

    .empty {
      display: flex;
      justify-content: center;

      .bodyBase2Medium();
      color: @palette-color-neutrals-grey1;
    }
  }
}