@import '~@palette/styles/colors.less';
@import '~@palette/styles/palette-theme.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  .collapseContainer {
    margin-bottom: 2rem;

    .title {
      margin-bottom: 1rem;
      .bodyBase2Bold();
      color: @palette-color-neutrals-dark;
    }

    :global(.ant-collapse-item) {
      :global(.ant-collapse-header) {
        padding: 1.5rem 2rem .5rem 2rem;

        :global(> div:first-child) {
          margin-bottom: 1rem;
        }
      }
    }

    .extraDetails {
      margin-left: 1rem;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;

      .colRow {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: flex-end;

        .actions {
          margin-left: 2rem;
          margin-bottom: 1rem;

          .addAdjustmentButton {
            padding: 0;
            height: auto;
          }
        }

        .totalWrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-left: 2.8rem;
          margin-bottom: 1rem;

          .totalLabel {
            .bodyBase2Medium();
            color: @palette-color-neutrals-grey1;
          }

          .totalAmount {
            margin-left: .6rem;

            .bodyBase2Bold();
            color: @palette-color-neutrals-dark;
          }
        }
      }
    }

    .commissionsListHeader {
      margin-bottom: 1.4rem;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;

      @media(max-width: @screen-xs-max) {
        display: none;
      }

      .colRow {
        flex: 1;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        margin-left: 1rem;
        color: @palette-color-neutrals-grey2;
        .bodyBase2Medium();

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          min-width: 30rem;
        }
      }
    }
  }
}
