@import '~@palette/styles/palette-theme.less';

.wrapper {
  position: relative;
  padding: 0 4rem 4rem;

  .actionsContainer {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    position: absolute;
    top: -9.2rem;
    right: 4rem;

    @media(max-width: @screen-md-max) {
      position: relative;
      top: auto;
      right: auto;
    }

    .filterSelect {
      width: 24rem;
      margin-bottom: 2rem;

      &:not(:last-child) {
        margin-right: 2rem;
      }
    }
  }
}
