.wrapper {
  .account {
    margin-top: 5rem;
  }

  .linkedResources {
    margin-top: 5rem;
  }

  .offboarding {
    margin-top: 5rem;
  }
}
