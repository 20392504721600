@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  position: relative;
  padding: 0 4rem;
  margin-bottom: 4rem;

  .highlightZone {
    margin-top: 2.8rem;
  }

  .periodsList {
    margin-top: 2.8rem;
  }
}
