@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.modal {
  :global(.ant-modal) {
    max-width: 60rem;
  }

  .titleWrapper {
    .headlineH2-2();
    color: @palette-color-neutrals-dark;
  }

  .totalCommissionsWrapper {
    position: relative;
    margin-bottom: 3.2rem;
    padding: 1.4rem 0;
    background-color: @palette-color-fill-lavender;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 1.4rem;
    overflow: hidden;

    .illustration {
      position: absolute;
      top: -1rem;
      right: 0;
    }

    .label {
      margin-bottom: .6rem;
      color: @palette-color-secondary-lavender;
      .caption1();
    }

    .amount {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      margin-bottom: .6rem;
      color: @palette-color-neutrals-dark;
      .headlineH2();

      .integerPart {
        color: @palette-color-neutrals-dark;
        .headlineH2();
      }

      .decimalPart {
        color: @palette-color-neutrals-dark;
        .bodyBase1Medium();
      }
    }

    .period {
      color: @palette-color-neutrals-dark;
      .caption1();

      .periodName {
        margin-right: .6rem;
        .caption1Bold();
      }
    }
  }

  :global(.ant-form-item-has-error) {
    :global(.ant-input-number),
    :global(.ant-select) {
      border-color: @palette-color-border-input-error;
    }
  }
}
