@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';
@import '~@palette/styles/palette-theme.less';

.wrapper {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;

  .actionLink {
    color: @palette-color-primary-blue;

    .actionIcon {
      .colorSvg(@palette-color-primary-blue);
    }
  }

  .actionDeleteLink {
    color: @palette-color-secondary-red;
    height: auto;
    padding: 0;

    .actionIcon {
      .colorSvg(@palette-color-secondary-red);
    }

    &:hover {
      color: @palette-color-secondary-red;

      .actionIcon {
        .colorSvg(@palette-color-secondary-red);
      }
    }
  }

  .header {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.5rem;

    .paymentLabelWrapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;

      .paymentLabel {
        .bodyBase2Medium();
        color: @palette-color-neutrals-dark;
        margin-right: .5rem;
      }
    }
  }

  .inputArea {
    display: flex;
    flex-flow: column nowrap;
    background-color: @palette-color-fill-antiflash-white;
    border-radius: @border-radius-base;
    padding: 1.5rem;
    margin-bottom: 2rem;

    .row {
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-start;
      flex: 1;

      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }

      &.noMarginBottom {
        margin-bottom: 0;
      }

      .label {
        .inputLabel();
        color: @palette-color-neutrals-grey1;
        margin-top: 1rem;
        margin-right: 1rem;
        flex: 1;
        text-align: right;
      }

      .field {
        display: flex;
        flex-flow: column nowrap;
        flex: 2;

        .inputField {
          background-color: @palette-color-neutrals-white;
        }

        .inputFieldError {
          .bodyBase2Medium();
          color: @palette-color-secondary-red;
          margin-top: 0.5rem;
        }

        .disclaimer {
          .bodyBase2Medium();
          color: @palette-color-neutrals-grey2;
          margin-top: .5rem;
        }

        .error {
          .bodyBase2Medium();
          color: @palette-color-secondary-red;
          margin-top: 0.5rem;
        }
      }
    }
  }
}
