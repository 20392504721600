.White_vignette_wrapper{
  padding: 40px;
  margin: 12px;
  background: white;
  width: 640px;
  max-width: 100%;
  margin: auto;
  box-shadow: 4px 5px 16px 2px #0000001f;
  border-radius: 4px;
  margin-top: 24px;
}
.QuickBooksDisconnected_page{
  min-height: 100%;
  height: 100%;
  padding-bottom: 200px;
}