@import '~@palette/styles/colors.less';

.wrapper {
  display: flex;
  flex-direction: column;

  .loadingContainer {
    display: flex;
    justify-content: center;

    .laoderIcon {
      .colorSvg(@palette-color-secondary-light-blue);
    }
  }

  .contentWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    .form {
      width: 100%;
      border-bottom: .1rem solid @palette-color-border-light;
      margin-bottom: 2rem;
    }
  }
}
