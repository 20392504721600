@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  border-top: 0.1rem solid @palette-color-border-normal;
  padding-top: 4rem;

  .globalResults {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .resultWrapper {
      + .resultWrapper {
        margin-left: 4rem;
      }

      display: flex;
      flex-direction: column;
      align-items: center;

      .resultLabel {
        .bodyBase1Medium();
        color: @palette-color-neutrals-grey1;
      }
    }
  }

  .result {
    margin-top: 0.5rem;

    .title2Bold();
    color: @palette-color-neutrals-dark;

    &.warning {
      color: @palette-color-secondary-red;
    }

    &.success {
      color: @palette-color-secondary-white-grape;
    }
  }

  .table {
    margin-top: 4rem;
  }
}
