@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  .title2Bold();
  color: @palette-color-neutrals-dark;

  &.emptyDescription {
    .bodyBase2Medium();
    color: @palette-color-neutrals-grey2;
  }

  &:hover {
    cursor: pointer;
    background-color: @palette-color-fill-sky;
  }
}

.input {
  width: auto;
  .title2Bold();
  color: @palette-color-neutrals-dark;
  height: 3.9rem;

  margin-top: -0.75rem;
  margin-bottom: -0.75rem;
}
