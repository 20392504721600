@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';
@import '~@palette/styles/palette-theme.less';

.wrapper {
  display: flex;
  flex-direction: row;

  .formWrapper {
    flex: 1;

    .formItemLabel {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: .7rem;

      mark {
        margin: 0 0.5rem;
        padding: 0.2rem 0.5rem;
        background-color: @palette-color-fill-lavender;
        font-weight: @palette-font-weight-bold;
      }
    }

    .filterObjectFormItem {
      transition: none;
      &.filterObjectFormItemSticked {
        margin-bottom: 0;
      }
    }

    .useCustomCodeBtn {
      padding: 0;
      height: 1.8rem;
      margin: 0.3rem auto 2.8rem auto;
    }

    .filterFormulaExtra {
      height: 1.8rem;

      display: flex;
      flex-direction: row;
      align-items: center;

      .filterFormulaExtraBtn {
        padding: 0;
        height: 1.8rem;
        margin-left: 0.5rem;
      }
    }
  }

  .queryBuilderContainer {
    min-width: 44rem;

    @media(min-width: @screen-xl-min) {
      max-width: 55rem;
    }

    @media(min-width: @screen-xxl-min) {
      max-width: 74rem;
    }
  }

  .resourceObjectSample {
    margin-left: 2rem;

    @media(min-width: @screen-xl-min) {
      margin-left: 1rem;
      width: 20rem;
    }

    @media(min-width: @screen-xxl-min) {
      margin-left: 2rem;
      width: 28rem;
    }
  }
}
