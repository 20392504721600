.wrapper {
  .description {
    margin: 2rem auto;

    width: 70%;
  }

  .resetLeaveDateBtn {
    margin: auto;
  }
}
