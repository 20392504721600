@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  .badge {
    margin-left: 1rem;

    :global(.ant-badge-count) {
      .caption1();
      color: @palette-color-shades-sea-blue;
      padding: 0.2rem 1rem;
      box-shadow: none;
      background-color: @palette-color-shades-navy-sky-blue;
    }
  }

  &:hover {
    .badge {
      :global(.ant-badge-count) {
        background-color: @palette-color-neutrals-white;
      }
    }
  }
}