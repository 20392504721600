.wrapper {
  height: 2.6rem;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  .item {
    &:not(:last-child) {
      margin-right: 2.4rem;
    }
  }
}
