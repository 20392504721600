@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';
@import '~@palette/styles/mixins.less';

.wrapper {
  display: flex;
  flex-direction: column;
  max-height: 34rem;
  overflow-y: auto;

  .resourceCardWrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin: .2rem 0;
    padding: .4rem 1.4rem;
    border-radius: .8rem;
    cursor: pointer;

    .iconWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 3.4rem;
      height: 3.4rem;
      margin-right: .6rem;

      .icon {
        .colorSvg(@palette-color-neutrals-grey1);
      }
    }

    .label {
      .bodyBase1Medium();
      color: @palette-color-neutrals-grey1;
      margin-right: .8rem;
      flex: 1;
      .ellipsis();
    }

    .checkedIcon {
      .colorSvg(@palette-color-primary-blue);
    }

    &:hover {
      background-color: @palette-color-fill-sky;

      .label {
        color: @palette-color-primary-blue;
      }

      .iconWrapper {
        .icon {
          .colorSvg(@palette-color-primary-blue);
        }
      }
    }

    &.selected {
      background-color: @palette-color-fill-sky;

      .label {
        color: @palette-color-primary-blue;
      }

      .iconWrapper {
        .icon {
          .colorSvg(@palette-color-primary-blue);
        }
      }
    }

    &.disabled {
      cursor: not-allowed;
    }
  }
}

:global(.ant-form-item-has-error) {
  .wrapper {
    .connectorCardWrapper {
      border-color: @palette-color-border-input-error;

      .checkedIcon {
        .colorSvg(@palette-color-border-input-error);
      }
    }
  }
}
