@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  position: relative;
  height: 5rem;
  padding-top: 0;
  transition: height 300ms ease, padding-top 300ms ease;

  .labelWrapper {
    display: flex;
    align-items: center;

    .label {
      margin-left: 0.6rem;

      .inputLabel();
      color: @palette-color-neutrals-grey2;

      cursor: text;
    }
  }

  .commentFormItem {
    position: relative;
    margin-bottom: 0;

    :global(.ant-form-item-label) {
      position: absolute;
      top: 50%;
      left: 1.6rem;
      transform: translateY(-50%);
      z-index: 1;
      margin-top: 0.4rem;

      transition: position 300ms ease 300ms, top 300ms ease, left 300ms ease, transform 300ms ease, margin-top 300ms ease;
    }
  }

  .textArea {
    height: 5rem;

    color: transparent;
    &::placeholder {
      color: transparent;
    }
    &::selection {
      background-color: transparent;
    }

    transition: height 300ms ease, color 300ms ease;
  }

  .submitBtn {
    display: none;

    position: absolute;
    bottom: 1.6rem;
    right: 1.6rem;
    z-index: 1;
  }

  &.open {
    height: 16.9rem;
    padding-top: 3.3rem;

    .labelWrapper {
      .label {
        color: @palette-color-neutrals-grey1;

        cursor: default;
      }
    }

    .commentFormItem {
      :global(.ant-form-item-label) {
        top: -3.3rem;
        left: 0;
        transform: translateY(0);
        margin-top: 0;
      }
    }

    .textArea {
      height: 13.6rem;

      color: inherit;
      &::placeholder {
        color: inherit;
      }
      &::selection {
        background-color: @palette-color-primary-blue;
      }
    }

    .submitBtn {
      display: flex;
    }
  }
}
