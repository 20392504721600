@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';
@import '~@palette/styles/palette-theme.less';
@import '~@palette/styles/mixins.less';

.wrapper, :global(.ant-menu-submenu-popup .ant-menu-sub) {
  display: flex;
  flex-direction: column;

  :global(.ant-menu-item-group:last-child) {
    margin-top: auto;
  }

  :global(.ant-menu-item-group-title) {
    .bodyBase1Medium();
    color: @palette-color-shades-navy-cloudy-blue;
    padding: 0;
  }

  :global(.ant-menu-item-icon) {
    .colorSvg(@palette-color-shades-navy-sky-blue);
  }

  :global(.ant-menu-title-content) {
    .bodyBase1Medium();
    color: @palette-color-shades-navy-sky-blue;
  }

  :global(.ant-menu-submenu) {
    :global(.ant-menu-submenu-title) {
      padding-left: 1rem !important;
      margin: 0 0 .8rem 0;
    }

    :global(.ant-menu-sub) {
      border-left: 0.1rem solid @palette-color-shades-light-sea-blue;
      margin-left: 1.8rem;
      padding-right: 1.8rem;
      padding-left: 1.8rem;
    }
  }

  :global(.ant-menu-item), :global(.ant-menu-item-active) {
    padding: 1rem !important;
    border-radius: @border-radius-base;
    margin: 0 0 .8rem 0;

    &:global(.ant-menu-item-selected) {
      background-color: @palette-color-shades-light-sea-blue !important;

      :global(.ant-menu-item-icon) {
        .colorSvg(@palette-color-neutrals-white);
      }

      :global(.ant-menu-title-content) {
        color: @palette-color-neutrals-white;
      }
    }
  }

  :global(.ant-menu-item-active), :global(.ant-menu-submenu-active) {
    :global(.ant-menu-item-icon) {
      .colorSvg(@palette-color-neutrals-white);
    }

    :global(.ant-menu-title-content) {
      color: @palette-color-neutrals-white;
    }
  }

  &:global(.ant-menu-inline-collapsed) {
    :global(.ant-menu-item), :global(.ant-menu-submenu-title) {
      :global(.ant-menu-item-icon) {
        display: contents;
      }
    }

    :global(.ActionsMenuProfileIcon) {
      .center(~':global(.ant-avatar)');
    }
  }
}

:global(.ant-menu-submenu-popup .ant-menu-sub) {
  :global(.ant-menu-item) {
    display: flex;
  }
}
