@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  .header {
    margin-bottom: 2rem;

    .title {
      .title1Bold();
      color: @palette-color-neutrals-dark;
    }
  
    .subtitle {
      .bodyBase2Medium();
      color: @palette-color-neutrals-dark;
    }
  
    .formulaHelper {
      margin-top: 2rem;
    }
  }

  .dealVariablesWrapper {
    border: 0.2rem solid @palette-color-fill-antiflash-white;
    border-radius: 1rem;
    padding: 1.5rem;

    .dealVariablesTitle {
      .title2Bold();
      color: @palette-color-neutrals-dark;
    }

    .dealVariablesSubtitle {
      .bodyBase2Medium();
      color: @palette-color-neutrals-dark;
    }

    .variablesFormWrapper {
      margin-top: 1.5rem;

      display: flex;
      flex-flow: column nowrap;
    }
  }

  .valueFormulaWrapper {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .valueFormulaFormItem {
      width: 100%;

      &.noMarginBottom {
        margin-bottom: 0;
      }
    }
  }

  .actions {
    display: flex;
    flex-direction: column;
    align-items: center;

    .submitBtn {
      width: 100%;
      justify-content: center;
    }
  }
}
