@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: fit-content;
  border: .1rem dashed @palette-color-primary-blue;
  padding: 1rem 1.5rem;
  background-color: @palette-color-neutrals-white;
  border-radius: 1rem;
  opacity: 0.6;

  .icon {
    .colorSvg(@palette-color-neutrals-dark);
  }

  .name {
    margin-left: 0.8rem;
    .bodyBase2Medium();
    color: @palette-color-neutrals-dark;
  }
}
