@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';
@import '~@palette/styles/palette-theme.less';

.wrapper {
  .container {
    display: flex;
    align-items: flex-start;
    flex-flow: row wrap;
    column-gap: 2.4rem;
  
    .row {
      flex: 1;
      min-width: 40rem;
      order: 2;

      @media(max-width: @screen-md-max) {
        &.orderFirst {
          order: 1;
        }
      }

      .buttonSuppContainer {
        margin-bottom: 2.8rem;

        .suppButton {
          height: auto;
          padding: .8rem 0 0;
        }
      }
    }
  }
}
