.login-form-forgot {
  float: right;
}
.login-form-button {
  width: 100%;
}
.Login_form_wrapper{
  padding: 24px;
  background: white;
  max-width: 100%;
  width: 360px;
  border-radius: 16px;
  margin: 12px;
}
.Phone_number_register.ant-input-group.ant-input-group-compact{
  display: flex;
}
.Phone_number_register{
  display: flex;
}
.Login_form_logo_wrapper{
  width: 100%;
  padding: 20px;
  text-align: center;
  margin: auto;

  padding-top: 33px;
  padding-bottom: 55px;
}
.Login_form_logo{
  width: 140px;
  max-width: 100%;
  text-align: center;
  margin: auto;
}
.Login_global_wrapper{
  width: 100%;
  min-height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  // background: #7070fd;
  background:linear-gradient(0deg, #7DE2E4 7%, #FFFFFF 100%);
  width: 100%;
}
.Login_register_link{
  margin-top: 12px;
}
.Login_register_link, .Login_forgot_link {
  text-align:center;
  display: block;
  line-height: 26px;
}
.Valide_email_loading_icon{
  font-size: 40px;
}
.Valide_email_error_icon{
  font-size: 40px;
  color: red;
}
.Register_form_double{
  display: flex;

  background: white;
  max-width: 100%;
  border-radius: 16px;
  margin: 12px;

  .Register_form_one{
    width: 360px;
    padding: 24px;
    max-width: 100%;
    overflow: hidden;

    &:first-child{
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-right: 1px solid rgba(0,0,0,.08);
    }
  }
}
.Register_quote_wrapper{
  font-size: 25px;
  position: relative;
  margin: 20px;
  margin-top: 40px;
}
.Register_quote_icon{
  position: absolute;
  opacity: 0.2;
  font-size: 60px;

  &.Register_quote_icon_top{
    top: -68px;
    left: -12px;
  }
  &.Register_quote_icon_bottom{
    bottom: -68px;
    right: -12px;
  }

}
.Register_quote_credits {
  padding-top: 60px;
}
.Register_quote_credits, .Register_quote_credits_company{
  font-size: 12px;
}
.Register_quote_credits_company{
  color: grey;
}
.Login_global_global{
  width: 100%;
    height: 100%;
    position: absolute;

}

form:not(.ant-form-vertical) :not(.ant-input-group-wrapper) > .ant-input-group, form:not(.ant-form-vertical) .ant-input-group-wrapper{
  display: flex;
}
.Password_input_register{
  &.ant-form-item{
    margin-bottom:4px;
  }
}
@media screen and (max-width: 800px) {
  .login_wrapper {
      margin-right: auto;
      margin-left: auto;
  }
  .login_inner_wrapper {
      padding: 30px 20px 40px;
  }
  .login_logo_pic{
      width: 80px;
  }
  .Register_form_double{
    flex-wrap: wrap-reverse;
    width: 360px;
  }
}
