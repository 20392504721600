.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;

  .warning {
    margin-bottom: 2rem;
  }

  .connectorsSelect {
    flex: 0 0 auto;

    margin-bottom: 2rem;
    width: 100%;
  }

  .filters {
    flex: 0 0 auto;

    margin-bottom: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;

    .search {
      margin-left: 1.2rem;
    }
  }

  .fastOnboardLinkWrapper {
    margin-bottom: 0.5rem;

    display: flex;
    justify-content: flex-end;
  }

  .table {
    width: 100%;
    flex: 1;
    max-height: calc(100% - 14rem);
  }
}
