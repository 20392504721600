@import '~@palette/styles/colors.less';

.wrapper {
  margin: 0 4rem 1.6rem 4rem;

  .description {
    .settingsLink {
      color: @palette-color-neutrals-dark;
      text-decoration: underline;
    }
  }
}
