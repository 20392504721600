.wrapper {
  height: 100%;
  width: 100%;

  .titleWithCurrency {
    padding: 4rem 4rem 2.2rem 4rem;

    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;

    .title {
      padding: 0;
    }

    .currencySelect {
      min-width: 16.5rem;
    }
  }
}
