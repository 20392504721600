.wrapper {
  position: relative;
  padding: 0 4rem 4rem;

  .header {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 3rem;

    .field {
      margin-right: 1.2rem;
      margin-bottom: 1rem;
    }

    .userField {
      width: 26.8rem;
      min-width: 26.8rem;
    }

    .datesField {
      width: 28rem;
      min-width: 28rem;
    }
  }
}
