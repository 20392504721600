@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.statusSelectDropdown {
  width: fit-content !important;

  .resolvedStatus {
    display: flex;
    flex-direction: row;
    align-items: center;

    .statusIcon {
      margin-right: 0.4rem;
    }
  }

  :global(.ant-select-item-option) {
    color: @palette-color-neutrals-grey1 !important;

    .resolvedStatus {
      .statusIcon {
        .colorSvg(@palette-color-neutrals-grey2);
      }
    }

    &:hover {
      color: @palette-color-primary-blue !important;

      .resolvedStatus {
        .statusIcon {
          .colorSvg(@palette-color-primary-blue);
        }
      }
    }
  }
}

.wrapper {
  padding-top: 1rem;

  .infos {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .changeStatus {
      padding: 0.25rem 0;

      display: flex;
      flex-direction: row;
      align-items: center;

      .statusIcon {
        margin-right: 0.4rem;
        .colorSvg(@palette-color-neutrals-grey2);
      }

      .statusSelect {
        :global(.ant-select-selector) {
          :global(.ant-select-selection-item) {
            .bodyBase2Medium();
            color: @palette-color-neutrals-grey1;
          }
        }
      }

      &:hover {
        .statusIcon {
          .colorSvg(@palette-color-primary-blue);
        }

        .statusSelect {
          :global(.ant-select-selector) {
            :global(.ant-select-selection-item) {
              color: @palette-color-primary-blue;
            }
          }
        }
      }
    }

    .stats {
      display: flex;
      flex-direction: row;
      align-items: center;

      .stat {
        display: flex;
        flex-direction: row;
        align-items: center;

        .bodyBase2Medium();
        color: @palette-color-neutrals-grey2;

        margin-right: 0.8rem;
        &:last-child {
          margin-right: 0;
        }

        .icon {
          margin-left: 0.5rem;
          .colorSvg(@palette-color-neutrals-grey2);
        }
      }
    }
  }


  .comments {
    margin-top: 2.6rem;

    overflow: auto;
    max-height: calc(100% - 6.5rem);

    .comment {
      margin-bottom: 0.4rem;
      border-bottom: 0.1rem solid @palette-color-border-light;
    }
  }
}
