@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.modalTitle {
  display: flex;
  align-items: center;
  margin-top: 0.8rem;

  .headlineH2();
  color: @palette-color-neutrals-dark;

  .quotaName {
    color: @palette-color-neutrals-grey1;
    margin-left: 0.6rem;
  }
}

.plan {
  margin-bottom: 1.6rem;
}
