@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  .rule {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    max-width: 100%;
    padding: 3rem 2.4rem;
    border: .1rem solid @palette-color-primary-blue;
    border-radius: 1rem;
    margin-bottom: 1.2rem;
  
    .title {
      .title2Bold();
      color: @palette-color-neutrals-dark;
      margin-bottom: 1.2rem;
    }
  
    .text {
      .bodyBase1Medium();
      color: @palette-color-neutrals-grey2;
    }
  
    .formula {
      .bodyBase1Medium();
      color: @palette-color-neutrals-dark;
    }
  }
}