.wrapper {
  position: relative;
  padding: 4.4rem 2.4rem;
  overflow: auto;
  max-height: 100vh;

  &.mobile {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 20;

    ~ :global(section) {
      margin-left: 9.8rem;
    }
  }

  .collapser {
    position: absolute;
    right: 3.5rem;
    margin-top: 0.5rem;

    opacity: 0;
  }

  &:hover {
    .collapser {
      opacity: 1;
      transition: opacity 0.5s ease-in;
    }
  }

  .menu {
    margin-top: 3.5rem;
    height: calc(100% - 3.5rem);
  }
}
