@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';
@import '~@palette/styles/palette-theme.less';

.wrapper {
  display: flex;
  flex-flow: column nowrap;

  .previewProjectionWrapper {
    margin: 0 auto 2rem auto;
    width: 100%;
    max-width: 62rem;

    .projectionChartWrapper {
      margin-top: 1.2rem;
    }
  }

  .ruleWrapper {
    display: flex;
    flex-flow: column nowrap;
    margin-bottom: 2rem;
    width: 100%;

    .ruleName {
      .title2Bold();
      color: @palette-color-neutrals-grey1;
      margin-bottom: 1.2rem;
    }

    .ruleDescription {
      .bodyBase1Medium();
      color: @palette-color-neutrals-grey1;
      margin-bottom: 1.2rem;
    }

    .ruleContainer {
      display: flex;
      flex-flow: column nowrap;
    }

    .variablesContainer {
      margin-top: 1rem;
    }

    .initFormulaContainer {
      background-color: @palette-color-fill-table-group;
      border-radius: @border-radius-base;
      padding: 2rem;
      display: flex;
      flex-flow: column nowrap;
      margin-top: 1rem;
      margin-bottom: 1rem;

      .formulaTitle {
        .bodyBase1Bold();
        color: @palette-color-neutrals-grey2;
        margin-bottom: 2.8rem;
      }

      .formulaContent {
        .bodyBase2Medium();
        color: @palette-color-neutrals-grey1;
        white-space: pre-line;
      }
    }

    .codeOnlyContainer {
      background-color: @palette-color-fill-table-group;
      border-radius: @border-radius-base;
      padding: 2rem;
      margin-bottom: 1rem;
      .bodyBase2Medium();
      color: @palette-color-neutrals-grey1;
      white-space: pre-line;
    }
  }
}
