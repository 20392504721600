@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  .contentWrapper {
    display: flex;
    flex-flow: column nowrap;

    .textContent {
      margin-bottom: 1.6rem;
      color: @palette-color-neutrals-dark;
      .bodyBase2Medium();
    }

    .currencyLine {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      margin-bottom: .6rem;

      .currencyLineValue {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        .default {
          margin-left: .6rem;
          .caption1Italic();
        }
      }

      .actions {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        .actionButton {
          height: auto;
          width: auto;
          padding: .4rem 0;
          margin-left: 1rem;

          &:first-child {
            border-right: .1rem solid @palette-color-border-light;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            padding: 0;
            padding-right: 1rem;
          }
        }
      }
    }
  }
}
