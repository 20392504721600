@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  .label {
    margin-right: 1.4rem;
    color: @palette-color-neutrals-grey1;
    .bodyBase2Medium();
  }

  .progressBar {
    width: 18rem;
    margin-right: 1rem;
  }

  .total {
    min-width: 5rem;
    text-align: right;
    .bodyBase2Medium();
    color: @palette-color-neutrals-dark;

    .totalGrey {
      color: @palette-color-neutrals-grey1;
    }

    .totalGreen {
      color: @palette-color-secondary-light-teal;
    }
  }
}
