.wrapper {
  height: 100%;

  .titleWrapper {
    display: flex;
    flex-flow: row wrap;
    align-items: center;

    .lottieAnimation {
      margin: 0 0 1rem 1rem;
      width: 5rem;
      height: 5rem;
    }
  }
}
