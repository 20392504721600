@import '~@palette/styles/colors.less';

.wrapper {
  .buttonContainer {
    display: flex;
  }

  .configureButton {
    padding: 0;
    height: auto;

    .alertButtonContainer {
      margin-left: .6rem;
      min-height: 2.2rem;
      display: flex;
      align-items: center;

      .iconAlert {
        .colorSvg(@palette-color-secondary-red);
      }
    }

    &:hover, &:active, &:focus, &.pressed {
      .iconAlert {
        .colorSvg(@palette-color-shades-secondary-red-hover);
      }
    }

    &.disabled {
      color: @palette-color-neutrals-grey2;

      .iconEdit {
        .colorSvg(@palette-color-neutrals-grey2);
      }

      .iconAlert {
        .colorSvg(@palette-color-secondary-red);
      }

      &:hover {
        color: @palette-color-neutrals-grey2;

        .iconEdit {
          .colorSvg(@palette-color-neutrals-grey2);
        }
      }
    }
  }
}
