@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';
@import '~@palette/styles/mixins.less';
@import '~@palette/styles/palette-theme.less';

.wrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 0.8rem 1.4rem;
  cursor: pointer;
  border-radius: @border-radius-base;

  .icon {
    .colorSvg(@palette-color-neutrals-grey1);
  }

  .label {
    margin-left: 0.8rem;
    color: @palette-color-neutrals-grey1;
    .inputValue();
    .ellipsis();
    width: calc(100% - 7.8rem);
  }

  .action {
    margin-left: auto;

    &.actionIcon {
      .colorSvg(@palette-color-neutrals-grey1);
    }
  }

  &.isResource {
    &.isSelected {
      background-color: @palette-color-fill-sky;

      .icon {
        .colorSvg(@palette-color-primary-blue);
      }

      .label {
        color: @palette-color-primary-blue;
      }

      .actionIcon {
        .colorSvg(@palette-color-primary-blue);
      }
    }

    &:not(.disabled) {
      &:hover {
        background-color: @palette-color-fill-sky;
      }
    }
  }

  &.isOpened {
    background-color: @palette-color-fill-sky;

    .icon {
      .colorSvg(@palette-color-primary-blue);
    }

    .label {
      color: @palette-color-primary-blue;
    }

    .actionIcon {
      .colorSvg(@palette-color-primary-blue);
    }
  }

  &.disabled {
    cursor: not-allowed;
  }
}
