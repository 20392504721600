.wrapper {
  display: block;
  height: 100%;
  overflow: hidden;
  position: relative;

  .noDataAlert {
    &:global(.ant-alert-with-description) :global(.ant-alert-message) {
      margin-bottom: .4rem;
    }
  }

  .noDataDescription {
    display: flex;
    align-items: center;

    .noDataDescriptionContent {
      margin-right: 1rem;
    }
  }

  .dashboardPlanContainer {
    &:not(:last-child) {
      margin-bottom: 6rem;
    }
  }
}
