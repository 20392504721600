.wrapper {
  .pagination {
    margin-top: 4.4rem;
  }

  .emptyStateContainer {
    :global(.ant-empty-image) {
      height: 8rem;
    }
  }
}
