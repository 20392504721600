@import '~@palette/styles/colors.less';

.wrapper {  
  .deleteButton {
    justify-content: flex-end;
    padding: 0;
    height: auto;

    .deleteIcon {
      .colorSvg(@palette-color-neutrals-dark);
    }
    
    &:focus {
      .deleteIcon {
        .colorSvg(@palette-color-neutrals-dark);
      }
    }

    &:hover {
      .deleteIcon {
        .colorSvg(@palette-color-secondary-red);
      }
    }
  }
}
