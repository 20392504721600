@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  .disclaimerDescriptionWrapper {
    .bodyBase1Medium();
    color: @palette-color-neutrals-dark;

    .disclaimerDescriptionCTAsWrapper {
      margin-top: 2rem;

      display: flex;
      flex-flow: row wrap;
      align-items: center;

      .disclaimerDescriptionCTA {
        + .disclaimerDescriptionCTA {
          margin-left: 1.8rem;
        }
      }
    }
  }

  .cancelQuotaSetupWrapper {
    max-width: 90rem;
    margin-bottom: 2.8rem;

    display: flex;
    justify-content: flex-end;
  }
}
