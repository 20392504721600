.wrapper {
  .form {
    max-width: 70rem;
    margin: 0 auto;
  }

  .quotaTableWrapper {
    .quotaInfosWithActions {
      margin-top: 2.8rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .actions {
        display: flex;
        flex-direction: row;
        align-items: center;

        .action {
          margin-left: 2rem;

          &:first-child {
            margin-left: 0;
          }

          + .action {
            margin-left: 2rem;
          }
        }
      }
    }

    .quotaTable {
      margin-top: 1.5rem;
    }
  }
}
