@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;

  .action {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    height: auto;
    width: auto;

    .actionIcon {
      .colorSvg(@palette-color-neutrals-dark);
    }

    .actionLabel {
      margin-left: 0.4rem;
      .bodyBase2Medium();
      color: @palette-color-neutrals-dark;
    }

    &:hover, &:focus, &:active {
      .actionIcon {
        .colorSvg(@palette-color-secondary-red);
      }

      .actionLabel {
        color: @palette-color-secondary-red;
      }
    }
  }
}