@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  display: flex;
  flex-direction: column;

  .filters {
    margin-bottom: 2.4rem;

    .search {
      width: 100%;
      max-width: 30rem;
    }
  }

  .selectedCount {
    color: @palette-color-neutrals-grey2;
    .bodyBase2Medium();
    margin-bottom: .8rem;
  }

  .table {
    width: 100%;
    flex: 1;
    max-height: calc(100% - 14rem);

    .nameContainer {
      display: flex;
      align-items: center;

      .nameDetails {
        margin-left: 1.2rem;
        display: flex;
        flex-direction: column;
        color: @palette-color-neutrals-grey2;
        .bodyBase2Medium();

        .name {
          color: @palette-color-neutrals-dark;
        }
      }
    }

    .teamsContainer {
      display: flex;
      gap: .8rem;
    }
  }

  .advancedTables {
    margin-top: 2.4rem;
  }
}
