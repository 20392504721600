@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;

  .label {
    .bodyBase2Medium();
    color: @palette-color-neutrals-grey2;
    white-space: nowrap;
    line-height: 3.8rem;
    margin-right: 1rem;
  }

  .filters {
    display: flex;
    flex-flow: row wrap;
    align-items: center;

    .filterBtn {
      margin: 0 0 .8rem .8rem;
    }
  
    .periodsPicker {
      margin-left: .6rem;
      margin-bottom: .8rem;
      padding: 0.7rem 1.6rem;
    }
  }
}
