@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  .progressBar {
    margin-bottom: 3rem;
  }

  .stepsContainer {
    padding-left: 2.4rem;

    .step {
      position: relative;
      padding: 1.6rem 1.2rem;
      border-radius: @border-radius-base;
      cursor: pointer;
      display: flex;
      align-items: center;

      + .step {
        margin-top: 0.4rem;
      }

      .stepIconWrapper {
        position: absolute;
        width: 2.2rem;
        height: 2.2rem;
        left: -2.8rem;
        text-align: center;

        .stepIcon {
          .colorSvg(@palette-color-primary-blue);
        }
      }

      .stepName {
        .bodyBase1Medium();
        color: @palette-color-neutrals-dark;
        white-space: nowrap;

        display: flex;
        align-items: center;
      }

      &:hover {
        background-color: @palette-color-fill-sky;
      }

      &.selected {
        background-color: @palette-color-fill-sky;

        .stepName {
          .bodyBase1Bold();
          color: @palette-color-primary-blue;
        }
      }

      &.disabled {
        cursor: not-allowed;
        background-color: transparent !important;

        .stepName {
          .bodyBase1Medium() !important;
          color: @palette-color-neutrals-grey1 !important;
        }
      }

      &.discard {
        cursor: default;
        background-color: transparent !important;

        .stepName {
          text-decoration: line-through;
        }
      }

      &.availableSoon {
        cursor: not-allowed;
        background-color: transparent !important;

        padding-left: 0;

        .stepName {
          .bodyBase1Medium() !important;
          color: @palette-color-neutrals-grey1 !important;

          .availableSoonTag {
            margin-right: 0.5rem;
          }
        }
      }
    }
  }

  .discardBtn {
    margin-top: 2rem;
    padding-top: 3rem;
    padding-right: 1.5rem;
    justify-content: center;
    border-top: 0.1rem solid @palette-color-border-light;
  }
}
