@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  display: flex;
  flex-direction: row;

  .name {
    .bodyBase2Bold();
    color: @palette-color-neutrals-dark;
    width: 12rem;
    margin-right: 2rem;
    word-break: break-word;
  }

  .valueWrapper {
    .bodyBase2Medium();
    color: @palette-color-neutrals-grey1;
    max-width: calc(100% - 14rem);

    .condition {
      margin-bottom: 0.6rem;
    }
  }
}
