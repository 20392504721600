@import '~@palette/styles/mixins.less';

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0.5rem;
  width: 100%;
  position: relative;

  .viewDealsBtn {
    padding: 0;
    height: auto;
    margin-left: 1rem;
    width: calc(100% - 16rem);
    display: flex;
    justify-content: flex-end;

    span:last-child {
      .ellipsis();
    }
  }
}
