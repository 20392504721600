@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  display: flex;
  flex-flow: column nowrap;

  .textContent {
    margin-bottom: 1.6rem;
    color: @palette-color-neutrals-dark;
    .bodyBase2Medium();
  }

  .switchWrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-bottom: 1.2rem;

    .switchLabel {
      color: @palette-color-neutrals-dark;
      .bodyBase2Medium();
      margin-left: 1rem;
    }
  }

  .textContentBold {
    margin-bottom: 1rem;
    color: @palette-color-neutrals-dark;
    .bodyBase2Bold();
  }

  .authorizationWrapper {
    border-top: .1rem solid @palette-color-border-light;
    margin-top: 2rem;
    padding-top: 2rem;

    .switchWrapper {
      margin-bottom: 0;
    }
  }
}
