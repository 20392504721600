@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';
@import '~@palette/styles/palette-theme.less';

.wrapper {
  :global(.ant-drawer-content-wrapper) {
    width: 100% !important;

    @media(min-width: @screen-md-min) {
      width: 60rem !important;
    }
  }

  :global(.ant-drawer-content) {
    :global(.ant-drawer-header) {
      padding: 4.6rem 4rem 0 4rem;
      border-bottom: none;

      :global(.ant-drawer-header-title) {
        flex-direction: row-reverse;

        :global(.ant-drawer-close) {
          :global(.ant-modal-close-x) {
            width: 2.8rem;
            height: 2.8rem;
            line-height: 2.8rem;
          }

          :global(.anticon) {
            .colorSvg(@palette-color-neutrals-grey1);
          }

          &:hover {
            :global(.anticon) {
              .colorSvg(@palette-color-neutrals-grey3);
            }
          }
        }

        :global(.ant-drawer-title) {
          .headlineH2-2();
          color: @palette-color-neutrals-dark;
        }
      }
    }

    :global(.ant-drawer-body) {
      padding: 2.6rem 0;
      display: flex;
      flex-flow: column nowrap;
    }
  }

  .content {
    flex: 1;
    overflow-y: scroll;
    padding: 0 4rem 4rem;
    border-bottom: .1rem solid @palette-color-border-light;

    .widgetsCategoryWrapper {
      + .widgetsCategoryWrapper {
        margin-top: 2.4rem;
      }

      width: 100%;

      .widgetsCategoryTitle {
        .title2Bold();
        color: @palette-color-neutrals-dark;
      }

      .widgetsWrapper {
        margin-top: 1.6rem;

        .widgetCards {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          margin-left: -.6rem;
          width: calc(100% + 1.2rem);

          .widgetCard {
            width: calc(50% - 1.2rem);
            margin: 0.6rem;
          }
        }

        .showMoreBtn {
          display: none;
        }

        &.displayShowMore {
          .widgetCards {
            max-height: 29rem;
            overflow: hidden;
          }

          .showMoreBtn {
            display: flex;
            margin: 1rem auto 0 auto;
          }
        }
      }
    }

    .planSelect {
      width: 100%;

      margin-bottom: 1.6rem;
    }

    .emptyStateImage {
      filter: grayscale(1);
    }
  }

  .actionWrapper {
    padding: 2.6rem 4rem 0;

    .addWidgetBtnWrapper {
      display: flex;
      justify-content: center;
      background-color: @palette-color-neutrals-white;
    }
  }
}
