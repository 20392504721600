.wrapper {
  display: flex;
  flex-flow: column nowrap;

  .header {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.8rem;

    &.justifyRight {
      justify-content: flex-end;
    }

    .search {
      width: 25.8rem;
    }

    .flexEndRow {
      margin-left: 1rem;
      display: flex;
      align-items: center;
      flex-flow: row wrap;
      justify-content: flex-end;
      flex: 1;

      .documentationLink {
        margin-right: 0;
      }

      .historyButton {
        margin-left: 1rem;
      }
    }
  }
}
