@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';
@import '~@palette/styles/mixins.less';

.wrapper {
  .widgetSizeSmall();

  .amount {
    color: @palette-color-neutrals-dark;
    .headlineH3();
    margin-bottom: 1.6rem;
    text-align: center;
  }
}
