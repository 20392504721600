@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';
@import '~@palette/styles/palette-theme.less';

.wrapper {
  .loadingWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    .loadingIcon {
      .colorSvg(@palette-color-primary-blue);
    }

    .loadingLabel {
      margin-left: 1rem;

      .bodyBase2Medium();
      color: @palette-color-primary-blue;
    }
  }

  .viewResultsWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    .viewResultsLabelWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;

      .viewResultsLabel {
        .bodyBase2Bold();
        color: @palette-color-primary-blue;
      }

      .viewResultsLabelIcon {
        margin-left: 0.5rem;
      }
    }

    .viewResultsBtn {
      margin-left: 1.5rem;
    }
  }
}
