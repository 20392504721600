@import '~@palette/styles/colors.less';

.wrapper {
  .collapse {
    :global(.ant-collapse-item) {
      :global(.ant-collapse-header) {
        height: auto;
        color: @palette-color-primary-blue;

        :global(.anticon) {
          .colorSvg(@palette-color-primary-blue);
        }
      }
    }
  }

  .content {
    margin-top: 1.2rem;
  }
}
