@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  display: flex;
  flex-flow: row nowrap;
  border: .1rem solid @palette-color-secondary-red;
  border-radius: .8rem;
  padding: .8rem;
  margin-bottom: 4rem;

  .icon {
    .colorSvg(@palette-color-secondary-red);
  }

  .content {
    margin-left: 1rem;

    .title {
      margin: .1rem 0 .4rem;
      color: @palette-color-secondary-red;
      .bodyBase2Medium();
    }

    .list {
      display: flex;
      flex-direction: column;
      margin: 0;
      padding: 0;
      color: @palette-color-neutrals-dark;
    }

    .text {
      .code();
    }
  }
}
