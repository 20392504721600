@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  padding: 0.8rem 1.6rem;
  border: 0.2rem solid @palette-color-border-normal;
  border-radius: 1.2rem;
  width: 100%;
  min-height: 20rem;

  .editor {
    min-height: 20rem;
  }

  :global(.line-numbers) {
    width: 100%;

    .code();
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: @palette-color-neutrals-grey2 !important;

    &:global(.active-line-number) {
      color: @palette-color-neutrals-grey1 !important;
    }
  }

  :global(.monaco-scrollable-element) {
    transform: translateX(-2rem);
  }

  :global(.overflow-guard) {
    background-color: @palette-color-fill-grey;
  }

  :global(.monaco-editor-background) {
    background-color: @palette-color-fill-grey;
    padding-left: 2rem;
  }

  :global(.decorationsOverviewRuler) {
    display: none !important;
  }

  :global(.invisible.scrollbar.vertical.fade), :global(.visible.scrollbar.vertical) {
    display: none !important;
  }

  :global(.invisible.scrollbar.horizontal.fade), :global(.visible.scrollbar.horizontal) {
    height: 0.6rem !important;

    :global(.slider) {
      height: 0.6rem !important;
    }
  }

  :global(.react-resizable-handle) {
    cursor: ns-resize;
    background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJtMjEgMTUtNiA2bTYtMTMtMTMgMTMiIHN0cm9rZT0iI0E2QjZDOSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjEiLz48L3N2Zz4K");
    padding: 0;
    width: 1.5rem;
    height: 1.5rem;
    bottom: -0.3rem;
    right: -0.3rem;
  }

  &:hover {
    border-color: @palette-color-shades-navy-cloudy-blue;
  }

  &.isFocus {
    border-color: @palette-color-primary-blue;
  }

  &.isDisabled {
    border-color: @palette-color-border-normal;

    &:hover {
      cursor: not-allowed;

      :global(.monaco-mouse-cursor-text) {
        cursor: not-allowed;
      }
    }
  }
}
