@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  width: 100%;

  .bodyBase1Medium();
  color: @palette-color-neutrals-dark;

  &:global(.ant-input) {
    .bodyBase1Medium();
    max-width: none;
  }
}
