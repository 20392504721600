@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.modal {
  :global(.ant-modal) {
    max-width: 96rem;
  }

  .modalContent {
    display: block;
    height: 100%;
    overflow: hidden;

    .selectResourcesFlowStep {
      height: calc(100% - 2.8rem);
    }
  }

  .alert {
    margin-bottom: 3rem;
  }
}
