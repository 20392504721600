@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  width: 35rem;

  .prevCurrentNextWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    .fillBtnSpace {
      width: 2.4rem;
      height: 2.4rem;
    }

    .prevNextBtn {
      width: 2.4rem;
      height: 2.4rem;

      cursor: pointer;

      :global(.anticon) {
        .colorSvg(@palette-color-neutrals-grey2);
      }

      &:hover {
        :global(.anticon) {
          .colorSvg(@palette-color-neutrals-grey1);
        }
      }

      &.disable {
        cursor: not-allowed;

        &:hover {
          :global(.anticon) {
            .colorSvg(@palette-color-neutrals-grey2);
          }
        }
      }
    }

    .prevBtn {
      margin-right: auto;
    }

    .current {
      .title1Medium();
      color: @palette-color-neutrals-dark;

      user-select: none;
    }

    .nextBtn {
      margin-left: auto;
    }
  }

  .periodsWrapper {
    margin-top: 1.5rem;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    .period {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30%;
      padding: 1rem 2rem;
      margin: 1rem 0;

      cursor: pointer;

      user-select: none;

      border: 0.14rem solid @palette-color-border-light;
      border-radius: 1rem;

      .bodyBase2Medium();
      color: @palette-color-neutrals-grey1;

      &:hover {
        border: 0.14rem solid @palette-color-shades-navy-cloudy-blue;
      }

      &.selected {
        .bodyBase2Bold();
        color: @palette-color-neutrals-white;
        border: 0.14rem solid @palette-color-primary-blue;
        background-color: @palette-color-primary-blue;
      }

      &.disabled {
        cursor: not-allowed;

        border: 0.14rem solid @palette-color-border-light;
        .bodyBase2Medium();
        color: @palette-color-neutrals-grey1;
        background-color: @palette-color-fill-antiflash-white;

        &:hover {
          border: 0.14rem solid @palette-color-border-light;
        }

        &.selected {
          .bodyBase2Bold();
          color: @palette-color-neutrals-white;
          border: 0.14rem solid @palette-color-primary-blue;
          background-color: @palette-color-primary-blue;
        }
      }

      &.forceDisabled {
        cursor: not-allowed;

        border: 0.14rem solid @palette-color-border-light;
        .bodyBase2Medium();
        color: @palette-color-neutrals-grey1;
        background-color: @palette-color-fill-antiflash-white;

        &:hover {
          border: 0.14rem solid @palette-color-border-light;
        }

        &.selected {
          border: 0.14rem solid @palette-color-border-light;
          .bodyBase2Medium();
          color: @palette-color-neutrals-grey1;
          background-color: @palette-color-fill-antiflash-white;
        }
      }
    }

    &.yearly {
      display: none;
    }
  }

  .dayPeriodsWrapper {
    margin-top: 1.5rem;

    .periodsWrapper {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 1rem;

      .period {
        width: 100%;
      }
    }
  }

  &.quarterly {
    width: 23rem;
    .periodsWrapper {
      .period {
        width: 46%;
      }
    }
  }
}
