@import '~@palette/styles/colors.less';

.wrapper {
  padding-top: 2.8rem;

  border: 0.1rem solid @palette-color-border-normal;
  border-radius: 0.8rem;
  background-color: @palette-color-fill-grey;

  overflow: hidden;

  &:hover {
    border-color: @palette-color-shades-navy-cloudy-blue;
  }

  &.isFocus {
    border-color: @palette-color-primary-blue;
  }

  &.isDisabled {
    border-color: @palette-color-border-normal;
    cursor: not-allowed;
  }
}
