@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    flex-direction: column;
    margin-bottom: 4rem;

    .subtitle {
      margin-bottom: .8rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      color: @palette-color-neutrals-grey2;
      .bodyBase2Medium();
    }

    .row {
      display: flex;
      flex-flow: row wrap;

      .nameInfos {
        flex: 1;
        margin-right: 3.2rem;
        display: flex;
        flex-direction: column;

        .title {
          color: @palette-color-neutrals-dark;
          .headlineH1();
          margin-bottom: .8rem;
        }
      
        .description {
          color: @palette-color-neutrals-grey1;
          .bodyBase1Medium();
        }
      }

      .saveInfos {
        padding-top: 2rem;
        color: @palette-color-neutrals-grey2;
        .bodyBase2Medium();
        display: inline-flex;
        align-items: center;

        .tag {
          margin-left: 1.6rem;
          text-transform: uppercase;
        }
      }
    }
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 4rem;
  }
}
