@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  padding: 2.4rem 5rem 10rem 5rem;
  max-height: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .titleWrapper {
    padding-bottom: 3rem;

    .subTitle {
      .bodyBase2Medium();
      color: @palette-color-neutrals-dark;
    }
  }
}
