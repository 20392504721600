@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  position: relative;
  padding: 0 4rem 4rem;

  .list {
    display: flex;
    flex-flow: row wrap;

    .listItem {
      margin: 0.5rem;
    }
  }
}
