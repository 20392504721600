@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  display: flex;
  flex-direction: column;

  .periodsListWrapper {
    padding: 2rem 3rem;
    border: 0.1rem solid @palette-color-border-light;
    border-radius: 1.6rem;
    min-width: 28.7rem;

    & + .periodsListWrapper {
      margin-top: 2.8rem;
    }

    .periodsListTitle {
      .title2Bold();
      color: @palette-color-neutrals-dark;
    }

    .periodsList {
      margin-top: 2.6rem;

      display: flex;
      flex-direction: column;

      .item {
        padding-bottom: 1.2rem;
        margin-bottom: 1.2rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
