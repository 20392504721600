@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.modal {
  :global(.ant-modal) {
    min-width: 95rem;
    width: 95rem;
    max-width: 95rem;
  }

  .resetToOriginalButton {
    padding: 0;
    max-width: 13rem;

    span {
      white-space: pre-line;
      text-align: left;
    }
  }

  .titleWrapper {
    display: flex;
    flex-direction: row;
    align-items: baseline;

    .title {
      .headlineH3();
      color: @palette-color-neutrals-dark;
    }

    .subTitle {
      margin-left: 1.2rem;

      .bodyBase2Medium();
      color: @palette-color-neutrals-grey2;
    }
  }

  .contentWrapper {
    .labels {
      width: 100%;

      display: flex;
      flex-direction: row;
      align-items: center;

      padding-left: 3rem;

      .label {
        padding-left: 1.6rem;

        .bodyBase2Medium();
        color: @palette-color-neutrals-grey2;

        .infoIcon {
          margin-left: 0.4rem;
          vertical-align: sub;

          .colorSvg(@palette-color-neutrals-grey2);

          &:hover {
            .colorSvg(@palette-color-primary-blue);
          }
        }

        &:first-child {
          width: 27.5rem;
          max-width: 27.5rem;
        }

        &:nth-child(2) {
          width: 31rem;
          max-width: 31rem;
        }

        &:last-child {
          width: calc(100% - 58.5rem);
          max-width: calc(100% - 58.5rem);
        }
      }
    }

    .variables {
      margin-top: 0.6rem;

      .variable {
        margin-top: 1.2rem;

        &:first-child {
          margin-top: 0;
        }
      }
    }

    .addButton {
      margin-top: 0.6rem;
      margin-left: 2rem;
    }

    .tipAlert {
      margin-top: 2rem;

      .tipMessage {
        display: flex;
        flex-direction: row;
        align-items: center;

        .tipLink {
          margin-left: 0.4rem
        }
      }
    }
  }
}
