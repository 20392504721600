@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';
@import '~@palette/styles/palette-theme.less';

.wrapper {
  .label {
    .inputLabel();
    color: @palette-color-neutrals-grey1;
    margin-bottom: .6rem;
  }

  .selectField {
    width: 100%;
    margin-bottom: .6rem;

    .selectPlanAlertWrapper {
      padding: 1.1rem 2rem;
      align-items: center;
      border-radius: @border-radius-base;
    }
  }

  .addButton {
    height: 3rem;
    align-self: flex-start;
    margin-bottom: .8rem;
  }
}
