@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  display: flex;
  align-items: center;
  height: 4rem;
  border-radius: @border-radius-base;
  border: none;
  box-shadow: none;
  text-transform: none;

  background-color: @palette-color-primary-teal;
  .bodyBase1Bold();
  color: @palette-color-neutrals-white;
  padding: 0.9rem 1.6rem;

  & > :global(.anticon + span) {
    margin-left: 0.4rem;
  }

  :global(.anticon) {
    display: flex;
    .colorSvg(@palette-color-neutrals-white);
  }

  &:hover, &:active {
    background-color: @palette-color-shades-teal-hover;
    .bodyBase1Bold();
    color: @palette-color-neutrals-white;

    :global(.anticon) {
      .colorSvg(@palette-color-neutrals-white);
    }
  }

  &:focus, &.pressed {
    background-color: @palette-color-primary-teal;
    .bodyBase1Bold();
    color: @palette-color-neutrals-white;

    :global(.anticon) {
      .colorSvg(@palette-color-neutrals-white);
    }
  }

  &.disabled {
    cursor: not-allowed;

    background-color: @palette-color-shades-primary-teal-ghost;
    .bodyBase1Bold();
    color: @palette-color-neutrals-white;

    :global(.anticon) {
      .colorSvg(@palette-color-neutrals-white);
    }

    &:hover, &:active, &:focus {
      background-color: @palette-color-shades-primary-teal-ghost;
      .bodyBase1Bold();
      color: @palette-color-neutrals-white;

      :global(.anticon) {
        .colorSvg(@palette-color-neutrals-white);
      }
    }
  }

  &.primaryOutlined {
    height: 2.4rem;
    padding: 0.8rem 1.1rem;
    background-color: @palette-color-neutrals-white;
    .caption1Bold();
    color: @palette-color-primary-teal;
    border: 0.1rem solid @palette-color-primary-teal;
    border-radius: 0.5rem;

    :global(.anticon) {
      .colorSvg(@palette-color-primary-teal);
    }

    &:hover, &:active {
      background-color: @palette-color-neutrals-white;
      .caption1Bold();
      color: @palette-color-shades-teal-hover;
      border-color: @palette-color-shades-teal-hover;

      :global(.anticon) {
        .colorSvg(@palette-color-shades-teal-hover);
      }
    }

    &:focus, &.pressed {
      background-color: @palette-color-neutrals-white;
      .caption1Bold();
      color: @palette-color-primary-teal;
      border-color: @palette-color-primary-teal;

      :global(.anticon) {
        .colorSvg(@palette-color-primary-teal);
      }
    }

    &.disabled {
      background-color: @palette-color-neutrals-white;
      .caption1Bold();
      color: @palette-color-shades-primary-teal-ghost;
      border-color: @palette-color-shades-primary-teal-ghost;

      :global(.anticon) {
        .colorSvg(@palette-color-shades-primary-teal-ghost);
      }

      &:hover, &:active, &:focus {
        background-color: @palette-color-neutrals-white;
        .caption1Bold();
        color: @palette-color-shades-primary-teal-ghost;
        border-color: @palette-color-shades-primary-teal-ghost;

        :global(.anticon) {
          .colorSvg(@palette-color-shades-primary-teal-ghost);
        }
      }
    }
  }

  &.primaryBlue {
    padding: 0.9rem 1.6rem;
    background-color: @palette-color-primary-blue;
    .bodyBase1Bold();
    color: @palette-color-neutrals-white;

    :global(.anticon) {
      .colorSvg(@palette-color-neutrals-white);
    }

    &:hover, &:active {
      background-color: @palette-color-shades-blue-hover;
      .bodyBase1Bold();
      color: @palette-color-neutrals-white;

      :global(.anticon) {
        .colorSvg(@palette-color-neutrals-white);
      }
    }

    &:focus, &.pressed {
      background-color: @palette-color-primary-blue;
      .bodyBase1Bold();
      color: @palette-color-neutrals-white;

      :global(.anticon) {
        .colorSvg(@palette-color-neutrals-white);
      }
    }

    &.disabled {
      background-color: @palette-color-shades-primary-blue-ghost;
      .bodyBase1Bold();
      color: @palette-color-neutrals-white;

      :global(.anticon) {
        .colorSvg(@palette-color-neutrals-white);
      }

      &:hover, &:active, &:focus {
        background-color: @palette-color-shades-primary-blue-ghost;
        .bodyBase1Bold();
        color: @palette-color-neutrals-white;

        :global(.anticon) {
          .colorSvg(@palette-color-neutrals-white);
        }
      }
    }
  }

  &.secondary {
    padding: 0.9rem 1.6rem;
    background-color: @palette-color-neutrals-white;
    .bodyBase1Bold();
    color: @palette-color-neutrals-grey1;
    border: 0.14rem solid @palette-color-border-normal;

    :global(.anticon) {
      .colorSvg(@palette-color-neutrals-grey1);
    }

    &:hover, &:active {
      background-color: @palette-color-neutrals-white;
      .bodyBase1Bold();
      color: @palette-color-neutrals-grey1;
      border-color: @palette-color-shades-navy-cloudy-blue;

      :global(.anticon) {
        .colorSvg(@palette-color-neutrals-grey1);
      }
    }

    &:focus, &.pressed {
      background-color: @palette-color-neutrals-white;
      .bodyBase1Bold();
      color: @palette-color-neutrals-grey1;
      border-color: @palette-color-primary-blue;

      :global(.anticon) {
        .colorSvg(@palette-color-neutrals-grey1);
      }
    }

    &.disabled {
      background-color: @palette-color-neutrals-white;
      .bodyBase1Bold();
      color: @palette-color-neutrals-grey2;
      border-color: @palette-color-border-light;

      :global(.anticon) {
        .colorSvg(@palette-color-neutrals-grey2);
      }

      &:hover, &:active, &:focus {
        background-color: @palette-color-neutrals-white;
        .bodyBase1Bold();
        color: @palette-color-neutrals-grey2;
        border-color: @palette-color-border-light;

        :global(.anticon) {
          .colorSvg(@palette-color-neutrals-grey2);
        }
      }
    }
  }

  &.link {
    padding: 1rem 0;
    border: 0;
    background-color: transparent;
    .bodyBase2Medium();
    color: @palette-color-primary-blue;
    box-shadow: none;

    :global(.anticon) {
      .colorSvg(@palette-color-primary-blue);
    }

    &:hover, &:active, &:focus {
      background-color: transparent;
      .bodyBase2Medium();
      color: @palette-color-primary-blue;
      text-decoration: underline;
      box-shadow: none;

      :global(.anticon) {
        .colorSvg(@palette-color-primary-blue);
      }
    }

    &.disabled {
      background-color: transparent;
      .bodyBase2Medium();
      color: @palette-color-primary-blue;
      box-shadow: none;

      :global(.anticon) {
        .colorSvg(@palette-color-primary-blue);
      }

      &:hover, &:active, &:focus {
        background-color: transparent;
        .bodyBase2Medium();
        color: @palette-color-primary-blue;
        box-shadow: none;

        :global(.anticon) {
          .colorSvg(@palette-color-primary-blue);
        }
      }
    }
  }

  &.linkSecondary {
    padding: 1rem 0;
    border: 0;
    background-color: transparent;
    .bodyBase2Medium();
    color: @palette-color-neutrals-grey1;
    box-shadow: none;

    :global(.anticon) {
      .colorSvg(@palette-color-neutrals-grey2);
    }

    &:hover, &:active, &:focus, &.pressed {
      background-color: transparent;
      .bodyBase2Medium();
      color: @palette-color-primary-blue;
      text-decoration: underline;
      box-shadow: none;

      :global(.anticon) {
        .colorSvg(@palette-color-primary-blue);
      }
    }

    &.disabled {
      background-color: transparent;
      .bodyBase2Medium();
      color: @palette-color-neutrals-grey1;
      box-shadow: none;

      :global(.anticon) {
        .colorSvg(@palette-color-neutrals-grey2);
      }

      &:hover, &:active, &:focus {
        background-color: transparent;
        .bodyBase2Medium();
        color: @palette-color-neutrals-grey1;
        box-shadow: none;

        :global(.anticon) {
          .colorSvg(@palette-color-neutrals-grey2);
        }
      }
    }
  }

  &.linkSecondaryBlack {
    padding: 1rem 0;
    border: 0;
    background-color: transparent;
    .bodyBase2Medium();
    color: @palette-color-neutrals-dark;
    box-shadow: none;

    :global(.anticon) {
      .colorSvg(@palette-color-neutrals-dark);
    }

    &:hover, &:active, &:focus, &.pressed {
      background-color: transparent;
      .bodyBase2Medium();
      color: @palette-color-primary-blue;
      text-decoration: underline;
      box-shadow: none;

      :global(.anticon) {
        .colorSvg(@palette-color-primary-blue);
      }
    }

    &.disabled {
      background-color: transparent;
      .bodyBase2Medium();
      color: @palette-color-neutrals-dark;
      box-shadow: none;

      :global(.anticon) {
        .colorSvg(@palette-color-neutrals-dark);
      }

      &:hover, &:active, &:focus, &.pressed {
        background-color: transparent;
        .bodyBase2Medium();
        color: @palette-color-neutrals-dark;
        box-shadow: none;

        :global(.anticon) {
          .colorSvg(@palette-color-neutrals-dark);
        }
      }
    }
  }

  &.linkDestroy {
    padding: 1rem 0;
    border: 0;
    background-color: transparent;
    .bodyBase2Medium();
    color: @palette-color-secondary-red;
    box-shadow: none;

    :global(.anticon) {
      .colorSvg(@palette-color-secondary-red);
    }

    &:hover, &:active, &:focus, &.pressed {
      background-color: transparent;
      .bodyBase2Medium();
      color: @palette-color-shades-secondary-red-hover;
      text-decoration: underline;
      box-shadow: none;

      :global(.anticon) {
        .colorSvg(@palette-color-shades-secondary-red-hover);
      }
    }

    &.disabled {
      background-color: transparent;
      .bodyBase2Medium();
      color: @palette-color-secondary-red;
      box-shadow: none;

      :global(.anticon) {
        .colorSvg(@palette-color-secondary-red);
      }

      &:hover, &:active, &:focus {
        background-color: transparent;
        .bodyBase2Medium();
        color: @palette-color-secondary-red;
        box-shadow: none;

        :global(.anticon) {
          .colorSvg(@palette-color-secondary-red);
        }
      }
    }
  }

  &.flat {
    padding: 0.9rem 1.6rem;
    border: 0;
    background-color: transparent;
    .bodyBase1Bold();
    color: @palette-color-neutrals-grey1;
    box-shadow: none;

    :global(.anticon) {
      .colorSvg(@palette-color-neutrals-grey1);
    }

    &:hover {
      background-color: @palette-color-fill-sky;
      .bodyBase1Bold();
      color: @palette-color-neutrals-grey1;
      box-shadow: none;

      :global(.anticon) {
        .colorSvg(@palette-color-neutrals-grey1);
      }
    }

    &:active {
      background-color: @palette-color-neutrals-dark;
      .bodyBase1Bold();
      color: @palette-color-neutrals-white;
      box-shadow: none;

      :global(.anticon) {
        .colorSvg(@palette-color-neutrals-white);
      }
    }

    &:focus, &.pressed {
      background-color: @palette-color-neutrals-dark;
      .bodyBase1Bold();
      color: @palette-color-neutrals-white;
      box-shadow: none;

      :global(.anticon) {
        .colorSvg(@palette-color-neutrals-white);
      }
    }

    &.disabled {
      background-color: transparent;
      .bodyBase1Bold();
      color: @palette-color-neutrals-grey2;

      :global(.anticon) {
        .colorSvg(@palette-color-neutrals-grey2);
      }

      &:hover, &:active, &:focus {
        background-color: transparent;
        .bodyBase1Bold();
        color: @palette-color-neutrals-grey2;

        :global(.anticon) {
          .colorSvg(@palette-color-neutrals-grey2);
        }
      }
    }
  }

  &.flatFill {
    padding: 0.9rem 1.6rem;
    border: 0;
    background-color: @palette-color-fill-sky;
    .bodyBase1Bold();
    color: @palette-color-neutrals-grey1;
    box-shadow: none;

    :global(.anticon) {
      .colorSvg(@palette-color-neutrals-grey1);
    }

    &:hover, &:active {
      background-color: @palette-color-neutrals-dark;
      .bodyBase1Bold();
      color: @palette-color-neutrals-white;
      box-shadow: none;

      :global(.anticon) {
        .colorSvg(@palette-color-neutrals-white);
      }
    }

    &:focus, &.pressed {
      background-color: @palette-color-neutrals-dark;
      .bodyBase1Bold();
      color: @palette-color-neutrals-white;
      box-shadow: none;

      :global(.anticon) {
        .colorSvg(@palette-color-neutrals-white);
      }
    }

    &.disabled {
      background-color: @palette-color-fill-sky;
      .bodyBase1Bold();
      color: @palette-color-neutrals-grey2;

      :global(.anticon) {
        .colorSvg(@palette-color-neutrals-grey2);
      }

      &:hover, &:active, &:focus {
        background-color: @palette-color-fill-sky;
        .bodyBase1Bold();
        color: @palette-color-neutrals-grey2;

        :global(.anticon) {
          .colorSvg(@palette-color-neutrals-grey2);
        }
      }
    }
  }

  &.flatFillBlue {
    height: 2.6rem;
    border-radius: 0.6rem;

    padding: 0.4rem 0.6rem;
    border: 0;
    background-color: transparent;
    .bodyBase2Medium();
    color: @palette-color-neutrals-grey1;
    box-shadow: none;

    :global(.anticon) {
      .colorSvg(@palette-color-neutrals-grey1);
    }

    &:hover, &:active {
      background-color: @palette-color-fill-sky;
      .bodyBase2Medium();
      color: @palette-color-neutrals-grey1;
      box-shadow: none;

      :global(.anticon) {
        .colorSvg(@palette-color-neutrals-grey1);
      }
    }

    &:focus, &.pressed {
      background-color: @palette-color-fill-sky;
      .bodyBase2Medium();
      color: @palette-color-primary-blue;
      box-shadow: none;

      :global(.anticon) {
        .colorSvg(@palette-color-primary-blue);
      }
    }

    &.disabled {
      background-color: @palette-color-fill-sky;
      .bodyBase2Medium();
      color: @palette-color-neutrals-grey2;

      :global(.anticon) {
        .colorSvg(@palette-color-neutrals-grey2);
      }

      &:hover, &:active, &:focus {
        background-color: @palette-color-fill-sky;
        .bodyBase2Medium();
        color: @palette-color-neutrals-grey2;

        :global(.anticon) {
          .colorSvg(@palette-color-neutrals-grey2);
        }
      }
    }
  }

  &.flatFillBlueOutlined {
    height: 2.6rem;
    border-radius: 0.6rem;

    padding: 0.4rem 0.6rem;
    border: .1rem solid @palette-color-border-normal;
    background-color: transparent;
    .bodyBase2Medium();
    color: @palette-color-neutrals-grey1;
    box-shadow: none;

    :global(.anticon) {
      .colorSvg(@palette-color-neutrals-grey1);
    }

    &:hover, &:active {
      border: .1rem solid @palette-color-shades-navy-cloudy-blue;
      .bodyBase2Medium();
      color: @palette-color-neutrals-grey1;
      box-shadow: none;

      :global(.anticon) {
        .colorSvg(@palette-color-neutrals-grey1);
      }
    }

    &:focus, &.pressed {
      .bodyBase2Medium();
      color: @palette-color-neutrals-grey1;
      box-shadow: none;

      :global(.anticon) {
        .colorSvg(@palette-color-primary-blue);
      }
    }

    &.disabled {
      background-color: @palette-color-fill-sky;
      .bodyBase2Medium();
      color: @palette-color-neutrals-grey2;

      :global(.anticon) {
        .colorSvg(@palette-color-neutrals-grey2);
      }

      &:hover, &:active, &:focus {
        background-color: @palette-color-fill-sky;
        .bodyBase2Medium();
        color: @palette-color-neutrals-grey2;

        :global(.anticon) {
          .colorSvg(@palette-color-neutrals-grey2);
        }
      }
    }
  }

  &.danger {
    padding: 0.9rem 1.6rem;
    background-color: @palette-color-secondary-red;
    .bodyBase1Bold();
    color: @palette-color-neutrals-white;

    :global(.anticon) {
      .colorSvg(@palette-color-neutrals-white);
    }

    &:hover, &:active {
      background-color: @palette-color-shades-secondary-red-hover;
      .bodyBase1Bold();
      color: @palette-color-neutrals-white;

      :global(.anticon) {
        .colorSvg(@palette-color-neutrals-white);
      }
    }

    &:focus, &.pressed {
      background-color: @palette-color-secondary-red;
      .bodyBase1Bold();
      color: @palette-color-neutrals-white;

      :global(.anticon) {
        .colorSvg(@palette-color-neutrals-white);
      }
    }

    &.disabled {
      background-color: @palette-color-secondary-red;
      .bodyBase1Bold();
      color: @palette-color-neutrals-white;

      :global(.anticon) {
        .colorSvg(@palette-color-neutrals-white);
      }

      &:hover, &:active, &:focus {
        background-color: @palette-color-secondary-red;
        .bodyBase1Bold();
        color: @palette-color-neutrals-white;

        :global(.anticon) {
          .colorSvg(@palette-color-neutrals-white);
        }
      }
    }
  }

  &.warning {
    padding: 0.9rem 1.6rem;
    background-color: @palette-color-secondary-orange;
    .bodyBase1Bold();
    color: @palette-color-neutrals-white;

    :global(.anticon) {
      .colorSvg(@palette-color-neutrals-white);
    }

    &:hover, &:active {
      background-color: @palette-color-shades-secondary-orange-hover;
      .bodyBase1Bold();
      color: @palette-color-neutrals-white;

      :global(.anticon) {
        .colorSvg(@palette-color-neutrals-white);
      }
    }

    &:focus, &.pressed {
      background-color: @palette-color-secondary-orange;
      .bodyBase1Bold();
      color: @palette-color-neutrals-white;

      :global(.anticon) {
        .colorSvg(@palette-color-neutrals-white);
      }
    }

    &.disabled {
      background-color: @palette-color-secondary-orange;
      .bodyBase1Bold();
      color: @palette-color-neutrals-white;

      :global(.anticon) {
        .colorSvg(@palette-color-neutrals-white);
      }

      &:hover, &:active, &:focus {
        background-color: @palette-color-secondary-orange;
        .bodyBase1Bold();
        color: @palette-color-neutrals-white;

        :global(.anticon) {
          .colorSvg(@palette-color-neutrals-white);
        }
      }
    }
  }

  &.dangerSecondary {
    padding: 0.9rem 1.6rem;
    background-color: @palette-color-neutrals-white;
    .bodyBase1Bold();
    color: @palette-color-secondary-red;
    border: 0.14rem solid @palette-color-fill-light-red;

    :global(.anticon) {
      .colorSvg(@palette-color-secondary-red);
    }

    &:hover, &:active {
      background-color: @palette-color-neutrals-white;
      .bodyBase1Bold();
      color: @palette-color-secondary-red;
      border-color: @palette-color-secondary-red;

      :global(.anticon) {
        .colorSvg(@palette-color-secondary-red);
      }
    }

    &:focus, &.pressed {
      background-color: @palette-color-neutrals-white;
      .bodyBase1Bold();
      color: @palette-color-shades-secondary-red-hover;
      border-color: @palette-color-secondary-red;

      :global(.anticon) {
        .colorSvg(@palette-color-secondary-red);
      }
    }

    &.disabled {
      background-color: @palette-color-neutrals-white;
      .bodyBase1Bold();
      color: @palette-color-fill-light-red;
      border-color: @palette-color-fill-light-red;

      :global(.anticon) {
        .colorSvg(@palette-color-fill-light-red);
      }

      &:hover, &:active, &:focus {
        background-color: @palette-color-neutrals-white;
        .bodyBase1Bold();
        color: @palette-color-fill-light-red;
        border-color: @palette-color-fill-light-red;

        :global(.anticon) {
          .colorSvg(@palette-color-fill-light-red);
        }
      }
    }
  }

  &.infoSecondary {
    padding: 0.9rem 1.6rem;
    background-color: @palette-color-neutrals-white;
    .bodyBase1Bold();
    color: @palette-color-primary-blue;
    border: 0.14rem solid @palette-color-border-normal;

    :global(.anticon) {
      .colorSvg(@palette-color-primary-blue);
    }

    &:hover, &:active {
      border-color: @palette-color-primary-blue;
    }

    &:focus, &.pressed {
      border-color: @palette-color-primary-blue;
    }

    &.disabled {
      color: @palette-color-secondary-light-blue;

      :global(.anticon) {
        .colorSvg(@palette-color-secondary-light-blue);
      }

      &:hover, &:active, &:focus {
        background-color: @palette-color-neutrals-white;
        color: @palette-color-secondary-light-blue;
        border-color: @palette-color-border-normal;

        :global(.anticon) {
          .colorSvg(@palette-color-secondary-light-blue);
        }
      }
    }
  }

  &.successSecondary {
    padding: 0.9rem 1.6rem;
    background-color: @palette-color-neutrals-white;
    .bodyBase1Bold();
    color: @palette-color-primary-teal;
    border: 0.14rem solid @palette-color-border-normal;

    :global(.anticon) {
      .colorSvg(@palette-color-primary-teal);
    }

    &:hover, &:active {
      border-color: @palette-color-primary-teal;
    }

    &:focus, &.pressed {
      border-color: @palette-color-primary-teal;
    }

    &.disabled {
      color: @palette-color-secondary-light-teal;

      :global(.anticon) {
        .colorSvg(@palette-color-secondary-light-teal);
      }

      &:hover, &:active, &:focus {
        background-color: @palette-color-neutrals-white;
        color: @palette-color-secondary-light-teal;
        border-color: @palette-color-border-normal;

        :global(.anticon) {
          .colorSvg(@palette-color-secondary-light-teal);
        }
      }
    }
  }

  &.warningSecondary {
    padding: 0.9rem 1.6rem;
    background-color: @palette-color-neutrals-white;
    .bodyBase1Bold();
    color: @palette-color-secondary-orange;
    border: 0.14rem solid @palette-color-border-normal;

    :global(.anticon) {
      .colorSvg(@palette-color-secondary-orange);
    }

    &:hover, &:active {
      border-color: @palette-color-secondary-orange;
    }

    &:focus, &.pressed {
      border-color: @palette-color-secondary-orange;
    }

    &.disabled {
      color: @palette-color-secondary-light-orange;

      :global(.anticon) {
        .colorSvg(@palette-color-secondary-light-orange);
      }

      &:hover, &:active, &:focus {
        background-color: @palette-color-neutrals-white;
        color: @palette-color-secondary-light-orange;
        border-color: @palette-color-border-normal;

        :global(.anticon) {
          .colorSvg(@palette-color-secondary-light-orange);
        }
      }
    }
  }
}
