@import '~@palette/styles/typographies.less';

.wrapper {
  .header {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;

    .userSearch {
      width: 100%;
      max-width: 28rem;
      margin-right: 2rem;
      margin-bottom: 2.4rem;
    }

    .actions {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 2.4rem;

      .actionLink {
        &:not(:last-child) {
          margin-right: 2rem;
        }
      }

      .sortSelect {
        min-width: 24rem;
      }
    }
  }

  .disclaimer {
    max-width: 46rem;
    justify-content: center;
    margin: 0 auto;
    margin-bottom: 1.5rem;
  }

  .usersTable {
    .headerCommissions {
      text-align: center;
      .bodyBase2Bold();
    }

    .headerCentered {
      text-align: center;
    }

    .cellCommissions {
      text-align: center;
      .bodyBase2Bold();
    }
  }
}
