.wrapper {
  max-height: calc(100vh - 8.9rem);

  .leftSideContentWrapper {
    overflow-y: hidden !important;
    height: 100%;
  }

  .mainContentWrapper {
    padding: 2rem 2.4rem 4rem 2.4rem !important;
  }
}
