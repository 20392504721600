@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  display: flex;
  flex-direction: row;

  .formWrapper {
    min-width: 44rem;
    flex: 1;
  }

  .resourceObjectSample {
    margin-left: 2rem;
  }
}
