@import '~@palette/styles/typographies.less';

.modal {
  :global(.ant-modal) {
    max-width: 100rem;
    width: fit-content !important;
  }

  .headerCommissions {
    text-align: center;
    .bodyBase2Bold();
  }

  .pagination {
    margin-top: 4.4rem;
  }
}
