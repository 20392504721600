@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;

  .input {
    background-color: @palette-color-neutrals-white;
  }

  .name {
    width: 26.5rem;
    max-width: 26.5rem;
  }

  .fields {
    margin-left: 1rem;

    width: 30rem;
    max-width: 30rem;
  }

  .formatter {
    margin-left: 1rem;

    width: 16rem;
    max-width: 16rem;
  }

  .editEvalFn {
    margin-left: 0.5rem;
    margin-right: -2.1rem;
    padding: 0;
    width: 1.6rem;
  }
}
