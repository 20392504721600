@import '~@palette/styles/colors.less';

.wrapper {
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 50%;
  background-color: @palette-color-primary-blue;

  position: absolute;
  top: 0.5rem;
  right: 0.9rem;
  z-index: 1;
}