@import '~@palette/styles/colors.less';

.wrapper {
  .titleContainer {
    width: fit-content;
    border: .1rem dashed @palette-color-primary-blue;
    padding: 0.8rem 1.5rem;
    background-color: @palette-color-neutrals-white;
    border-radius: 1rem;
    opacity: 0.6;
  }
}
