@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  border-bottom: 0.1rem solid @palette-color-border-light;
  padding: 1.2rem 1.4rem;
  cursor: pointer;

  &:hover {
    border-bottom-color: transparent;
    border-radius: 1.8rem;
    background-color: @palette-color-fill-sky;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .avatar_and_title {
      display: flex;
      align-items: center;

      .avatarWrapper {
        width: 3rem;
        height: 3rem;
        position: relative;

        .commentTypeIconWrapper {
          width: 1.8rem;
          height: 1.8rem;
          position: absolute;
          top: 0;
          right: 0;
          transform: translate(30%, -30%);
        }
      }

      .title {
        margin: 0 0.6rem;

        .name {
          .bodyBase1Medium();
          color: @palette-color-neutrals-dark;
        }

        .action {
          .bodyBase1Bold();
          color: @palette-color-primary-blue;
        }
      }
    }

    .date {
      .caption1();
      color: @palette-color-neutrals-grey1;

      width: 10rem;
      text-align: right;
    }
  }

  .contentWrapper {
    margin-top: 0.4rem;
    padding-left: 3.5rem;

    display: flex;
    justify-content: space-between;

    .content {
      .bodyBase1Medium();
      color: @palette-color-neutrals-grey1;

      blockquote::before {
        content: open-quote;
      }
      blockquote::after {
        content: close-quote;
      }
      blockquote {
        quotes: "“" "”" "‘" "’";
      }
    }

    .resolvedIcon {
      margin-left: 0.6rem;
    }
  }
}