.wrapper {
  display: flex;
  flex-flow: row wrap;
  align-items: center;

  .field + .field {
    margin-left: 2rem;
  }

  .periodField {
    width: 20rem;
    min-width: 20rem;
  }

  .yearField {
    width: 12rem;
    min-width: 12rem;
  }
}
