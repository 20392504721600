@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;

  .tooltip {
    display: flex;
    flex-direction: row;
    align-items: center;

    .progressIcon {
      margin-right: 1.2rem;

      &.success {
        .colorSvg(@palette-color-secondary-grass-green);
      }

      &.warning {
        .colorSvg(@palette-color-secondary-orange);
      }

      &.danger {
        .colorSvg(@palette-color-secondary-red);
      }
    }

    .progressBar {
      width: 12rem;
    }

    .progressPercent {
      margin-left: 1.5rem;

      .bodyBase2Medium();
      color: @palette-color-neutrals-grey1;
    }
  }
}
