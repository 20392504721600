@import '~@palette/styles/colors.less';

.filterByUserSelectorDropdown {
  .userOption {
    width: 100%;
  }
}

.wrapper {
  .icon {
    margin: .8rem 0;
    .colorSvg(@palette-color-secondary-light-blue);
  }

  .select {
    width: 100%;
  }
}