@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  .content {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    .icon {
      .colorSvg(@palette-color-secondary-orange);
    }
  
    .label {
      margin-left: .3rem;
      .bodyBase2Medium();
      color: @palette-color-secondary-orange;
    }
  }
}
