@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.modal {
  :global(.ant-modal) {
    max-width: 64rem;
  }
}

.sectionTitle {
  margin-bottom: 1rem;

  .title1Bold();
  color: @palette-color-neutrals-dark;
}

.leaveDatePicker {
  width: 100%;

  :global(.ant-picker-clear) {
    right: 4rem;
    :global(.anticon) {
      .colorSvg(@palette-color-neutrals-grey2);
    }

    &:hover {
      :global(.anticon) {
        .colorSvg(@palette-color-secondary-red);
      }
    }
  }
}

.finalAdjustment {
  margin-top: 2.8rem;
}
