// @import '~antd/lib/style/themes/default.less';
// @import '~antd/dist/antd.less';

.Admin_pending_state{
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}