@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  position: relative;
  padding: 0 4rem 4rem;

  .userProfile {
    display: flex;
    flex-direction: column;
    align-items: center;

    .avatarUploader {
      width: auto;
    }

    .userProfileName {
      margin-top: 1.2rem;

      .headlineH2-2();
      color: @palette-color-neutrals-dark;
    }
  }

  .userDetailsTabs {
    margin-top: 3.5rem;

    :global(.ant-tabs) {
      overflow: visible;
    }
  }
}
