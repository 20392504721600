@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: 100%;
  height: 100%;

  &.withBgColor {
    background-color: @palette-color-fill-antiflash-white;
  }

  .title {
    .bodyBase1Bold();
    color: @palette-color-neutrals-dark;
    margin-bottom: .5rem;
    text-align: center;
  }

  .subtitle {
    .bodyBase2Medium();
    color: @palette-color-neutrals-grey1;
    margin-bottom: 2rem;
    text-align: center;
  }
}
