@import '~@palette/styles/colors.less';
@import '~@palette/styles/mixins.less';
@import '~@palette/styles/palette-theme.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 1.4rem;
  }

  .colRow {
    flex: 1;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-left: 1rem;
    .ellipsis();

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      justify-content: space-between;
      min-width: 30rem;
    }

    .details {
      white-space: normal;
      color: @palette-color-neutrals-grey1;
      .bodyBase2Medium();
      .ellipsis();
    }
    
    strong.details {
      .bodyBase2Bold();
    }

    .actions {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-end;

      .viewButton {
        padding: 0;
        height: auto;
        margin-left: 1.5rem;
      }
    }
  }
}
