@import '~@palette/styles/colors.less';

.wrapper {
  display: flex;
  flex-direction: column;
  padding: 4.1rem 2.4rem 4rem;

  .backLink {
    align-self: flex-start;
    margin-bottom: 2.4rem;

    .icon {
      .colorSvg(@palette-color-primary-blue);
    }
  }

  .loaderContainer {
    padding: 2rem;
  }

  .summaryContainer {
    margin-bottom: 4rem;
  }

  .tabs {
    width: 100%;
    margin: 0 auto;

    :global(.ant-tabs-nav) {
      margin-bottom: 3rem;
    }
  }
}
