@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  .dealSearch {
    width: 28rem;
    margin-bottom: 2rem;
  }

  .plans {
    .planCollapse {
      + .planCollapse {
        margin-top: 2rem;
      }
    }
  }
}
