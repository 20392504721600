@import '~@palette/styles/colors.less';

.wrapper {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: @palette-color-neutrals-grey2;

  position: absolute;
  top: -0.8rem;
  right: -0.8rem;
  z-index: 150;

  display: flex;
  align-items: center;
  justify-content: center;

  .icon {
    .colorSvg(@palette-color-border-light);
  }
}