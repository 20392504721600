@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';
@import '~@palette/styles/palette-theme.less';

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;

  width: fit-content;

  padding: 0.8rem 1.6rem;
  border: 0.1rem solid @palette-color-border-normal;
  border-radius: @border-radius-base;

  .editablePeriodYear {
    min-width: 24rem;
  }

  .contentWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .content {
      .bodyBase1Bold();
      color: @palette-color-neutrals-grey1;
    }

    .contentIcon {
      margin-left: 0.2rem;
      .colorSvg(@palette-color-neutrals-grey2);
    }
  }

  .prevNextWrapper {
    margin-left: 1rem;
    padding-left: 1rem;
    border-left: 0.1rem solid @palette-color-border-normal;

    display: flex;
    flex-direction: row;
    align-items: center;

    .prevNextBtn {
      padding: 0;
      width: 2.4rem;
      height: 2.4rem;

      & + .prevNextBtn {
        margin-left: 0.2rem;
      }

      :global(.anticon) {
        .colorSvg(@palette-color-neutrals-grey2);
      }

      &:hover, &:active, &:focus {
        :global(.anticon) {
          .colorSvg(@palette-color-primary-blue);
        }
      }
    }
  }
}
