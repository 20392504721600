@import '~@palette/styles/colors.less';
@import '~@palette/styles/mixins.less';
@import '~@palette/styles/palette-theme.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 1.4rem;
  }

  .colRow {
    flex: 1;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-left: 1rem;
    .ellipsis();

    &:first-child {
      margin-left: 0;

      @media(min-width: @screen-sm-max) {
        flex: 2;
      }
    }

    .details {
      white-space: normal;
      color: @palette-color-neutrals-grey1;
      .bodyBase2Medium();
      .ellipsis();
    }
  }
}
