@import '~@palette/styles/colors.less';

.wrapper {
  :global(.ant-progress-outer) {
    display: block;
    height: 1.8rem;

    :global(.ant-progress-inner) {
      display: block;
      border-radius: 0.4rem;
      background-color: @palette-color-border-light;

      :global(.ant-progress-bg) {
        height: 1.8rem !important;
        border-radius: 0.4rem;
        background: linear-gradient(270deg, #AAF2AD -0.86%, #88D98C 99.14%);
      }
    }
  }

  &.warning {
    :global(.ant-progress-outer) {
      :global(.ant-progress-inner) {
        :global(.ant-progress-bg) {
          background: linear-gradient(270deg, #FFC761 -0.47%, #FFAD6B 99.53%);
        }

        :global(.ant-progress-success-bg) {
          height: 1.8rem !important;
          border-radius: 0.4rem;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          background: linear-gradient(270deg, #88C7F8 -0.47%, #5BAAE8 99.53%);
        }
      }
    }
  }

  &.danger {
    :global(.ant-progress-outer) {
      :global(.ant-progress-inner) {
        :global(.ant-progress-bg) {
          background: linear-gradient(270deg, #FF8E75 -0.47%, #FC6C71 99.53%);
        }
      }
    }
  }

  &.info {
    :global(.ant-progress-outer) {
      :global(.ant-progress-inner) {
        :global(.ant-progress-bg) {
          background: linear-gradient(270deg, #88C7F8 -0.47%, #5BAAE8 99.53%);
        }
      }
    }
  }
}
