@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  max-width: 90rem;
  padding: 3rem 0 6rem;
  margin: 0 auto;

  .connectorsDescription {
    margin-bottom: 0.3rem;
    .bodyBase1Medium();
    color: @palette-color-neutrals-grey1;
  }

  .connectorsSelect {
    margin-bottom: 2.8rem;
    width: 30rem;
  }

  .filterWrapper {
    margin-bottom: 3.2rem;

    .selectFilter {
      width: 30rem;
    }

    .filterDescription {
      margin-top: 0.8rem;

      .bodyBase1Medium();
      color: @palette-color-neutrals-grey1;
    }
  }

  .tables {
    > div > div {
      border-radius: 0;

      > div:last-child {
        > div:last-child {
          > div:first-child {
            border-bottom-left-radius: 0;
          }

          > div:last-child {
            border-bottom-right-radius: 0;
          }
        }
      }
    }

    &.managersTable {
      + .usersTable {
        margin-top: 3.2rem;
      }
    }

    .actionButton {
      width: auto;
      margin: 0;
      margin-left: 1rem;

      &.actionTrashButton {
        color: @palette-color-secondary-red;
      }

      .actionTrashIcon {
        .colorSvg(@palette-color-secondary-red);
      }
    }
  }
}
