@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';
@import '~@palette/styles/palette-theme.less';
@import '~@palette/styles/mixins.less';

.wrapper {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  border: .1rem solid @palette-color-border-light;
  border-radius: @border-radius-base;
  margin: 0 .5rem;
  padding: 2rem 1.5rem 1rem;
  min-width: 20.15rem;
  max-width: 20.15rem;

  .title {
    .bodyBase2Medium();
    color: @palette-color-neutrals-grey1;
    margin-bottom: 1.5rem;
    text-align: center;
  }

  .amount {
    color: @palette-color-neutrals-dark;
    .bodyBase1Bold();
    margin-bottom: 2rem;
    text-align: center;
    width: 100%;
  }

  .name {
    .bodyBase1Bold();
    width: 100%;
    margin-bottom: .5rem;
    text-align: center;
  }

  .details {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;

    .period {
      .bodyBase2Medium();
      color: @palette-color-neutrals-grey1;
    }

    .scope {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-end;
      margin-left: 1rem;

      span {
        margin-left: 0;
        .caption1();
        padding: 0.2rem 0.5rem;

        & + span {
          margin-left: 0.5rem;
        }
      }
    }
  }

  .link {
    width: 100%;
    justify-content: center;

    span {
      .ellipsis();
    }
  }
}
