@import '~@palette/styles/colors.less';

.wrapper {
  .andOrSelector {
    display: flex;
    flex-direction: row;
    align-items: center;

    .andOrBtn + .andOrBtn {
      margin-left: 0.5rem;
    }

    .deleteBtn {
      margin-left: auto;
    }
  }

  .content {
    margin-top: 0.5rem;
    padding: 0 1rem 1rem 2rem;
    border-left: 0.1rem solid @palette-color-border-normal;
    width: fit-content;

    .addButtons {
      margin-bottom: 1rem;

      display: flex;
      flex-direction: row;
      align-items: center;

      .addBtn + .addBtn {
        margin-left: 1rem;
      }
    }

    .condition {
      margin-top: 1rem;
    }
  }
}
