@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.modal {
  :global(.ant-modal) {
    min-width: 65rem;
    max-width: 65rem;
  }

  .header {
    .title {
      .headlineH2-2();
      color: @palette-color-neutrals-dark;
    }

    .subTitle {
      margin-top: 0.3rem;
      .title2Medium();
      color: @palette-color-neutrals-grey1;
    }
  }
}
