@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.modal {
  :global(.ant-modal) {
    min-width: 65rem;
    max-width: 65rem;
  }

  .alert {
    margin-bottom: 2.4rem;
  }

  .alertMessageWrapper {
    display: flex;
    align-items: center;

    .message {
      margin-left: 1.6rem;

      .hint {
        .bodyBase2Bold();
      }

      .explanation {
        margin-top: 0.4rem;

        .caption1();
        color: @palette-color-neutrals-grey1;
      }
    }
  }

  .uploadAttachmentsBtn {
    justify-content: center;
  }

  .uploadsListWrapper {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;

    .uploadListItem {
      margin: 0.5rem;

      .removeUploadListItem {
        position: absolute;
        bottom: -1rem;
        left: -1rem;
        background-color: @palette-color-neutrals-white;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.6rem;
        margin: 0;
        height: auto;
        width: auto;

        &:hover, &:focus, &:active {
          background-color: @palette-color-neutrals-white;
        }
      }
    }
  }
}
