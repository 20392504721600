@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';
@import '~@palette/styles/palette-theme.less';
@import '~@palette/styles/mixins.less';

.wrapper {
  .contentWrapper {
    .title2Medium();
    color: @palette-color-neutrals-grey1;

    border-radius: 0.5rem;
    height: 2.4rem !important;
    width: 2.4rem !important;

    background-color: @palette-color-neutrals-grey2;

    .center(svg);

    &:hover {
      cursor: pointer;
      background-color: @palette-color-primary-blue;
    }
  }
}

.overlay {
  :global(.ant-dropdown-menu) {
    padding: 1.2rem 1.4rem;

    :global(.ant-dropdown-menu-item) {
      padding: 0;

      &:global(.ant-dropdown-menu-item-active) {
        background-color: transparent;
        border-radius: 0;

        .menuItem {
          color: @palette-color-primary-blue;
        }
      }
    }

    .menuItem {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: @palette-color-neutrals-grey1;

      &:hover {
        color: @palette-color-primary-blue;
      }

      .dragIcon {
        margin-top: .4rem;
        margin-right: .6rem;
        .colorSvg(@palette-color-neutrals-grey2);

        &.disabled {
          .colorSvg(@palette-color-fill-antiflash-white);
        }
      }

      .menuItemTitle {
        display: flex;
        align-items: center;

        .bodyBase2Medium();
        color: inherit;
        height: auto;
        width: 100%;
        padding: .5rem 0;
      }
    }
  }
}
