@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  .bodyBase2Bold();
  color: @palette-color-neutrals-dark;
  padding: 1.3rem 1.4rem;
  background-color: @palette-color-fill-table-group;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
