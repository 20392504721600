@import '~@palette/styles/typographies.less';
@import '~@palette/styles/colors.less';

.wrapper {
  .bodyBase2Medium();
  color: @palette-color-neutrals-grey1;

  .nameLink {
    display: block;
  }

  .type {
    margin-left: 0.8rem;
  }
}
