@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  .simpleLayout {
    display: flex;
    flex-flow: row wrap;
  }

  .conditionalLayout {
    display: flex;
    flex-flow: column nowrap;
  }

  .row {
    display: flex;
    flex-flow: row wrap;
    align-items: center;

    .formItem {
      margin-right: 1.5rem;
    }

    .dragIcon {
      cursor: grab;
      margin: 0 .6rem .6rem 0;
      .colorSvg(@palette-color-neutrals-grey2);
    }
  }

  .formItem {
    flex: 1;

    &.singleLine {
      max-width: 60%;
    }
  }

  .valueSingleContainer {
    flex: 2;
  }

  .inputArea {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-end;

    .inputField, .formulaInput {
      background-color: @palette-color-neutrals-white;
    }

    .actionButton {
      width: auto;
      margin-left: 1rem;
    }

    .actionIcon {
      .colorSvg(@palette-color-neutrals-dark);

      &.actionTrashIcon {
        .colorSvg(@palette-color-secondary-red);
      }
    }

    &.singleLine {
      max-width: 60%;
    }
  }

  .collapseContainer {
    margin-bottom: 1.8rem;

    :global(.ant-collapse-item) {
      :global(.ant-collapse-header) {
        padding: 0 !important;
      }
    }

    .collapseTitle {
      display: flex;
      flex-flow: row nowrap;
      .bodyBase1Medium();
      color: @palette-color-neutrals-grey1;
    }

    .actionDeleteLink {
      color: @palette-color-secondary-red;

      .actionIcon {
        .colorSvg(@palette-color-secondary-red);
      }
    }

    .addConditionButton {
      margin-bottom: 1rem;
    }
  }
}
