@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';
@import '~@palette/styles/palette-theme.less';

.wrapper {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  .btnTestWrapper {
    width: 100%;
    padding: .5rem 2rem .5rem .5rem;
    border-radius: @border-radius-base;
    border: 0.1rem solid @palette-color-border-normal;
    background-color: @palette-color-fill-table-group;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  
    .loadingWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
  
      .loadingIcon {
        .colorSvg(@palette-color-primary-blue);
      }
  
      .loadingLabel {
        margin-left: 1rem;
        .bodyBase2Bold();
        color: @palette-color-primary-blue;
      }
    }
  }

  .resultsWrapper {
    width: 100%;
    max-width: 28rem;
    margin-top: 3rem;
    display: flex;
    flex-flow: column;

    .previewTitle {
      .title1Bold();
      color: @palette-color-neutrals-dark;
      margin-bottom: 1.6rem;
      display: flex;
      justify-content: center;
    }

    .emptyWrapper {
      margin: 2rem 0;
    }
  }
}
