@import '~@palette/styles/colors.less';

.wrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  &:hover {
    .inputNumber, .selectCurrencies {
      border-color: @palette-color-shades-navy-cloudy-blue !important;
    }
  }

  .inputNumber {
    flex: 1;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;

    :global(.ant-input-number-handler) {
      border-right: .1rem solid @palette-color-input-number-handler;
    }

    :global(.ant-input-number-handler-wrap) {
      border-radius: 0;
    }

    :global(.ant-input-number-handler-up) {
      border-top-right-radius: 0;
    }

    :global(.ant-input-number-handler-down) {
      border-bottom-right-radius: 0;
    }

    &:global(.ant-input-number-focused) {
      border-color: @palette-color-border-normal;
    }
  }

  .selectCurrencies {
    flex: 0;
    min-width: 12rem;
    border: .2rem solid @palette-color-border-normal;
    border-left: none;
    border-top-right-radius: 1.2rem;
    border-bottom-right-radius: 1.2rem;
    transition: border-color 0.2s linear;

    :global(.ant-select-selector) {
      height: 3.6rem;
      padding-left: 2.4rem;
      border: none;
      border-radius: 0;
      background-color: transparent !important;
      box-shadow: none !important;
    }

    &.big {
      :global(.ant-select-selector) {
        height: 4.6rem;
        padding: 1.3rem 1.6rem 1.3rem 2.4rem;
      }
    }
  }
}
