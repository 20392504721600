.Source_vignette_wrapper{
  display: flex;
  border: 1px solid #e6e8f0;
  cursor:pointer;
  padding:20px 24px;
  transition: all .2s;
  position: relative;
  margin: 8px;
  width: 290px;
  max-width: 100%;
  border-radius: 18px;

  &:hover{
    box-shadow: #435a6f4d 0px 0px 1px, #435a6f78 0px 2px 4px -2px;
    transform: translateY(-2px);
  }

  &.Source_vignette_wrapper_disabled {
    background: #f4f5f8;
    cursor:not-allowed;

    &:hover{
      box-shadow: none;
      transform: none;
    }
  }

  .Source_vignette_icon{
    width: 60px;
    padding-right: 16px;
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .Source_vignette_type{
    color:grey
  }
  .Source_vignette_content_wrapper{
    text-align: left;
    flex-grow: 1;
  }
}