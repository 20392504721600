@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  .inputValue();
  color: @palette-color-neutrals-dark;

  &:global(:not(.ant-select-customize-input)) {
    :global(.ant-select-selector) {
      height: 4rem;
      padding: 0.8rem 1.6rem;
      border: 0.2rem solid @palette-color-border-normal;
      border-radius: 1.2rem;
    }
  }

  :global(.ant-select-arrow) {
    margin-top: -1.2rem;
    width: 2.4rem;
    height: 2.4rem;

    span {
      .colorSvg(@palette-color-neutrals-grey1);
    }
  }

  :global(.ant-select-selector) {
    height: 4rem;
    padding: 0.8rem 1.6rem;
    border: 0.2rem solid @palette-color-border-normal;
    border-radius: 1.2rem;

    :global(.ant-select-selection-item) {
      .inputValue();
      color: @palette-color-neutrals-dark;
    }

    :global(.ant-select-selection-placeholder) {
      .inputValue();
      color: @palette-color-neutrals-grey2;
    }

    :global(.ant-select-selection-search) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      left: 1.6rem;
    }
  }

  &.big {
    &:global(:not(.ant-select-customize-input)) {
      :global(.ant-select-selector) {
        height: 5rem;
        padding: 1.3rem 1.6rem;
      }
    }

    :global(.ant-select-selector) {
      height: 5rem;
      padding: 1.3rem 1.6rem;
    }
  }

  &:global(.ant-select-open) {
    :global(.ant-select-arrow) {
      margin-top: -1.2rem;
      width: 2.4rem;
      height: 2.4rem;
      transform: rotate(180deg);

      span {
        .colorSvg(@palette-color-primary-blue);
      }
    }

    &:global(.ant-select-show-search) {
      :global(.ant-select-arrow) {
        margin-top: -0.9rem;
        width: 1.8rem;
        height: 1.8rem;
        transform: rotate(0deg);
      }
    }
  }

  &:global(:not(.ant-select-disabled)) {
    &:hover {
      :global(.ant-select-selector) {
        border-color: @palette-color-shades-navy-cloudy-blue;
        border-right-width: 0.2rem !important;
      }
    }

    &:focus {
      :global(.ant-select-selector) {
        border-color: @palette-color-primary-blue;
        border-right-width: 0.2rem !important;
        box-shadow: none;
      }
    }
  }

  &:global(.ant-select-focused:not(.ant-select-disabled):not(.ant-select-customize-input)) {
    :global(.ant-select-selector) {
      border-color: @palette-color-primary-blue;
      border-right-width: 0.2rem !important;
      box-shadow: none;
    }
  }
}

.dropdown {
  border: 0.14rem solid @palette-color-border-light;
  box-shadow: @palette-shadow-1;
  border-radius: 1.2rem;
  padding: 1rem;
  width: 30rem;

  :global(.ant-select-tree-treenode:last-child) {
    padding-bottom: 0;
  }

  &.firstIsPlaceholder {
    :global(.ant-select-tree-treenode:first-child) {
      :global(.ant-select-tree-switcher) {
        :global(.ant-select-tree-switcher-leaf-line) {
          display: none;
        }
      }
    }

    &.onlyOneChoice {
      :global(.ant-select-tree-treenode:first-child) {
        :global(.ant-select-tree-switcher) {
          display: none;
        }
      }
    }
  }

  :global(.ant-select-tree-switcher) {
    padding: 0.8rem 0;

    :global(.ant-select-tree-switcher-icon) {
      .colorSvg(@palette-color-neutrals-grey1);
    }

    &:global(.ant-select-tree-switcher_open) {
      svg {
        transform: translate(-50%, -50%) rotate(90deg);
      }
    }

    &:global(.ant-select-tree-switcher_close) {
      svg {
        transform: translate(-50%, -50%) rotate(0deg);
      }
    }
  }

  :global(.ant-select-tree-node-content-wrapper) {
    display: flex;
    align-items: center;

    border-radius: @border-radius-base;

    margin-bottom: 0.3rem;
    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      background-color: @palette-color-fill-sky;
    }

    :global(.ant-select-tree-title) {
      padding: 0.8rem 1.4rem;
      .inputValue();
      color: @palette-color-neutrals-grey1;
    }

    &:global(.ant-select-tree-node-selected) {
      background-color: @palette-color-fill-sky;

      :global(.ant-select-tree-title) {
        color: @palette-color-primary-blue;
      }
    }
  }

  :global(.ant-select-tree-treenode-disabled) {
    :global(.ant-select-tree-node-content-wrapper) {
      background-color: @palette-color-fill-soft-vanilla;

      &:hover {
        background-color: @palette-color-fill-soft-vanilla;
      }
    }

    &:global(.ant-select-tree-treenode-selected) {
      :global(.ant-select-tree-node-content-wrapper) {
        :global(.ant-select-tree-title) {
          color: @palette-color-primary-blue;
        }
      }
    }
  }

  .not_found_content_description {
    .caption1();
    color: @palette-color-neutrals-grey1;
    text-align: center;
  }
}
