@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  padding: 0.4rem 0.8rem;
  border-radius: 0.5rem;
  position: relative;

  background-color: @palette-color-neutrals-dark;
  .bodyBase2Medium();
  color: @palette-color-neutrals-white;

  .contentWrapper {
    z-index: 2;
    position: relative;

    .title {
      .bodyBase2Medium();
      color: @palette-color-neutrals-white;
    }

    .content {
      margin-top: 0.2rem;

      .caption1();
      color: @palette-color-neutrals-grey2;
    }
  }

  &::after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -0.4rem;
    width: 1.3rem;
    height: 1.3rem;
    background-color: @palette-color-neutrals-dark;
    transform: translateX(-50%) rotate(45deg);
    border-radius: 0.3rem;
    z-index: 1;
  }
}
