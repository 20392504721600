@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  width: 100%;

  .item {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    & + .item {
      margin-top: 1rem;
    }

    .itemInput {
      flex: 1;
    }

    .deleteButton {
      width: auto;
      margin: 0 0 0 1rem;

      .trashIcon {
        .colorSvg(@palette-color-secondary-red);
      }
    }
  }

  .emptyList {
    .bodyBase2MediumItalic();
    color: @palette-color-neutrals-grey1;
  }
}
