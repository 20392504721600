@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  background-color: @palette-color-fill-lavender;
  border-radius: 1.2rem;
  padding: 1.6rem 2.4rem .6rem;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;

  .content {
    margin-bottom: 1rem;

    .icon {
      .colorSvg(@palette-color-neutrals-dark);
      margin-right: .8rem;
    }
  
    .title {
      display: flex;
      flex-direction: row;
      align-items: center;
      .title2Bold();
      color: @palette-color-neutrals-dark;
      margin-bottom: .6rem;
    }
  
    .description {
      display: flex;
      flex-direction: row;
      align-items: center;
      .bodyBase2Medium();
      color: @palette-color-neutrals-grey1;
    }

    &.withExtra {
      margin-right: 1.5rem;
    }
  }

  &.skyblue {
    background-color: @palette-color-shades-navy-sky-blue;
  }

  &.warning {
    background-color: @palette-color-fill-soft-vanilla;

    .content {
      .icon {
        .colorSvg(@palette-color-secondary-orange);
      }
    }
  }

  .extra {
    margin-bottom: 1rem;
  }
}
