@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  .bodyBase2Medium();
  color: @palette-color-neutrals-grey1;
  background-color: transparent;
  padding: 1rem 1.4rem;
  width: 100%;
  height: 100%;
  min-height: 4.4rem;
  display: flex;
  align-items: center;
  overflow: hidden;

  &.dark {
    color: @palette-color-neutrals-dark;
  }

  &.highlight {
    background-color: @palette-color-fill-table-group;
  }

  &.bold {
    .bodyBase2Bold();
  }

  &.borderBottom {
    border-bottom: .1rem solid @palette-color-border-light;
  }

  &.theme-white {
    color: @palette-color-neutrals-grey1;
    background-color: transparent;

    &.highlight {
      background-color: @palette-color-fill-table-group;
    }
  }

  &.theme-orange {
    color: @palette-color-neutrals-white;
    background-color: @palette-color-secondary-orange;
  }

  &.theme-yellow {
    color: @palette-color-secondary-brown-yellow;
    background-color: @palette-color-fill-soft-vanilla;
  }

  &.theme-khaki {
    color: @palette-color-shades-teal-hover;
    background-color: @palette-color-shades-primary-teal-ghost;
  }

  &.theme-green {
    color: @palette-color-neutrals-white;
    background-color: @palette-color-primary-teal;
  }
}
