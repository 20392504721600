@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  display: flex;
  flex-flow: row wrap;

  .teamCard {
    margin: 0.5rem;
  }
}
