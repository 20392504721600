@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';
@import '~@palette/styles/mixins.less';

.wrapper {
  width: fit-content;
  max-width: calc(100% - 1.6rem);

  padding: 0.4rem 1.6rem;
  background-color: @palette-color-neutrals-white;
  border: 0.1rem solid @palette-color-border-normal;
  border-radius: 0.8rem;

  .code();
  color: @palette-color-neutrals-dark;

  &:hover {
    cursor: pointer;
    background-color: @palette-color-fill-sky;
    border-color: @palette-color-shades-navy-cloudy-blue;
  }

  &.disabled {
    cursor: default;
    background-color: @palette-color-neutrals-white;
    border-color: @palette-color-border-normal;
  }
}

.cellNameWrapper {
  display: flex;
  align-items: center;

  .label {
    .bodyBase2Medium();
    color: @palette-color-neutrals-grey2;
    margin-right: 0.4rem;
  }

  .label:before {
    content: '*';
    color: @palette-color-secondary-red;
  }

  .input {
    width: calc(100% - 1.6rem);

    padding: 0.4rem 1.6rem;
    background-color: @palette-color-neutrals-white !important;
    border: 0.1rem solid @palette-color-primary-blue;
    border-radius: 0.8rem;

    .code();
    color: @palette-color-neutrals-dark;
    height: 2.6rem;
  }

  .required {
    border: 0.1rem solid @palette-color-secondary-red !important;
  }

  .required:after{
    content: attr(aria-errormessage);
    font-size: 1rem;
    color:@palette-color-secondary-red;
    position:relative;
    top:2.2rem;
    right:1.6rem;
    font-weight: 500;
  }
}