.popoverContentWrapper {
  display: flex;
  flex-direction: column;

  .owner + .owner {
    margin-top: 0.5rem;
  }
}

.wrapper {
  display: flex;
  flex-flow: row wrap;
  align-items: center;

  .ownersCountTag:hover {
    cursor: pointer;
  }
}
