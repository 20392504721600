@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  display: flex;
  flex-flow: column nowrap;

  .ruleWrapper {
    display: flex;
    flex-flow: column nowrap;
    margin-bottom: 2rem;
    width: 100%;

    .ruleName {
      .title2Bold();
      color: @palette-color-neutrals-grey1;
      margin-bottom: 1.2rem;
    }
  
    .ruleContainer {
      display: flex;
      flex-flow: column nowrap;
    }
  }

  .payoutScheduleWrapper {
    margin-bottom: 3rem;
    display: flex;
    flex-flow: column;
    align-items: center;

    .previewTitle {
      .title1Bold();
      color: @palette-color-neutrals-dark;
      margin-bottom: 1.6rem;
      display: flex;
    }

    .payoutScheduleChart {
      width: 100%;
      max-width: 28rem;
    }
  }
}