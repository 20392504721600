@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';
@import '~@palette/styles/mixins.less';

.wrapper {
  position: relative;

  &:not(:last-child) {
    .inline {
      margin-bottom: 1rem;
    }
  }

  .status {
    position: absolute;
    top: -.8rem;
    right: -.8rem;
  }

  .inline {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    .date {
      margin-right: 1.6rem;
      color: @palette-color-neutrals-grey2;
      .bodyBase2Medium();
    }

    .avatar {
      width: 100%;
      max-width: 16rem;
      margin-right: 2rem;
      .ellipsis();
    }

    .amount {
      flex: 1;
      margin-right: 2rem;
      white-space: nowrap;
      color: @palette-color-neutrals-dark;
      .bodyBase1Medium();
    }

    .status {
      position: relative;
      top: auto;
      right: auto;
    }
  }
}
