@import '~@palette/styles/colors.less';

.wrapper {
  .showHistoryBtn {
    height: 1.6rem;
    width: 1.6rem;
    background-color: transparent;
    padding: 0;
    border-radius: 0;

    .showHistoryBtnIcon {
      .colorSvg(@palette-color-neutrals-grey2);
    }

    &:hover {
      .showHistoryBtnIcon {
        .colorSvg(@palette-color-primary-blue);
      }
    }
  }
}
