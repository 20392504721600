@import '~@palette/styles/colors.less';
@import '~@palette/styles/mixins.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  .titleWrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    max-width: calc(100% - 6rem);

    .titleIcon {
      min-width: 1.8rem;
      .colorSvg(@palette-color-neutrals-grey1);
    }

    .title {
      margin-left: .8rem;
      color: @palette-color-neutrals-dark;
      .bodyBase2Medium();
      .ellipsis();
    }

    .dataframeNameButton {
      padding: 0;
      height: auto;
      margin: 0;
      margin-left: .8rem;
      width: 1.6rem;
    }
  }

  .columns {
    padding-left: 1.6rem;

    .columnWrapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;

      .icon {
        .colorSvg(@palette-color-neutrals-grey1);
      }

      .column {
        margin-left: 0.8rem;

        .bodyBase2Medium();
        color: @palette-color-neutrals-dark;
      }

      + .columnWrapper {
        margin-top: 0.8rem;
      }
    }
  }

  &:global(.ant-collapse-ghost) {
    :global(.ant-collapse-item) {
      :global(.ant-collapse-header) {
        height: 2.6rem;
      }
    }
  }
}
