@import '~@palette/styles/palette-theme.less';

.wrapper {
  position: relative;
  padding: 0 3rem 4rem;
  display: flex;
  flex-flow: row wrap;

  .cols {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;

    @media(min-width: @screen-md-min) {
      max-width: 50%;
    }

    @media(min-width: @screen-lg-min) {
      max-width: calc(100% / 3);
    }

    .cardContainer {
      margin: 0 1rem 2rem;
    }
  }
}
