@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  .bodyBase1Bold();
  background-color: @palette-color-banners-yellow;
  color: @palette-color-neutrals-white;
  text-align: center;
  padding: 0.5rem 1.5rem;

  .linkWrapper {
    margin-left: 2rem;

    .link {
      .bodyBase1Bold();
      color: @palette-color-neutrals-white;
      text-decoration: underline;
    }
  }
}