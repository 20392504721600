@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.descriptionWrapper {
  & + .descriptionWrapper {
    margin-top: 0.5rem;
  }

  .descriptionContent {
    margin-top: 0.2rem;
  }
}

.wrapper {
  cursor: pointer;
  color: @palette-color-neutrals-grey2;
  .bodyBase2Medium();
}
