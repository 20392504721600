@import '~@palette/styles/palette-theme.less';

.center(@elem) {
  position: relative;

  & > @{elem} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@keyframes spin {
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
}

.spin(@duration: 2000ms){
  animation-name: spin;
  animation-duration: @duration;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.widgetsGrid() {
  display: grid;
  gap: 2rem 1.5rem;
  grid-template-columns: repeat(auto-fit, minmax(calc(100% - 1.5rem), 1fr));

  @media(min-width: @screen-sm-min) {
    grid-template-columns: repeat(auto-fit, minmax(calc(50% - 1.5rem), 1fr));
  }

  @media(min-width: @screen-md-min) {
    grid-template-columns: repeat(auto-fit, minmax(calc(33.33% - 1.5rem), 1fr));
  }
}

// Widget sizes
.widgetSizeSmall() {
  grid-column-end: span 1;
}

.widgetSizeMedium() {
  grid-column-end: span 1;

  @media(min-width: @screen-sm-min) {
    grid-column-end: span 2;
  }
}

.widgetSizeLarge() {
  grid-column-end: span 1;

  @media(min-width: @screen-sm-min) {
    grid-column-end: span 2;
  }

  @media(min-width: @screen-md-min) {
    grid-column-end: span 3;
  }
}
