@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.modal {
  :global(.ant-modal) {
    max-width: 54rem;
  }
}

.content {
  .bodyBase1Medium();
  color: @palette-color-neutrals-dark;

  .formItem {
    margin-bottom: 0;
  }
}