@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.modal {
  :global(.ant-modal) {
    max-width: 60rem;
  }

  .titleWrapper {
    .headlineH2-2();
    color: @palette-color-neutrals-dark;
  }

  .modalContent {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;

    .currentLogoWrapper {
      position: relative;
      margin-bottom: 4rem;

      .removeWrapper {
        position: absolute;
        bottom: -1rem;
        left: -1rem;
        background-color: @palette-color-neutrals-white;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: .6rem;
        margin: 0;
        height: auto;
        width: auto;

        &:hover, &:focus, &:active {
          background-color: @palette-color-neutrals-white;
        }
      }
    }

    .dividerWrapper {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-bottom: 4rem;

      .divider {
        width: 100%;
        height: .1rem;
        background-color: @palette-color-border-light;
      }

      .dividerLabel {
        position: absolute;
        color: @palette-color-neutrals-grey1;
        .bodyBase1Medium();
        padding: 0 2rem;
        background-color: @palette-color-neutrals-white;
        text-transform: uppercase;
      }
    }
  }
}
