@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  display: flex;
  flex-direction: column;

  .widgetsTitle {
    .title1Bold();
    color: @palette-color-neutrals-dark;

    padding: 2.4rem 1.4rem 2.4rem 0;

    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .widgetsList {
    padding: 2.4rem 1.4rem 2.4rem 0;

    .widgetsListItem {
      padding-top: 1rem;
      border-top: 0.1rem solid @palette-color-border-light;

      + .widgetsListItem {
        margin-top: 1rem;
      }
    }

    .widgetWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;

      .dragIcon {
        margin-right: 0.6rem;
        .colorSvg(@palette-color-neutrals-grey2);
      }

      .widgetNameDescriptionWrapper {
        flex: 1;

        .widgetName {
          .bodyBase1Bold();
          color: @palette-color-neutrals-dark;
        }

        .widgetDescription {
          margin-top: 0.5rem;
          .caption1();
          color: @palette-color-neutrals-dark;
        }
      }

      .removeButton {
        margin-left: 4.5rem;

        padding: 0;
        min-width: 2.4rem;
        width: 2.4rem;
        height: 2.4rem;
        border-radius: 50%;
        background-color: @palette-color-neutrals-white;
        display: flex;
        align-items: center;
        justify-content: center;

        :global(.anticon) {
          .colorSvg(@palette-color-shades-navy-cloudy-blue);
        }

        &:hover {
          :global(.anticon) {
            .colorSvg(@palette-color-primary-blue);
          }
        }
      }
    }
  }

  .addWidgetButton {
    width: fit-content;
    margin: 2.4rem auto;
  }
}
