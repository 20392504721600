@import '~@palette/styles/palette-theme.less';

.wrapper {
  .loaderWrapper {
    height: 13.8rem;
  }

  .container {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    width: calc(100% + 1.2rem);
    margin-left: -.6rem;

    @media(max-width: @screen-md-max) {
      justify-content: flex-start;
    }
  
    .period {
      margin: 0 .6rem 1.2rem;
      min-width: 21rem;
      flex-grow: 0;

      @media(min-width: @screen-lg-min) {
        flex-grow: 1;
      }

      @media(max-width: @screen-xl-max) {
        min-width: 16rem;
      }

      @media(max-width: @screen-md-max) {
        min-width: calc((100% - (3 * 1.2rem)) / 3);
      }

      @media(max-width: @screen-sm-max) {
        min-width: calc((100% - (2 * 1.2rem)) / 2);
        max-width: calc(50% - 1.2rem);
      }
    
      @media(max-width: @screen-xs-max) {
        min-width: calc(100% - 1.2rem);
        max-width: 100%;
      }
    }

    &.hasMultipleLines {
      justify-content: flex-start;

      .period {
        @media(min-width: @screen-lg-min) {
          flex-grow: 0;
        }
      }
    }
  }
}
