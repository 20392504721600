@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  width: 100%;
  height: 100%;

  padding: 2rem;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  background-color: @palette-color-fill-table-group;
  border-radius: 1.4rem;

  .amount {
    margin-top: 4.5rem;

    .headlineH3();
    color: @palette-color-neutrals-dark;

    text-align: center;
  }

  .description {
    margin-top: 0.8rem;

    text-align: center;

    .caption1();
    color: @palette-color-neutrals-grey1;
  }
}
