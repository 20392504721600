.selectDropdown {
  .userOption {
    width: 100%;
  }
}

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;

  .select {
    width: 24rem;
    min-width: 24rem;

    margin-left: 1.2rem;
  }
}
