@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.modal {
  :global(.ant-modal) {
    max-width: 56rem;
  }

  .resetToOriginalButton {
    padding: 0;
    max-width: 13rem;

    span {
      white-space: pre-line;
      text-align: left;
    }
  }

  .contentWrapper {
    .commissionAmountWrapper {
      position: relative;

      margin-top: 1.4rem;

      padding: 2rem 0;
      background-color: @palette-color-fill-lavender;

      display: flex;
      flex-direction: column;
      align-items: center;

      border-radius: 1.4rem;

      overflow: hidden;

      .illustration {
        position: absolute;
        top: -1rem;
        right: 0;
      }

      .amount {
        display: flex;
        flex-direction: row;
        align-items: baseline;

        .headlineH2-2();
        color: @palette-color-neutrals-dark;

        .integerPart {
          .headlineH2-2();
          color: @palette-color-neutrals-dark;
        }

        .decimalPart {
          .bodyBase1Medium();
          color: @palette-color-neutrals-dark;
        }
      }

      .label {
        margin-top: 0.6rem;

        .caption1();
        color: @palette-color-secondary-lavender;
      }
    }

    .scheduleWrapper {
      max-height: calc(100% - 18.4rem);
      margin-top: 2rem;
      display: flex;
      flex-direction: column;

      .header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .label {
          .headlineH3();
          color: @palette-color-neutrals-dark;
        }

        .modeSelectWrapper {
          display: flex;
          flex-direction: row;
          align-items: center;

          .modeSelectLabel {
            .bodyBase2Medium();
            color: @palette-color-neutrals-grey2;
          }

          .modeSelectBtn {
            margin-left: 0.8rem;
          }
        }
      }

      .periodIsFrozenAlert {
        margin-top: 1.6rem;
      }

      .content {
        flex: 1;

        max-height: calc(100% - 11.7rem);
        overflow: auto;

        margin-top: 1.6rem;

        .payment {
          margin-top: 1rem;
          &:first-child {
            margin-top: 0;
          }
        }

        .addButton {
          margin-top: 1.6rem;
        }
      }

      .footer {
        margin-top: 2rem;

        border-top: 0.1rem solid @palette-color-border-light;
        padding-top: 2rem;

        display: flex;
        flex-direction: row;
        align-items: center;

        .totalLabel {
          .bodyBase1Medium();
          color: @palette-color-neutrals-dark;
        }

        .totalAmount {
          margin-left: 0.6rem;

          .bodyBase1Medium();
          color: @palette-color-neutrals-dark;
        }

        .totalPercentage {
          margin-left: 0.6rem;

          .bodyBase1Medium();
          color: @palette-color-neutrals-grey2;
        }
      }
    }

    &.periodIsFrozen {
      .content {
        max-height: calc(100% - 17.1rem);
      }
    }
  }
}
