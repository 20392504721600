@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  .bodyBase2Medium();
  color: @palette-color-neutrals-grey1;

  .falseValueIcon {
    .colorSvg(@palette-color-secondary-red);
    min-width: 1.8rem;
  }

  .trueValueIcon {
    .colorSvg(@palette-color-primary-teal);
    min-width: 1.8rem;
  }

  .valueArrayItem + .valueArrayItem {
    margin-top: 0.7rem
  }

  .emptyList {
    .bodyBase2MediumItalic();
    color: @palette-color-neutrals-grey1;
  }
}
