@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';
@import '~@palette/styles/palette-theme.less';

.wrapper {
  background-color: @palette-color-fill-table-group;
  border-radius: @border-radius-base;
  padding: 2rem;

  .title {
    .bodyBase1Bold();
    color: @palette-color-neutrals-grey2;
  }

  .list {
    margin-top: 2.8rem;

    .variable {
      margin-bottom: 1.8rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
