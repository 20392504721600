@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';
@import '~@palette/styles/mixins.less';

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 2rem 1.5rem;
  border: 0.1rem solid @palette-color-border-light;
  border-radius: 1.6rem;

  width: 36rem;

  .name {
    width: 20rem;

    .bodyBase1Bold();

    .ellipsis();
  }

  .members {
    margin-left: 1.5rem;
    width: 10rem;
  }

  &:hover {
    border-color: @palette-color-shades-navy-sky-blue;
  }
}
