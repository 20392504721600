@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';
@import '~@palette/styles/palette-theme.less';

.wrapper {
  overflow: hidden;
  max-height: 8rem;
  padding: 2rem;
  background-color: transparent;
  transition: max-height 200ms ease-in-out, background-color 200ms ease-in-out;

  .buttonWrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    .button {
      .bodyBase2Bold();
      color: @palette-color-neutrals-grey1;
    }

    .icon {
      .colorSvg(@palette-color-neutrals-grey1);
    }
  }

  .rawDataWrapper {
    overflow: auto;
    max-height: calc(100% - 6.8rem);
    margin-top: 0.5rem;

    .rawData {
      span:first-of-type {
        .bodyBase1Medium();
      }
    }
  }

  &.isOpen {
    max-height: fit-content;
    background-color: @palette-color-fill-table-group;

    border-radius: @border-radius-base;

    .buttonWrapper {
      .icon {
        transform: rotate(90deg);
      }
    }
  }
}
