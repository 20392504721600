@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  border: 0.1rem solid @palette-color-border-light;
  border-radius: 1.8rem;

  .detail {
    display: flex;
    flex-direction: row;

    .label {
      padding-left: 2rem;
      padding-right: 2rem;
      padding-bottom: 1.2rem;

      width: 12.7rem;
      min-width: 12.7rem;

      .caption1();
      color: @palette-color-neutrals-grey2;

      background-color: @palette-color-fill-grey;
    }

    .value {
      padding-left: 2rem;
      padding-right: 2rem;
      padding-bottom: 1.2rem;
      width: 100%;

      .bodyBase2Medium();
      color: @palette-color-neutrals-grey1;

      .complement {
        .bodyBase2Medium();
        color: @palette-color-neutrals-grey2;
      }

      .actions{
        display: flex;
        justify-content: space-between;
      }
    }

    &:first-child {
      .label {
        padding-top: 2rem;
        border-top-left-radius: 1.8rem;
      }

      .value {
        padding-top: 2rem;
        border-top-right-radius: 1.8rem;
      }
    }

    &:last-child {
      .label {
        padding-bottom: 2rem;
      }

      .value {
        padding-bottom: 2rem;
      }
    }
  }
}
