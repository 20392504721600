.wrapper {
  .header {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;

    .dealSearch {
      width: 100%;
      max-width: 28rem;
      margin-right: 2rem;
      margin-bottom: 2.4rem;
    }

    .actions {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 2.4rem;
  
      .actionLink {
        &:not(:last-child) {
          margin-right: 2rem;
        }
      }
    }
  }
}
