@import '~@palette/styles/colors.less';

@body-background: @palette-color-neutrals-white;
@border-radius-base: 1rem;
@btn-border-width: 0;
@btn-primary-bg: @palette-color-primary-teal;
@font-family: 'Satoshi', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';
@font-size-base: 14px;
@font-size-base-rem: 1.4rem;
@heading-color: @palette-color-neutrals-dark;
@layout-body-background: @palette-color-neutrals-white;
@layout-sider-background: @palette-color-shades-sea-blue;
@link-color: @palette-color-primary-teal;
@primary-color-hover: @palette-color-shades-teal-hover;
@text-color: @palette-color-neutrals-dark;

// Media queries breakpoints
@screen-xs: 480px;
@screen-xs-min: @screen-xs;
// 👆 Extra small screen / phone

// 👇 Small screen / tablet
@screen-sm: 576px;
@screen-sm-min: @screen-sm;

// Medium screen / desktop
@screen-md: 768px;
@screen-md-min: @screen-md;

// Large screen / wide desktop
@screen-lg: 992px;
@screen-lg-min: @screen-lg;

// Extra large screen / full hd
@screen-xl: 1200px;
@screen-xl-min: @screen-xl;

// Extra extra large screen / large desktop
@screen-xxl: 1600px;
@screen-xxl-min: @screen-xxl;

// provide a maximum
@screen-xs-max: (@screen-sm-min - 1px);
@screen-sm-max: (@screen-md-min - 1px);
@screen-md-max: (@screen-lg-min - 1px);
@screen-lg-max: (@screen-xl-min - 1px);
@screen-xl-max: (@screen-xxl-min - 1px);


// Other overrides from previous less configuration
@primary-color: #1eb1b5;
@layout-header-height: 51px;
@layout-header-padding: 0px 0px;
@layout-header-background: #00718c;
@layout-footer-padding: 0px;
@collapse-content-bg: #ffffff;
@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),0 9px 28px 8px rgba(0, 0, 0, 0.05);
