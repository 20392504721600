@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  .dateYearPickerWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .yearPickerWrapper {
      margin-left: 0.5rem;

      position: relative;

      .yearPicker {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 1.4rem;
        min-width: 1.4rem;
        padding: 0;
        border: 0;
        background: transparent !important;
        z-index: 2;

        > :global(.ant-picker-input) {
          visibility: hidden;

          :global(.ant-picker-suffix) {
            display: none;
          }
        }
      }

      &:hover .editIcon {
        .colorSvg(@palette-color-primary-blue);
      }
    }
  }

  .content {
    .bodyBase2Medium();
    color: @palette-color-neutrals-dark;
    text-align: center;
  }

  .editIcon {
    cursor: pointer;

    .colorSvg(@palette-color-neutrals-grey1);

    &:hover {
      .colorSvg(@palette-color-primary-blue);
    }

    &.popoverVisible {
      .colorSvg(@palette-color-primary-blue);
    }
  }

  .popoverWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .editIcon {
      margin-left: 0.5rem;
    }
  }
}

.yearPickerDropdown {
  :global(.ant-picker-footer) {
    :global(.ant-picker-footer-extra) {
      padding: 0;
    }
  }
}

.popoverContent {
  display: flex;
  flex-direction: column;
  align-items: center;

  .useMostRecentSwitchWrapper {
    margin-top: 1.6rem;

    display: flex;
    flex-direction: row;
    align-items: center;

    .useMostRecentLabel {
      margin-left: 0.7rem;

      .bodyBase1Medium();
      color: @palette-color-neutrals-dark;
    }
  }
}
