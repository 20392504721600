@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  width: fit-content;

  .contentWrapper {
    width: 25rem;
    height: 5rem;
    padding: 1.5rem 1.6rem;

    border: 0.2rem solid @palette-color-border-normal;
    border-radius: 1.2rem;
    cursor: pointer;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    &:hover {
      border-color: @palette-color-shades-navy-cloudy-blue;
    }

    .content {
      .bodyBase1Bold();
      color: @palette-color-neutrals-dark;
    }

    .contentIcon {
      margin-left: 0.2rem;
      .colorSvg(@palette-color-neutrals-dark);
    }

    &.popoverVisible {
      border-color: @palette-color-primary-blue;
    }
  }
}

:global(.ant-form-item-has-error) {
  .wrapper {
    .contentWrapper {
      border-color: @palette-color-border-input-error;
    }
  }
}
