@import '~@palette/styles/typographies.less';
@import '~@palette/styles/colors.less';

.wrapper {
  :global(.ant-collapse-ghost) {
    :global(.ant-collapse-item) {
      :global(.ant-collapse-header) {
        height: 4.4rem;
        display: flex;
        align-items: center;
        .bodyBase1Medium();
        color: @palette-color-neutrals-dark;

        :global(.ant-collapse-arrow) {
          .colorSvg(@palette-color-neutrals-dark);
        }

        div:first-child {
          height: 4.4rem;
          display: flex;
          align-items: center;
        }

        small {
          margin-left: .8rem;
          padding-top: .2rem;
          color: @palette-color-neutrals-grey1;
          .bodyBase1Medium();
          font-weight: @palette-font-weight-regular;
        }
      }

      :global(.ant-collapse-content) {
        margin-top: 0;
      }
    }
  }
}
