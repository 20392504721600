.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;

  .fieldFormulaInput {
    min-width: 16rem;

    :global(.ant-select-selector) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .operatorSelect {
    min-width: 10rem;

    :global(.ant-select-selector) {
      border-left: none;
      border-right: none;
      border-radius: 0;
    }
  }

  .codeInput {
    min-width: 20rem;

    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .deleteBtn {
    padding: 0;
    margin: 0 1rem;
  }
}
