@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .labelWrapper {
    .bodyBase2Medium();
    color: @palette-color-neutrals-dark;
    display: flex;
    align-items: center;
    height: 4rem;
    margin: 0 1rem;
  }

  .valueWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .value {
      .title1Bold();
      color: @palette-color-neutrals-dark;

      display: flex;
      flex-direction: row;
      align-items: baseline;

      .integerPart {
        .title1Bold();
        color: @palette-color-neutrals-dark;
      }

      .decimalPart {
        .bodyBase1Bold();
        color: @palette-color-neutrals-grey2;
      }
    }

    .initialValue {
      margin-left: .5rem;

      .caption1();
      color: @palette-color-neutrals-grey2;
      text-decoration: line-through;
    }

    .button {
      margin: 0;
      padding: .4rem 0;
      height: auto;
      justify-content: center;
    }
  }
}
