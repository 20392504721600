@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  position: relative;
  padding: 0 4rem;
  margin-bottom: 4rem;

  .header {
    margin: 4rem 0;

    display: flex;
    flex-flow: row wrap;
    align-items: baseline;
    justify-content: space-between;

    .actions {
      display: flex;
      flex-flow: row wrap;
      align-items: center;

      .applyPresetBtn {
        margin-left: 2.4rem;
      }

      .moreMenu {
        margin-left: 1.5rem;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: row;

    .widgets {
      width: 28rem;
      min-width: 28rem;
      border-right: 0.1rem solid @palette-color-border-light;
    }

    .preview {
      flex: 1;

      max-width: calc(100% - 28rem);

      padding: 2.4rem 0 2.4rem 2.4rem;

      .previewHeader {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: space-between;

        .previewHeaderTitle {
          .title1Bold();
          color: @palette-color-neutrals-dark;
        }

        .presetUserSelect {
          margin-left: 1.2rem;
        }
      }

      .previewAlert {
        margin-top: 2.4rem;
      }

      .previewContent {
        margin-top: 2.4rem;
      }
    }
  }
}
