.wrapper {
  .currencySelectWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .periodsNavigationWrapper {
    margin-bottom: 2.6rem;
  }
}
