@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.recursivityDepthLimitReachedWrapper {
  width: 100%;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  .recursivityDepthLimitReachedIcon {
    .colorSvg(@palette-color-neutrals-grey1);
  }

  .recursivityDepthLimitReachedLabel {
    margin-left: 1rem;

    text-align: center;
    .bodyBase2Medium();
    color: @palette-color-neutrals-grey1;
  }
}

.collapse {
  border-radius: 0;

  &:not(div:first-of-type) {
    border-top: .1rem solid @palette-color-border-light;
  }
}
