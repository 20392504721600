.wrapper {
  height: 100%;
  width: 100%;
  padding-top: 4rem;

  .breadcrumb {
    margin-left: 4rem;
  }

  .pageTitle {
    flex-flow: row wrap;

    .documentationLink {
      margin-left: auto;
    }
  }
}
