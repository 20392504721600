@import '~@palette/styles/colors.less';
@import '~@palette/styles/palette-theme.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  .collapseContainer {
    margin-bottom: 2rem;

    .title {
      .bodyBase2Bold();
      color: @palette-color-neutrals-dark;
    }

    .extraDetails {
      margin-left: 1rem;
      display: flex;
      flex-direction: row;
      align-items: center;

      .totalLabel {
        .bodyBase2Medium();
        color: @palette-color-neutrals-grey1;
      }

      .totalAmount {
        margin-left: .6rem;

        .bodyBase2Bold();
        color: @palette-color-neutrals-dark;
      }
    }

    .adjustmentsListHeader {
      margin-bottom: 1.4rem;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;

      @media(max-width: @screen-xs-max) {
        display: none;
      }

      .colRow {
        flex: 1;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        margin-left: 1rem;
        color: @palette-color-neutrals-grey2;
        .bodyBase2Medium();

        &:first-child {
          margin-left: 0;

          @media(min-width: @screen-sm-max) {
            flex: 2;
          }
        }
      }
    }

    .adjustmentItem + .adjustmentItem {
      margin-top: 0.8rem;
    }
  }
}
