@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  .ruleNumber {
    .bodyBase1Bold();
    color: @palette-color-neutrals-grey2;

    margin-bottom: 1.6rem;
  }

  .description {
    .bodyBase2Medium();
    color: @palette-color-neutrals-dark;

    strong {
      .bodyBase2BoldItalic();
      color: @palette-color-neutrals-dark;
    }
  }
}
