@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  //
}

.variableNameWrapper {
  display: flex;
  align-items: center;
  margin: 0.8rem 0 0 2rem;

  .variableNameIcon {
    .colorSvg(@palette-color-neutrals-grey2);
  }

  .variableName {
    margin-left: 0.8rem;
    padding: 0.4rem 1.6rem;
    .code();
    color: @palette-color-neutrals-grey3;
    background-color: @palette-color-shades-navy-sky-blue;
    border-radius: 0.8rem;
    transform: translateY(0.6rem);
  }
}
