@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  .title {
    color: @palette-color-neutrals-dark;
    .title2Medium();
    margin-bottom: 1.4rem;
  }

  .dataConnectionsList {
    margin-bottom: 3.2rem;

    .dataConnectionsListItem + .dataConnectionsListItem {
      margin-top: 1rem;
    }
  }
}
