@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  .header {
    .titleActionsWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      height: 4.4rem;

      .saveStatusWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;

        .saveStatusLabel {
          margin-left: 1.2rem;
          .caption1();
          color: @palette-color-primary-teal;

          &.isSaving {
            color: @palette-color-neutrals-grey1;
          }
        }

        .saveStatusIcon {
          margin-left: 0.3rem;
        }
      }
    }

    .descriptionWrapper {
      margin-top: 2.4rem;
    }
  }

  .cellsWrapper {
    .addCell {
      margin-top: 2.4rem;
    }

    .cell {
      margin-top: 1.6rem;
    }
  }
}
