@import '~@palette/styles/colors.less';

.wrapper {
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;

  .avatar {
    border: .1rem solid @palette-color-neutrals-dark;
    border-radius: 50%;
    overflow: hidden;
  }
}
