@import '~@palette/styles/colors.less';

.wrapper {
  .variablesDroppable {
    margin-bottom: 2rem;

    .container {
      cursor: default;
      border-bottom: .1rem solid @palette-color-border-light;
      margin: 1rem 0;
    }
  }
}