@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  padding: 0.8rem 1.6rem;
  border: 0.2rem solid @palette-color-border-normal;
  border-radius: 1.2rem;
  width: 100%;
  background-color: transparent;

  .inputValue();
  color: @palette-color-neutrals-dark;

  &.disableResize {
    resize: none;
  }

  &::placeholder {
    color: @palette-color-neutrals-grey2;
    border-right-width: 0.2rem !important;
  }

  &:hover {
    border-color: @palette-color-shades-navy-cloudy-blue;
    border-right-width: 0.2rem !important;
    box-shadow: none;
  }

  &:focus {
    border-color: @palette-color-primary-blue;
    border-right-width: 0.2rem !important;
    box-shadow: none;
  }

  &.disabled {
    border-color: @palette-color-border-normal;
  }

  &:global(.ant-input-disabled) {
    color: @palette-color-neutrals-dark;
  }
}
