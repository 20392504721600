@import '~@palette/styles/colors.less';

.wrapper {
  display: flex;
  align-items: center;

  .percent {
    color: @palette-color-neutrals-grey2;
  }

  .amount {
    margin-left: 0.5rem;
  }
}
