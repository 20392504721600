.wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  overflow: auto;
  height: 100%;

  .warning {
    width: 100%;
  }

  .profileWrapper {
    position: relative;
    width: 25%;
    margin: 1.2rem 0;

    .profile {
      width: fit-content;
      margin: auto;
    }

    .onRemoveBtnWrapper {
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-50%, -50%);

      .onRemoveBtn {
        opacity: 0;
        transition: opacity 0.2s ease-in;
      }
    }

    &:hover {
      .onRemoveBtnWrapper {
        .onRemoveBtn {
          opacity: 1;
        }
      }
    }
  }
}
