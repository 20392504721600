@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  margin-bottom: 2rem;

  .connectorNameWrapper {
    .bodyBase1Bold();
    color: @palette-color-neutrals-dark;
  }

  .list {
    padding-left: 1.6rem;

    .listItem + .listItem {
      margin-top: 1.2rem;
    }
  }
}
