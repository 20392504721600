@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  display: flex;
  flex-flow: row wrap;

  .connectorItem {
    margin: 1.2rem;

    padding: 2.4rem;
    cursor: pointer;

    width: 32rem;
    max-width: 32rem;

    border: 0.1rem solid @palette-color-border-normal;
    border-radius: 1.6rem;

    display: flex;
    align-items: center;

    .connectorIcon {
      width: 6.4rem;
      height: 6.4rem;
      display: flex;
      align-items: center;
      justify-content: center;

      padding: 1.2rem;

      border: 0.1rem solid @palette-color-border-normal;
      border-radius: 0.8rem;

      :global(.anticon) svg {
        width: 4rem;
        height: 4rem;
      }
    }

    .connectorDetails {
      margin-left: 0.8rem;

      .connectorName {
        .bodyBase2Bold();
        color: @palette-color-neutrals-dark;
      }

      .connectorLastSync {
        margin-top: 0.4rem;

        .caption1();
        color: @palette-color-neutrals-grey1;
      }

      .usedResources {
        margin-top: 0.4rem;

        .caption1();
        color: @palette-color-neutrals-grey1;
      }
    }

    &:hover {
      border-color: @palette-color-primary-blue;
    }

    &.disabledConnector {
      cursor: not-allowed;

      &:hover {
        border-color: @palette-color-border-normal;
      }
    }
  }
}
