@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  margin: 0 4rem 3rem 4rem;
  display: flex;
  flex-flow: row wrap;
  align-items: center;

  .titleContainer {
    display: flex;
    flex-flow: row nowrap;
    margin-right: .8rem;

    .icon {
      margin-right: .4rem;
  
      .colorSvg(@palette-color-neutrals-dark);
    }
  
    .title {
      .bodyBase2Bold();
      color: @palette-color-neutrals-dark;
    }
  }

  .content {
    display: flex;
    flex-flow: row wrap;

    .injectionItem {
      color: @palette-color-neutrals-grey1;
      margin-right: .4rem;

      &:not(:last-child) {
        &::after {
          content: ' |';
        }
      }

      .injectionLink {
        color: @palette-color-neutrals-grey1;
      }
    }
  }
}
