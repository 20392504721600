@import '~@palette/styles/colors.less';

.wrapper {
  position: relative;
  padding: 2.4rem;
  border-radius: 2rem;
  border: 0.1rem solid @palette-color-border-light;
  background-color: @palette-color-neutrals-white;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-between;

  .actionsWrapper {
    position: absolute;
    top: 0;
    right: 0;

    padding: 0.6rem 1rem;
    border: 0.1rem solid @palette-color-border-light;
    border-radius: 1.6rem;
    background-color: @palette-color-neutrals-white;

    transform: translateY(-42%);

    opacity: 0;
    transition: opacity 200ms ease-in-out;

    display: flex;
    align-items: center;

    .actionButton {
      padding: 0;
      width: 2rem;
      height: 2rem;

      + .actionButton {
        margin-left: 0.5rem;
      }
    }
  }

  &:hover {
    .actionsWrapper {
      opacity: 1;
    }
  }
}
