@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';
@import '~@palette/styles/palette-theme.less';

.wrapper {
  .dealSearch {
    width: 28rem;
    margin-bottom: 2rem;

    @media(max-width: @screen-xs-max) {
      width: 100%;
    }
  }

  .plans {
    .planCollapse {
      + .planCollapse {
        margin-top: 2rem;
      }
    }
  }
}
