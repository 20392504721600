.wrapper {
  display: block;
  height: 100%;
  overflow: hidden;

  .alert {
    margin-bottom: 3rem;
  }

  .selectResourcesFlowStep {
    height: 100%;
    min-height: 20rem;
  }
}
