.inner {
  display: flex;
  align-items: center;

  .contentNode {
    flex-grow: 1;
  }
  
  .tagContainer {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    padding: 0.2rem 0.4rem;
  }
}
