@import '~@palette/styles/colors.less';

.wrapper {
  .yearPicker {
    border: 0;
    background-color: @palette-color-fill-sky;
  }

  .table {
    margin-top: 2rem;
  }
}
