.QueryBuilder {
  .Group {
    border-radius: 12px;
    background: rgb(250, 250, 250);

    &.Level0 {
      padding: 12px;
      border: 1px lightgrey solid;
    }

    &:not(.Level0) {
      padding-top: 8px;
      padding-bottom: 8px;
      padding-left: 24px;
      margin-top: 4px;
    }
  }

  .Rule {
    border-radius: 3px;
    margin-top: 4px;
    align-items: center;
    justify-content: center;

    > :first-child {
      .ant-select-selector {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        &:hover {
          input {
            z-index: 100;
          }
        }
      }
      &.ant-select-focused, &:hover {
        z-index: 100;
      }
    }
    > :nth-child(2) {
      .ant-select-selector {
        border-radius: 0;
        margin-left: -1px;
      }
      &.ant-select-focused, &:hover {
        z-index: 100;
      }
    }
    > :nth-child(3).ant-input {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      margin-left: -2px;
    }
  }

  .AddButtons {
    margin-top: 4px;
  }

  .ErrorItem {
    color: red;
    font-weight: bold;
  }

  > .DisplayContainer {
    background: white;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 3px 0;
    padding-left: 11px;
    padding-right: 3px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    // max-width: ~"calc(100% - 12px)";
  }

  .DisplayContainer_inner{
    max-width: ~"calc(100% - 65px)";
  }

  .HtmlGroupContainer {
    display: inline;
    max-width: 100%;

    &:before {
      content: "(";
      display: inline;
    }
    &:after {
      content: ")";
      display: inline;
    }

    .HtmlGroupOperator {
      margin: 0 4px;
      color: orange;
      font-weight: bold;
      display: inline;
    }

    .HtmlRule {
      display: inline;
      max-width: 100%;
      word-break: break-all;
      .HtmlField {
        font-weight: bold;
        display: inline;
      }
      .HtmlOperator {
        margin: 0 4px;
        color: orange;
        font-weight: bold;
        display: inline;
      }
      .HtmlValue {
        color: dodgerblue;
        white-space: pre;
        max-width: 100%;
        display: inline;
        white-space: normal;
      }
    }
  }
}

.QueryBuilderModal {
  width: 800px !important;
}
