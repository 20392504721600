@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  display: flex;
  flex-direction: row;
  position: relative;
  height: 2.4rem;

  .avatar {
    position: absolute;
    top: 0;
  }

  .diffNbUsers {
    border: 0.1rem solid @palette-color-neutrals-dark;
    background-color: @palette-color-fill-turquoise;
    .bodyBase2Bold();
    color: @palette-color-neutrals-white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}
