@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';
@import '~@palette/styles/palette-theme.less';

.wrapper {
  position: relative;
  padding: 0 4rem 4rem;
  max-width: 100rem;
  width: 100rem;

  .usersSelectorWrapper {
    display: flex;
    flex-direction: row;

    .mergeUserSelector {
      flex: 3;
    }

    .divider {
      flex: 1;

      border-radius: @border-radius-base;

      display: flex;
      justify-content: center;

      .dividerIcon {
        .colorSvg(@palette-color-neutrals-grey2);
      }
    }
  }

  .results {
    margin-top: 4rem;
  }
}
