@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';
@import '~@palette/styles/palette-theme.less';

.wrapper {
  :global(.ant-modal) {
    min-width: 100vw;
  }

  @media(min-width: @screen-xs-min) {
    :global(.ant-modal) {
      min-width: 48rem;
    }
  }

  :global(.ant-modal-content) {
    border-radius: 2.4rem;
    background-color: @palette-color-neutrals-white;
    padding: 4rem;

    overflow: hidden;

    display: flex;
    flex-direction: column;

    :global(.ant-modal-close) {
      right: 5.5rem;
      top: 5rem;

      :global(.ant-modal-close-x) {
        width: 2.8rem;
        height: 2.8rem;
        line-height: 2.8rem;
      }

      :global(.anticon) {
        .colorSvg(@palette-color-neutrals-grey1);
      }

      &:hover {
        :global(.anticon) {
          .colorSvg(@palette-color-neutrals-grey3);
        }
      }
    }

    :global(.ant-modal-header) {
      flex: 0 0 auto;

      padding: 0 5rem 3.2rem 0;
      border: 0;
      background-color: inherit;

      :global(.ant-modal-title) {
        .headlineH2-2();
        color: @palette-color-neutrals-dark;
      }
    }

    :global(.ant-modal-body) {
      display: grid;
      padding: 0;
      border: 0;
      background-color: inherit;

      flex: 1;
      overflow-y: auto;
    }

    :global(.ant-modal-footer) {
      flex: 0 0 auto;

      padding: 3.2rem 0 0 0;
      border: 0;
      background-color: inherit;

      .footer {
        display: flex;
        align-items: center;
        justify-content: space-between;


        &.singleBtn {
          justify-content: center;
        }
      }
    }
  }

  &.stretchHeight {
    :global(.ant-modal-content) {
      min-height: calc(100vh - 20rem);
    }
  }

  @media(min-height: @screen-lg-min) {
    &.blockHeight {
      overflow: hidden;

      :global(.ant-modal) {
        max-height: calc(100vh - 20rem);
      }

      :global(.ant-modal-content) {
        max-height: calc(100vh - 20rem);
      }
    }
  }
}
