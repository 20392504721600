.ReconciliationDetails {
  h1 {
    font-weight: bold;
  }

  form {
    max-width: 700px;
  }
}

.ReconciliationStatus {
  background: grey;
  height: 12px;
  border-radius: 100px;
  position: relative;
  left: 4px;
  top: 1px;
  width: 12px;
  display: inline-block;

  &.DONE {
    background: #00b700;
  }
  &.ERRORED {
    background: red;
  }
  &.QUEUED {
    background: dodgerblue;
  }
  &.IN_PROGRESS {
    background: orange;
  }
}
