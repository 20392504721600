.wrapper {
  width: calc(100% - 8rem);
  max-width: 56.4rem;
  margin-bottom: 1.6rem;
  opacity: 1;
  top: 7.2rem;
  animation: notif-anim-in 1.5s 1 ease-in-out;

  &.fadeOut {
    animation: notif-anim-out 1s 1 ease-in-out;
  }

  &.hidden {
    display: none;
  }

  .contentDesc {
    ul {
      padding: 0;
      margin: 0;
    }

    a {
      white-space: break-spaces;
    }
  }
}

@keyframes notif-anim-in {
  0% {
    opacity: 0;
    top: 5.2rem;
    padding-top: 0;
    padding-bottom: 0;
  }
  20% {
    opacity: 1;
    top: 7.2rem;
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
  }
}

@keyframes notif-anim-out {
  0% {
    opacity: 1;
    top: 7.2rem;
  }
  20% {
    opacity: 0;
    top: 5.2rem;
    padding-top: 0;
    padding-bottom: 0;
    height: 0;
    margin-bottom: -.2rem;
  }
  100% {
    opacity: 0;
    top: 5.2rem;
    padding-top: 0;
    padding-bottom: 0;
    height: 0;
    margin-bottom: -.2rem;
    display: none;
  }
}
