@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';
@import '~@palette/styles/mixins.less';

.wrapper {
  .widgetSizeSmall();

  .content {
    flex: 1;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;

    .amount {
      color: @palette-color-neutrals-dark;
      .headlineH3();
      margin-bottom: 2rem;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .button {
      white-space: pre-line;
      height: auto;
    }
  }
}
