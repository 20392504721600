@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  padding: 0.8rem 0;

  .header {
    display: flex;
    align-items: center;

    .avatarWrapper {
      width: 3rem;
      height: 3rem;
      position: relative;

      .commentTypeIconWrapper {
        width: 1.8rem;
        height: 1.8rem;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(30%, -30%);
      }
    }

    .title {
      margin-left: 1rem;

      .name {
        .bodyBase2Medium();
        color: @palette-color-neutrals-dark;
      }

      .action {
        .bodyBase2Medium();
        color: @palette-color-neutrals-dark;

        .amount {
          padding: 0.2rem;
          border-radius: 0.4rem;
          background-color: @palette-color-fill-white-grape;
        }

        .payments {
          padding: 0.2rem;
          border-radius: 0.4rem;
          background-color: @palette-color-fill-white-grape;

          .paymentsInfoIcon {
            margin-left: 0.2rem;
            vertical-align: sub;

            .colorSvg(@palette-color-neutrals-grey2);

            &:hover {
              .colorSvg(@palette-color-primary-blue);
            }
          }
        }

        .status {
          padding: 0.2rem;
          border-radius: 0.4rem;

          &.resolved {
            background-color: @palette-color-fill-white-grape;
            color: @palette-color-secondary-light-teal;
          }

          &.pending {
            background-color: @palette-color-fill-soft-vanilla;
            color: @palette-color-secondary-brown-yellow;
          }
        }
      }
    }
  }

  .content {
    margin-top: 1rem;

    .bodyBase2Medium();
    color: @palette-color-neutrals-dark;
  }

  .footer {
    margin-top: 1rem;

    .bodyBase2Medium();
    color: @palette-color-neutrals-grey2;
  }
}
