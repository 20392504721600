@import '~@palette/styles/colors.less';
@import '~@palette/styles/mixins.less';
@import '~@palette/styles/palette-theme.less';
@import '~@palette/styles/typographies.less';

.modal {
  :global(.ant-modal) {
    max-width: 60rem;
  }

  :global(.ant-modal-content) {
    :global(.ant-modal-body) {
      display: flex;
      flex-flow: column nowrap;
    }
  }

  .titleWrapper {
    .headlineH2-2();
    color: @palette-color-neutrals-dark;
  }

  .introduction {
    margin-bottom: 2rem;
    .bodyBase1Medium();
  }

  .searchFilter {
    margin-bottom: 1.5rem;

    .searchFilterInput {
      width: 100%;
    }
  }

  .resetBtn {
    margin: 0 0 1.5rem auto;
    padding: 0;
    height: auto;
  }

  .switchContainer {
    max-height: 30rem;
    overflow-y: auto;
    border-radius: @border-radius-base;
    border: .1rem solid @palette-color-border-normal;
    padding: 1.2rem 1.6rem;

    .switchWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 0 .5rem;

      &:not(:last-child) {
        margin-bottom: 1.8rem;
      }

      .labelContainer {
        flex: 1;
        display: flex;
        align-items: center;
        overflow: hidden;

        .icon {
          .colorSvg(@palette-color-neutrals-grey1);
        }
    
        .label {
          .inputLabel();
          color: @palette-color-neutrals-grey1;
          margin-left: .8rem;
          width: 100%;
          .ellipsis();
        }
      }
  
      .switch {
        margin-bottom: 0;
        margin-left: 1rem;
      }
    }
  }
}
