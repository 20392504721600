@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';
@import '~@palette/styles/mixins.less';
@import '~@palette/styles/palette-theme.less';

.popoverContentWrapper {
  .popoverContentTitle {
    .bodyBase1Bold();
    color: @palette-color-neutrals-dark;
  }

  .subTeamListWrapper {
    margin-top: 1rem;

    display: flex;
    flex-direction: column;

    .subTeamItem {
      .bodyBase2Medium();
      color: @palette-color-neutrals-dark;

      & + .subTeamItem {
        margin-top: 0.5rem;
      }
    }
  }
}

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 2rem 1.5rem;
  border: 0.1rem solid @palette-color-border-light;
  border-radius: 1.6rem;

  width: 49rem;

  .name {
    width: 20rem;

    .bodyBase1Bold();

    .ellipsis();
  }

  .subTeamsWrapper {
    margin-left: 1.5rem;
    max-width: 10rem;
    min-width: fit-content;

    .bodyBase2Medium();
    color: @palette-color-neutrals-dark;

    .subTeamsTag:hover {
      cursor: pointer;
    }
  }

  .members {
    margin-left: 1.5rem;
    width: 10rem;
  }

  .moreMenuWrapper {
    margin-left: 1rem;
    width: 2.4rem;

    &.moreMenuIsOpen {
      .moreMenu {
        display: block;
      }
    }
  }

  @media(min-width: @screen-lg-min) {
    .moreMenuWrapper {
      .moreMenu {
        display: none;
      }
    }

    &:hover {
      border-color: @palette-color-shades-navy-sky-blue;

      .moreMenuWrapper {
        .moreMenu {
          display: block;
        }
      }
    }
  }
}
