@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  width: 100%;
  height: 100%;

  .header {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 2rem;

    .title {
      .bodyBase1Bold();
      color: @palette-color-neutrals-dark;
      margin-right: 1rem;
    }
  
    .secondTitle {
      .bodyBase2Medium();
      color: @palette-color-primary-blue;
      padding: 0;
      height: auto;
    }
  }
}
