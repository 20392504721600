@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  .paymentLabel {
    .bodyBase1Medium();
    color: @palette-color-neutrals-grey1;
  }

  .editionWrapper {
    margin-top: 0.3rem;
    display: flex;
    flex-direction: row;
    align-items: center;

    .inputs {
      flex: 1;

      display: flex;
      flex-direction: row;

      .input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right-width: 0.1rem !important;
      }

      .inputWrapper {
        width: 100%;
      }

      .datePicker {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left-width: 0.1rem !important;
        min-width: 18rem;
        width: 18rem;

        input {
          text-align: center;
          color: @palette-color-neutrals-dark;
        }
      }
    }

    .delete {
      margin-left: 1rem;

      padding: 0;
      min-width: 2.4rem;
      width: 2.4rem;
      height: 2.4rem;
      border-radius: 50%;
      background-color: @palette-color-shades-navy-cloudy-blue;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: @palette-color-primary-blue;
      }
    }
  }

  &.isDisabled {
    .editionWrapper {
      .inputs {
        margin-right: 3.4rem;
      }
    }
  }
}
