@import '~@palette/styles/colors.less';

.wrapper {
  background-color: @palette-color-neutrals-grey2;

  &:global(.ant-switch-checked) {
    background-color: @palette-color-primary-blue;
  }

  &.primaryTeal {
    &:global(.ant-switch-checked) {
      background-color: @palette-color-primary-teal;
    }
  }
}
