@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  .bodyBase2Medium();
  color: @palette-color-neutrals-grey1;
  width: 100%;
  position: relative;

  cursor: pointer;

  &.locked {
    cursor: default;
    padding: 1.3rem 1.4rem;
  }

  &.toDelete {
    text-decoration: line-through;
    input {
      text-decoration: line-through;
    }
  }

  .lockedIndicator {
    display: none;
  }

  &:hover {
    .lockedIndicator {
      display: flex;
    }
  }
}