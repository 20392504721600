@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .content {
    .bodyBase2Medium();
    color: @palette-color-neutrals-grey1;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    @supports (-webkit-line-clamp: 2) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }

  &.isClickable {
    cursor: pointer;

    .content {
      .bodyBase2Bold();
      color: @palette-color-neutrals-dark;
    }

    .expandIcon {
      margin-left: 1rem;

      .colorSvg(@palette-color-neutrals-dark);
    }

    &:hover {
      .content {
        color: @palette-color-primary-blue;
      }
      .expandIcon {
        .colorSvg(@palette-color-primary-blue);
      }
    }
  }
}
