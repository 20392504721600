@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';
@import '~@palette/styles/mixins.less';

.wrapper {
  .headerRight {
    text-align: right;
  }

  .userProfile {
    min-height: 4rem;
    width: 100%;
    .ellipsis();

    .userProfileLink {
      max-width: 18rem;
    }
  }

  .commissions {
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
    justify-content: space-between;

    .amounts {
      display: flex;
      align-items: center;
      flex-flow: row nowrap;
      flex: 1;

      .statementAmount {
        .bodyBase2Medium();
        color: @palette-color-neutrals-dark;
      }
  
      .statementPaidAmount {
        margin-left: 2rem;
      }
    }

    .correctionsWrapper {
      min-width: 14rem;
    }
  }

  .approvalsWrapper {
    min-height: 4rem;
  }

  .actionsWrapper {
    min-height: 4rem;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-end;

    .actionButton {
      &:not(:first-child) {
        margin-left: .8rem;
      }
    }
  }
}
