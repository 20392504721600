@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  margin-bottom: 2rem;

  &:global(.ant-collapse-ghost) {
    :global(.ant-collapse-item) {
      :global(.ant-collapse-header) {
        display: flex;
        align-items: center;
        background-color: @palette-color-fill-grey;
        padding: 1rem;
        height: 4rem;
        border-radius: .6rem;
      }
    }
  }

  .connectorNameWrapper {
    width: 100%;
    .bodyBase1Bold();
    color: @palette-color-neutrals-dark;
  }

  .list {
    padding-left: 1.6rem;
    padding-right: 1rem;

    .listItem {
      border-radius: 0;
      padding: .5rem 0;

      &:not(:last-child) {
        border-bottom: .1rem solid @palette-color-border-normal;
      }

      &:hover {
        background-color: @palette-color-fill-grey;
      }
    }
  }
}
