@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  .description {
    margin-top: 4rem;
    .bodyBase1Medium();
    color: @palette-color-neutrals-grey1;

    &.bold {
      .headlineH3();
      color: @palette-color-neutrals-dark;
    }
  }

  &.compact {
    .description {
      margin-top: 0;
    }
  }
}
