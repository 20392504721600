@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  display: flex;
  flex-direction: column;

  .title {
    .headlineH2();
    color: @palette-color-neutrals-dark;
    margin-bottom: 3rem;
    padding: 0 1.2rem;
  }

  .summariesList {
    .columns {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      margin-bottom: 1.6rem;
      padding: 0 1.2rem;

      .column {
        color: @palette-color-neutrals-grey2;
        .bodyBase2Medium();
        flex: .8;
        text-align: right;

        &:not(:last-child) {
          margin-right: 1rem;
        }

        &:nth-child(1) {
          flex: 2;
          text-align: left;
        }

        &:nth-child(2) {
          flex: 1.5;
          text-align: left;
        }

        &:nth-child(3) {
          flex: 1;
          text-align: left;
        }
      }
    }

    .item {
      margin-bottom: .4rem;
    }
  }
}
