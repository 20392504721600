@import '~@palette/styles/palette-theme.less';

.wrapper {
  height: 100vh;
  max-width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin: auto;

  h1 {
    margin-top: 4rem;
  }

  h2 {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  .resetPasswordForm {
    margin-top: 3rem;
    margin-bottom: 1rem;

    width: 100%;
    max-width: 30.8rem;
  }

  @media(min-width: @screen-xl) {
    max-width: 110rem;
  }

  @media(min-width: @screen-xxl) {
    max-width: 140rem;
  }
}
