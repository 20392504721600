@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';
@import '~@palette/styles/palette-theme.less';
@import '~@palette/styles/mixins.less';

.wrapper {
  .collapseContainer {
    margin-bottom: 1.6rem;

    .title {
      width: 100%;
      color: @palette-color-neutrals-grey1;
      .bodyBase1Medium();
      .ellipsis();
    }

    &.selected {
      .title {
        color: @palette-color-primary-blue;
        .bodyBase1Bold();
      }
    }

    &:hover {
      .title {
        color: @palette-color-primary-blue;
      }
    }

    .resourceItem {
      padding: 1rem 2rem;
      border-radius: @border-radius-base;
      color: @palette-color-neutrals-grey2;
      .bodyBase2Medium();
      .ellipsis();

      &:not(:last-child) {
        margin-bottom: .6rem;
      }

      &.selected {
        color: @palette-color-primary-blue;
        background-color: @palette-color-fill-sky;
      }

      &:not(.selected) {
        &:hover {
          cursor: pointer;
          background-color: @palette-color-fill-sky;
        }
      }
    }

    .emptyResource {
      margin: 0;
    }
  }
}
