.wrapper {
  user-select: none;
  text-decoration: none;
  overflow: visible;
  width: 100%;
  max-width: 34rem;
  flex: 1;
  cursor: pointer;

  &.isSelected {
    cursor: default;
  }

  &:hover, &:focus {
    user-select: none;
    text-decoration: none;
  }
}
