@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  //
}

.autoCompleteDropdown {
  .mostUsedOption {
    display: flex;
    align-items: baseline;

    .mostUsedOptionDetails {
      display: flex;
      align-items: center;

      .mostUsedOptionLabel {
        margin-left: 0.5rem;

        .caption1();
        color: @palette-color-neutrals-grey2;
        text-transform: lowercase;
        font-style: italic;
      }

      .mostUsedOptionIcon {
        margin-left: 0.3rem;

        .colorSvg(@palette-color-neutrals-grey2);
      }
    }
  }
}

.reactAutoCompleteInputDropdown {
  border: 0.14rem solid @palette-color-border-light;
  box-shadow: @palette-shadow-1;
  border-radius: 1.2rem;
  padding: 1rem;

  li {
    background-color: transparent;

    margin: 0 0 0.3rem 0;
    &:last-child {
      margin-bottom: 0;
    }

    padding: 0.8rem 1.4rem;
    border-radius: @border-radius-base;

    .inputValue();
    color: @palette-color-neutrals-grey1;

    &.active {
      background-color: @palette-color-fill-sky;
      color: @palette-color-primary-blue;
    }
  }
}

.suggestionsWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  .suggestionBtn {
      margin-right: 0.5rem;
      margin-top: 0.5rem;
  }
}
