@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.modal {
  :global(.ant-modal) {
    max-width: 69rem;
  }
}

.content {
  display: block;
  height: 100%;
  overflow: hidden;

  .selectionPanelWrapper {
    margin-top: 2.4rem;

    height: calc(100% - 8rem);

    display: flex;
    justify-content: center;
  }
}
