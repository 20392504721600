@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  border-radius: 1.4rem;
  border-width: 0;
  padding: 1.6rem 2rem;
  align-items: flex-start;
  .bodyBase2Medium();
  box-shadow: 0 4px 14px 2px rgba(28, 60, 78, 0.04);
  width: 100%;
  border: .1rem solid @palette-color-secondary-light-blue;
  color: @palette-color-neutrals-dark;

  &.closable {
    padding-right: 3.6rem;
  }

  .crossIcon {
    position: absolute;
    right: 1.6rem;
    .colorSvg(@palette-color-neutrals-grey2);
  }

  a {
    text-decoration: underline;
  }

  &:global(.ant-alert-with-description) {
    :global(.ant-alert-message) {
      .bodyBase2Bold();
      margin-bottom: .4rem;
    }

    :global(.ant-alert-description) {
      color: @palette-color-neutrals-dark;
    }

    :global(.ant-alert-icon) {
      margin-right: .8rem;
      font-size: inherit;
    }

    &:global(.ant-alert-no-icon) {
      padding: 1.6rem 2rem;
    }
  }

  &:global(.ant-alert-success) {
    background-color: @palette-color-fill-white-grape;
    border-color: @palette-color-border-secondary-success;

    &:global(.ant-alert-with-description) :global(.ant-alert-message) {
      color: @palette-color-primary-teal;
    }

    a {
      color: @palette-color-primary-teal;
    }

    > :global(.anticon) {
      .colorSvg(@palette-color-primary-teal);
    }
  }

  &:global(.ant-alert-info) {
    background-color: @palette-color-fill-light-blue;
    border-color: @palette-color-border-secondary-info;

    &:global(.ant-alert-with-description) :global(.ant-alert-message) {
      color: @palette-color-primary-blue;
    }

    a {
      color: @palette-color-primary-blue;
    }

    > :global(.anticon) {
      .colorSvg(@palette-color-primary-blue);
    }
  }

  &:global(.ant-alert-warning) {
    background-color: @palette-color-fill-soft-vanilla;
    border-color: @palette-color-border-secondary-warning;

    &:global(.ant-alert-with-description) :global(.ant-alert-message) {
      color: @palette-color-secondary-orange;
    }

    a {
      color: @palette-color-secondary-orange;
    }

    > :global(.anticon) {
      .colorSvg(@palette-color-secondary-orange);
    }
  }

  &:global(.ant-alert-error) {
    background-color: @palette-color-fill-secondary-red;
    border-color: @palette-color-border-secondary-error;

    &:global(.ant-alert-with-description) :global(.ant-alert-message) {
      color: @palette-color-secondary-red;
    }

    a {
      color: @palette-color-secondary-red;
    }

    > :global(.anticon) {
      .colorSvg(@palette-color-secondary-red);
    }
  }
}
