@import '~@palette/styles/colors.less';

.wrapper {
  width: 100%;
  height: 100%;

  .content {
    justify-content: center;

    & > div:first-child {
      margin-top: 0;
    }
  }

  .viewLink {
    margin-top: 2rem;

    .icon {
      margin-right: 0.5rem;
      .colorSvg(@palette-color-primary-blue);
    }
  }
}
