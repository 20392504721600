@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  display: flex;
  flex-direction: column;

  .header {
    .title {
      .title2Bold();
      color: @palette-color-neutrals-dark;
    }

    .subtitle {
      margin-top: 0.8rem;

      height: 2rem;

      .bodyBase1Medium();
      color: @palette-color-neutrals-grey2;
    }
  }

  .search {
    margin-top: 0.8rem;
  }

  .list {
    flex: 1;

    margin-top: 0.8rem;
    padding: 0.8rem;
    border-radius: 0.8rem;
    border: 0.1rem solid @palette-color-fill-table-hover;
    overflow: auto;

    .item + .item {
      margin-top: 0.8rem;
    }
  }
}
