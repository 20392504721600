@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';
@import '~@palette/styles/palette-theme.less';

.wrapper {
  max-width: 90rem;
  padding: 3rem 0 6rem;
  margin: 0 auto;

  .currencyTimezoneWrapper {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;

    .rowFieldItem {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      margin-bottom: 2.8rem;

      &:first-child {
        margin-right: 4rem;
      }

      .rowFieldSelect {
        margin-bottom: 0;
        width: 25rem;
      }

      .addCurrencyLink {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 4rem;
        padding: 1rem 0;

        .addCurrencyLinkIcon {
          .colorSvg(@palette-color-primary-blue);
          margin-right: 0.5rem;
        }
      }
    }
  }

  .datePicker {
    width: 25rem;
  }

  .liveWrapper {
    margin-bottom: 2.8rem;

    .liveSwitchWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;

      .liveSwitch {
        margin-right: 0.7rem;
        margin-bottom: 0;
      }

      .liveLabel {
        .inputLabel();
        color: @palette-color-neutrals-dark;
      }
    }

    .liveDescription {
      margin-top: 1.1rem;

      .inputLabel();
      color: @palette-color-neutrals-grey2;
    }
  }

  .freezeSettingsWrapper {
    margin-top: 3.2rem;
    padding-top: 3.2rem;
    border-top: 0.1rem solid @palette-color-border-normal;

    .freezeSettingsTitle {
      .title1Bold();
      color: @palette-color-neutrals-dark;
      margin-bottom: 1.2rem;
    }

    .freezeSettingsSubTitle {
      .title2Medium();
      color: @palette-color-neutrals-dark;
    }

    .autoFreezeSwitchWrapper {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      margin: 1rem 0 2rem;

      .autoFreezeSwitch {
        margin-right: 0.7rem;
        margin-bottom: 0;
      }

      .autoFreezeLabel {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        .inputLabel();
        color: @palette-color-neutrals-dark;

        .autoFreezeFormItem {
          margin: 0 1rem;
        }

        .autoFreezeFormInput {
          max-width: 8rem;
        }
      }
    }

    .freezeSettingsDescription {
      margin: 1.2rem 0 2.4rem 0;

      .inputLabel();
      color: @palette-color-neutrals-dark;
    }

    .freezeSettingsLabel {
      display: flex;
      flex-direction: row;
      align-items: center;

      .freezeSettingsTooltipIcon {
        margin-left: 0.2rem;
        .colorSvg(@palette-color-neutrals-grey2);
      }
    }
  }

  .payoutsPercentageSettingsWrapper {
    margin-top: 3.2rem;
    padding-top: 3.2rem;
    border-top: 0.1rem solid @palette-color-border-normal;

    .payoutsPercentageSettingsTitle {
      .title1Bold();
      color: @palette-color-neutrals-dark;
      margin-bottom: 1.2rem;
    }

    .allowWrongPayoutsPercentageSwitchWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;

      .allowWrongPayoutsPercentageSwitch {
        margin-right: 0.7rem;
        margin-bottom: 0;
      }

      .allowWrongPayoutsPercentageLabel {
        .inputLabel();
        color: @palette-color-neutrals-dark;
      }
    }
  }

  .historySettingsWrapper {
    margin-top: 3.2rem;
    padding: 3.2rem 0;
    border-top: 0.1rem solid @palette-color-border-normal;

    .historySettingsTitle {
      .title1Bold();
      color: @palette-color-neutrals-dark;
      margin-bottom: 1.2rem;
    }

    .historyDescriptionContainer {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;

      .historySettingsDescription {
        .inputLabel();
        color: @palette-color-neutrals-dark;
      }

      .historySettingsActions {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        .viewPlan {
          margin-left: 1.5rem;
          display: flex;
          flex-flow: row nowrap;
          align-items: center;

          .viewPlanIcon {
            .colorSvg(@palette-color-primary-blue);
            margin-right: 0.5rem;
          }
        }

        .deleteLinkBtn {
          margin-left: 1.5rem;
          padding: 0;
          height: auto;
        }
      }
    }

    .historyDefineBtn {
      padding-bottom: 0;
      height: auto;
    }

    .historyFormItem {
      margin-top: 1rem;
      margin-bottom: 0;

      &.hidden {
        display: none;
      }
    }
  }

  .quotaWarning {
    margin-bottom: 2.8rem;
  }

  .dependenciesWarning {
    margin-bottom: 2.8rem;
  }

  .actions {
    display: flex;
    flex-direction: column;
    align-items: center;

    .submitBtn {
      width: 100%;
      justify-content: center;
    }

    .moreActions {
      border-top: .1rem solid @palette-color-border-normal;
      margin-top: 3rem;
      padding-top: 3rem;
      width: 100%;
      display: flex;
      flex-wrap: nowrap;
      align-items: flex-start;
      flex-direction: column;

      @media(min-width: @screen-md-min) {
        flex-direction: row;
      }

      .actionItem {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex: 1;

        &.isNotAlone {
          margin-top: 2rem;

          @media(min-width: @screen-md-min) {
            border-left: .1rem solid @palette-color-border-normal;
            padding-left: 2rem;
            margin-left: 2rem;
            margin-top: 0;
          }
        }

        .title {
          color: @palette-color-neutrals-dark;
          .bodyBase2Bold();
          margin-bottom: .8rem;
        }

        .content {
          display: flex;
          flex-flow: row nowrap;
          align-items: flex-start;
          justify-content: space-between;

          .description {
            color: @palette-color-neutrals-grey1;
            .bodyBase2Medium();
          }

          .actionItemBtn {
            margin-left: 2rem;
            padding: .8rem 1rem;
            height: auto;
          }
        }
      }
    }
  }
}
