@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  padding-top: 2rem;

  .detailsActionsWrapper {
    .actionsWrapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;

      .tag{
        margin-left: 0.4rem;
      }

      .cellName {
        //
      }

      .cellOutputUniqueIdentifier {
        // 
      }

      .cellActions {
        margin-left: auto;

        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        .action {
          margin-left: 0.8rem;
        }
      }
    }

    .requiredFieldMissing{
      margin-bottom: 2.4rem;
    }

    .cellContentWrapper {
      position: relative;
      margin-top: 1rem;

      .exportedOverlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 100;

        background-color: fade(@palette-color-fill-white-grape, 80);

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .exportedOverlayDescription {
          max-width: 21rem;

          .caption1Bold();
          text-align: center;
          color: @palette-color-primary-teal;
        }

        .exportedOverlayBtn {
          margin-top: 1.2rem;
          background-color: transparent !important;
          padding: 1.25rem;
        }
      }
    }

    .outputNameWrapper {
      display: flex;
      align-items: center;
      margin-left: 2.4rem;

      .outputNameIcon {
        .colorSvg(@palette-color-neutrals-grey2);
      }

      .outputName {
        margin-left: 0.4rem;
        transform: translateY(0.6rem);
      }
    }
  }

  .preview {
    margin-top: 2.8rem;
  }

  .error {
    margin-top: 2.8rem;
  }
}