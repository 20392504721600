@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';
@import '~@palette/styles/palette-theme.less';
@import '~@palette/styles/mixins.less';

.wrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  background-color: @palette-color-fill-antiflash-white;
  border-radius: @border-radius-base;
  padding: 1.5rem;
  margin-bottom: 1rem;

  .fields {
    flex: 1;

    .rowField {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 1rem;
      }

      .prefix {
        margin-right: 1rem;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        width: 100%;
        max-width: 12rem;
        text-align: right;
        justify-content: flex-end;
        word-break: break-all;
        .code();

        .variableNameContainer {
          display: flex;
          flex-flow: row nowrap;

          .prefixStrong {
            margin-left: 0.5rem;
            max-width: 10.8rem;
            .ellipsis();
          }
        }
      }

      .formulaInput {
        background-color: @palette-color-neutrals-white;
      }
    }
  }

  .actions {
    margin-left: 1rem;

    .actionButton {
      width: auto;
      margin: 0;

      .actionIcon {
        .colorSvg(@palette-color-secondary-red);
      }
    }
  }
}