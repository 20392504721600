@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  .collapseContainer {
    margin-bottom: 2rem;

    .title {
      .bodyBase2Bold();
      color: @palette-color-neutrals-dark;
    }

    .extraDetails {
      margin-left: 1rem;
      width: 100%;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;

      .colRow {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        .tags {
          margin-left: .5rem;
        }

        .comments {
          margin-left: 2rem;
          .bodyBase2Medium();
        }

        .amount {
          margin-left: 3rem;
          .bodyBase1Bold();
        }
      }
    }

    .period {
      display: flex;
      flex-flow: column nowrap;
      padding: 1rem 0;

      &:not(:last-child) {
        margin-bottom: 2rem;
      }

      .periodNameContainer {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        margin-bottom: 2rem;

        .periodName {
          .bodyBase2Bold();
          margin-right: 1.5rem;
        }
      }

      .commissions {
        display: flex;
        flex-flow: column nowrap;

        .commission {
          display: flex;
          flex-flow: row wrap;
          align-items: center;
          justify-content: space-between;
          
          &:not(:last-child) {
            margin-bottom: 1rem;
          }

          .amoutValues {
            flex: 1;
            display: flex;
            justify-content: flex-start;
            margin-right: 2rem;

            .commissionValue {
              flex-direction: row-reverse;
              background-color: @palette-color-fill-antiflash-white;
              padding: 0 .5rem 0 1.5rem;
              border-radius: .8rem;
              height: 4rem;
            }
          }

          .links {
            display: flex;
            align-items: center;
            flex: 1;
            justify-content: space-between;

            .user {
              flex: 0.8;
            }

            .detailItem {
              width: auto;
              min-width: 6rem;
              margin-left: 2rem;
              display: flex;
              justify-content: flex-end;

              &.rules {
                min-width: 8rem;
                cursor: pointer;
                color: @palette-color-primary-blue;
                .bodyBase1Bold();
                display: flex;
                align-items: center;
                
                .icon {
                  margin-right: .5rem;
                  .colorSvg(@palette-color-primary-blue);
                }
              }
            }
          }
        }
      }
    }
  }
}