@import '~@palette/styles/colors.less';

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;

  .icon {
    .colorSvg(@palette-color-neutrals-grey1);
  }

  .select {
    margin-left: 0.5rem;
  }

  &:hover {
    .icon {
      .colorSvg(@palette-color-primary-blue);
    }
  }
}
