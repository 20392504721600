@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';
@import '~@palette/styles/mixins.less';

.wrapper {
  padding: 2rem 1.5rem;
  border: 0.1rem solid @palette-color-border-light;
  border-radius: 1.6rem;

  width: 50rem;

  .linkActionsWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .linkWrapper {
      display: flex;
      align-items: center;

      .type {
        margin-left: 1rem;
      }

      .link {
        margin-left: 1rem;
      }
    }

    .unlinkResourceBtn {
      height: 1.6rem;
      width: 1.6rem;
      background-color: transparent;
      padding: 0;
      border-radius: 0;

      cursor: pointer;

      .unlinkResourceBtnIcon {
        .colorSvg(@palette-color-neutrals-grey2);
      }

      &:hover {
        .unlinkResourceBtnIcon {
          .colorSvg(@palette-color-secondary-red);
        }
      }
    }
  }

  .detailsCollapse {
    margin-top: 1rem;

    :global(.ant-collapse-item) :global(.ant-collapse-header) {
      justify-content: center;
    }
  }

  .detailsList {
    .details {
      margin-top: 1rem;

      display: flex;
      align-items: center;

      .detailsLabel {
        .bodyBase2Bold();
        color: @palette-color-neutrals-dark;
        min-width: 15rem;
      }

      .detailsValue {
        .bodyBase2Medium();
        color: @palette-color-neutrals-grey1;
      }
    }
  }

  &:hover {
    border-color: @palette-color-shades-navy-sky-blue;
  }
}
