.Page_top_title_wrapper{
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
   
    align-items: center;
  .Page_top_title{
    flex-grow: 1;
  }
}
