@import '~@palette/styles/colors.less';
@import '~@palette/styles/mixins.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  padding: 2rem 2.4rem;
  border-radius: 1.8rem;
  border: .1rem solid @palette-color-border-normal;
  box-shadow: @palette-shadow-1;

  &:hover, &:focus {
    border-color: @palette-color-shades-navy-cloudy-blue;
  }

  .date {
    max-width: 100%;
    margin-bottom: .4rem;
    color: @palette-color-neutrals-grey1;
    .caption1();
    .ellipsis();
  }

  .amount {
    width: 100%;
    color: @palette-color-neutrals-dark;
    .title1Bold();
    .ellipsis();
  }

  .status {
    margin-top: 1.2rem;
    max-width: 100%;
  }

  &.isSelected {
    border-color: @palette-color-primary-blue;
    cursor: default;

    &:hover, &:focus {
      border-color: @palette-color-primary-blue;
    }

    .date {
      color: @palette-color-primary-blue;
      .caption1Bold();
    }
  }
}
