@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  display: flex;
  flex-direction: row;
  height: calc(100% - 10.2rem);

  .leftSide {
    width: 30rem;
    border-right: 0.1rem solid @palette-color-border-light;
    display: flex;
    flex-direction: column;

    .header {
      padding: 2.4rem 2.4rem 2.4rem 4rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      border-bottom: 0.1rem solid @palette-color-border-light;

      .title {
        color: @palette-color-neutrals-dark;
      }
    }

    .steps {
      padding: 2.4rem 4rem 2rem;
      overflow-y: auto;
    }
  }

  .main {
    flex: 1;
  }

  @media(min-width: @screen-xl-min) {
    max-width: 100%;
    overflow: hidden;

    .main {
      max-width: 100%;
      overflow: auto;
    }
  }
}
