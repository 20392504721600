@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  position: relative;
  padding: 0 4rem 4rem;

  .tabs {
    :global(.ant-tabs-nav) {
      margin-bottom: 1.6rem;
    }
  }
}
