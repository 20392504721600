@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  height: 100%;
  width: 100%;

  .header {
    width: 100%;
    padding: .8rem 0 0;

    .title {
      margin-bottom: 1.8rem;
      color: @palette-color-neutrals-grey3;
      .headlineH3();
    }

    .tabs {
      width: 100%;
      margin: 0 auto;
  
      :global(.ant-tabs-nav) {
        margin-bottom: 0;
      }
    }
  }
}
