@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  .code();
  color: @palette-color-shades-primary-blue-hover;

  &.boolean {
    color: @palette-color-secondary-light-blue;
  }

  &.number {
    color: @palette-color-secondary-orange;
  }

  &.float {
    color: @palette-color-primary-teal;
  }

  &.date {
    color: @palette-color-secondary-brown-yellow;
  }
}
