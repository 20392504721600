@import '~@palette/styles/colors.less';

.wrapper {
  width: 1.8rem;
  height: 1.8rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-radius: 50%;
  background-color: @palette-color-fill-sky;

  .icon {
    .colorSvg(@palette-color-primary-blue);
  }

  &.warning {
    background-color: @palette-color-fill-soft-vanilla;

    .icon {
      .colorSvg(@palette-color-secondary-orange);
    }
  }
}
