@import '~@palette/styles/colors.less';
@import '~@palette/styles/mixins.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  padding-bottom: 2.6rem;
  margin-bottom: 3.2rem;
  border-bottom: .1rem solid @palette-color-border-light;

  &:first-child {
    margin-top: 1.2rem;
  }

  .title {
    .title2Bold();
    color: @palette-color-neutrals-dark;
    display: flex;
    align-items: center;
    margin-bottom: 1.6rem;

    .titleIcon {
      margin-right: .8rem;
      .colorSvg(@palette-color-neutrals-grey1);
    }
  }

  .variable {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: .8rem;
    padding-left: 2.6rem;

    .name {
      color: @palette-color-neutrals-grey1;
      .code();
      .ellipsis();
    }

    .copy {
      margin-left: .8rem;
      padding: 0;
      height: auto;
      justify-content: center;
    }
  }
}
