@import '~@palette/styles/typographies.less';
@import '~@palette/styles/colors.less';

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  .field {
    .bodyBase2Bold();
    color: @palette-color-neutrals-dark;
  }

  .operator {
    margin: 0 0.3rem;

    .bodyBase2Bold();
    color: @palette-color-primary-teal;
  }

  .code {
    .bodyBase2Medium();
    color: @palette-color-neutrals-grey1;
  }
}
