@import '~@palette/styles/typographies.less';
@import '~@palette/styles/colors.less';
@import '~@palette/styles/palette-theme.less';

.wrapper {
  &.bordered {
    padding: .5rem 2rem;
    border-radius: @border-radius-base;
    border: .1rem solid @palette-color-border-normal;
    background-color: @palette-color-fill-table-group;
  }

  span:first-of-type {
    .bodyBase1Medium();
  }
}
