.wrapper {
  height: 100%;
  width: 100%;
  padding-top: 4rem;

  .breadcrumb {
    margin-left: 4rem;
  }

  .actions {
    margin-left: auto;
  }
}
