.wrapper {
  height: 100%;
  width: 100%;
  padding-top: 4rem;
  overflow: hidden;

  // Specific to Left Main Content Layout Page
  max-width: 100% !important;
  min-width: 110rem;

  .breadcrumb {
    max-width: 140rem;
    margin: 0 auto;
    padding: 0 2.4rem;
  }

  .content {
    margin-top: 2.4rem;
  }
}
