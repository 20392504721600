@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  color: @palette-color-neutrals-dark;

  &:hover {
    cursor: pointer;
    background-color: @palette-color-fill-sky;
  }

  &.disabled {
    cursor: default;
    background-color: transparent;
  }
}

.input {
  width: auto;
  .headlineH1();
  color: @palette-color-neutrals-dark;
  height: 5.5rem;
  margin-top: -0.75rem;
  margin-bottom: -0.75rem;
}
