.wrapper {
  display: block;
  height: 100%;
  overflow: hidden;
  position: relative;

  .noDataAlert {
    position: absolute;
    top: 8rem;
    z-index: 200;
    left: 0;
    right: 0;
  }

  .noDataDescription {
    display: flex;
    align-items: center;

    .noDataDescriptionContent {
      margin-right: 1rem;
    }
  }
}