@import '~@palette/styles/palette-theme.less';

.layout {
  min-height: 100vh;
  max-width: 100vw;

  .content {
    overflow: auto;

    > div {
      margin: 0 auto;
      max-width: 100%;

      @media(min-width: @screen-xl) {
        max-width: 110rem;
      }

      @media(min-width: @screen-xxl) {
        max-width: 140rem;
      }

      &:not(:global(.div-wrapper)) {
        @media (min-width: @screen-lg) {
          min-width: 110rem;
        }
      }

      &:global(.div-wrapper) {
        margin: initial;
        max-width: initial !important;
      }
    }
  }
}
