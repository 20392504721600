@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';
@import '~@palette/styles/palette-theme.less';
@import '~@palette/styles/mixins.less';

.wrapper {
  display: flex;
  flex-direction: column;
  max-height: 34rem;
  overflow-y: auto;

  .connectorCardWrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin: .4rem 0;
    padding: .8rem;
    border: .1rem solid @palette-color-border-normal;
    border-radius: @border-radius-base;
    cursor: pointer;

    .iconWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      border: .1rem solid @palette-color-border-normal;
      border-radius: .8rem;
      width: 3.4rem;
      height: 3.4rem;
      padding: .6rem;
      margin-right: .8rem;
      font-size: 2rem;

      .iconWrapperIcon {
        .colorSvg(@palette-color-neutrals-grey2);
      }
    }

    .label {
      .bodyBase2Bold();
      color: @palette-color-neutrals-grey3;
      margin-right: .8rem;
      flex: 1;
      .ellipsis();
    }

    .checkedIcon {
      .colorSvg(@palette-color-primary-blue);
    }

    &:hover {
      padding: .7rem;
      border: .2rem solid @palette-color-shades-navy-cloudy-blue;
    }

    &.selected {
      padding: .7rem;
      border: .2rem solid @palette-color-primary-blue;
    }

    &.disabled {
      cursor: not-allowed;
    }
  }
}

:global(.ant-form-item-has-error) {
  .wrapper {
    .connectorCardWrapper {
      border-color: @palette-color-border-input-error;

      .checkedIcon {
        .colorSvg(@palette-color-border-input-error);
      }
    }
  }
}
