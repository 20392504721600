@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';
@import '~@palette/styles/palette-theme.less';

.wrapper {
  width: 43rem;
  height: 51rem;
  overflow: hidden;
  max-width: 100%;

  .header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .title {
      .headlineH2();
      color: @palette-color-neutrals-dark;
    }

    .statusSelector {
      display: flex;

      :global(.ant-btn) {
        .bodyBase1Bold();
        margin-right: 0.6rem;

        &:global(.ant-btn-primary.ant-btn-round) {
          background-color: @palette-color-primary-blue;
          color: @palette-color-neutrals-white;
          border: none;
          border-radius: @border-radius-base;
        }

        &:global(.ant-btn-link) {
          color: @palette-color-neutrals-grey1;

          &:hover {
            background-color: @palette-color-fill-sky;
          }
        }
      }
    }
  }

  .content {
    margin-top: 2rem;
    overflow: auto;
    max-height: calc(100% - 4rem);

    .thread {
      margin-top: 1.4rem;
    }
  }
}
