@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 1.3rem 1.2rem 1.3rem 1.4rem;
  width: 100%;
  height: 100%;
  background-color: @palette-color-fill-grey;
  cursor: pointer;

  .label {
    .bodyBase2Medium();
    color: @palette-color-neutrals-grey1;
  }

  &:hover {
    .label {
      color: @palette-color-primary-blue;
    }
  }
}
