@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.modal {
  :global(.ant-modal) {
    max-width: 60rem;
  }

  .titleWrapper {
    .headlineH2-2();
    color: @palette-color-neutrals-dark;
  }

  .dateField {
    width: 100%;
  }

  .switchContainer {
    margin-bottom: 3rem;

    .switchWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 .5rem;
      margin-bottom: .5rem;
  
      .switch {
        margin-right: 0.7rem;
        margin-bottom: 0;
      }
  
      .switchLabel {
        .inputLabel();
        color: @palette-color-neutrals-dark;
      }
    }
  }
}
