@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  .inputValue();
  color: @palette-color-neutrals-dark;

  &:global(:not(.ant-select-customize-input)) {
    :global(.ant-select-selector) {
      height: 4rem;
      padding: 0.8rem 1.6rem;
      border: 0.2rem solid @palette-color-border-normal;
      border-radius: 1.2rem;
    }
  }

  :global(.ant-select-selector) {
    height: 4rem;
    padding: 0.8rem 1.6rem;
    border: 0.2rem solid @palette-color-border-normal;
    border-radius: 1.2rem;

    :global(.ant-select-selection-placeholder) {
      .inputValue();
      color: @palette-color-neutrals-grey2;
      left: 1.6rem;
      right: 1.6rem;
    }

    :global(.ant-select-selection-search) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      left: 1.6rem;
    }
  }

  &.big {
    &:global(:not(.ant-select-customize-input)) {
      :global(.ant-select-selector) {
        height: 5rem;
        padding: 1.3rem 1.6rem;
      }
    }

    :global(.ant-select-selector) {
      height: 5rem;
      padding: 1.3rem 1.6rem;
    }
  }

  &:global(.ant-select-open) {
    :global(.ant-select-selector) {
      :global(.ant-select-selection-item) {
        color: @palette-color-neutrals-grey2;
      }
    }
  }

  &:global(:not(.ant-select-disabled)) {
    &:hover {
      :global(.ant-select-selector) {
        border-color: @palette-color-shades-navy-cloudy-blue;
        border-right-width: 0.2rem !important;
      }
    }

    &:focus {
      :global(.ant-select-selector) {
        border-color: @palette-color-primary-blue;
        border-right-width: 0.2rem !important;
        box-shadow: none;
      }
    }
  }

  &:global(.ant-select-focused:not(.ant-select-disabled):not(.ant-select-customize-input)) {
    :global(.ant-select-selector) {
      border-color: @palette-color-primary-blue;
      border-right-width: 0.2rem !important;
      box-shadow: none;
    }
  }
}

.dropdown {
  border: 0.14rem solid @palette-color-border-light;
  box-shadow: @palette-shadow-1;
  border-radius: 1.2rem;
  padding: 1rem;

  :global(.ant-select-item-option) {
    background-color: transparent;

    padding: 0 0 0.3rem 0;
    &:last-child {
      padding-bottom: 0;
    }

    :global(.ant-select-item-option-content) {
      padding: 0.8rem 1.4rem;
      border-radius: @border-radius-base;

      .inputValue();
      color: @palette-color-neutrals-grey1;
    }
  }

  :global(.ant-select-item-option-selected) {
    :global(.ant-select-item-option-content) {
      background-color: @palette-color-fill-sky;
      color: @palette-color-primary-blue;
    }

    :global(.ant-select-item-option-state) {
      :global(.anticon) {
        font-size: 1.6rem;
        color: @palette-color-primary-blue;
        .colorSvg(@palette-color-primary-blue);
      }
    }
  }

  :global(.ant-select-item-option-active) {
    :global(.ant-select-item-option-content) {
      background-color: @palette-color-fill-sky;
    }
  }
}
