@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  .collapse {
    :global(.ant-collapse-item) {
      :global(.ant-collapse-header) {
        padding: 1.2rem 2.4rem;
        border-radius: 1.6rem;

        .title {
          width: 100%;
          .bodyBase2Medium();
          color: @palette-color-neutrals-dark;
        }
      }

      :global(.ant-collapse-content) {
        margin: 1.2rem;
      }

      &:global(.ant-collapse-item-active) {
        :global(.ant-collapse-header) {
          border-radius: 1.6rem 1.6rem 0 0;
          background-color: @palette-color-fill-sky !important;

          .title {
            .bodyBase2Bold();
            color: @palette-color-primary-blue;
          }

          :global(.ant-collapse-arrow) {
            .colorSvg(@palette-color-primary-blue);
          }
        }
      }
    }
  }
}
