@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';
@import '~@palette/styles/mixins.less';

.wrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 1rem 0;

  &:not(.alignCenter) {
    align-items: flex-end;
  }

  &:not(:last-child) {
    border-bottom: .1rem solid @palette-color-border-light;
  }

  .infosWrapper {
    display: flex;
    flex-flow: column nowrap;
    margin-right: 2rem;
    flex: 1;

    .namePeriodWrapper {
      display: flex;
      flex-flow: row wrap;
      align-items: center;

      .nameScopeWrapper {
        margin-right: 2rem;
        margin-bottom: .6rem;

        .nameWrapper {
          .nameLabel {
            .bodyBase2Medium();
            color: @palette-color-neutrals-dark;

            &::after {
              content: ' ';
              width: 1.7rem;
              display: inline-flex;
            }
          }
        }
      }
    }

    .detailsContainer {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: space-between;

      .detailsWrapper {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        .frequency {
          margin-right: 1rem;
          .bodyBase2Medium();
          color: @palette-color-neutrals-grey1;
          white-space: normal;
        }

        .scope {
          margin-right: 1rem;
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: flex-end;
        }
      }
    }
  }

  .progressWrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-end;

    .periodLinkCommissionWrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin: 0 3rem 0 1rem;

      &.alignRight {
        margin-right: 0;

        .lastPeriodLink {
          margin-top: .6rem;
        }
      }

      .lastPeriodLink {
        display: flex;
        margin-bottom: .6rem;
      }

      .totalCommissionValue {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        .lastPeriodCommission {
          .bodyBase2Medium();
          color: @palette-color-neutrals-grey2;
          white-space: nowrap;
          margin-right: .4rem;
        }

        .lastPeriodCommissionValue {
          .bodyBase2Medium();
          color: @palette-color-neutrals-grey1;
        }
      }
    }

    .lastPeriodDetails {
      display: flex;
      flex-flow: column nowrap;
      width: 24.5rem;
      max-width: 24.5rem;
    }
  }
}
