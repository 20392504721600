@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  .filtersAction {
    position: absolute;
    top: .8rem;
    right: 2rem;
    color: @palette-color-neutrals-grey1;
    display: flex;
    align-items: center;
    .bodyBase2Medium();

    .iconFilter {
      margin-right: .2rem;
      .colorSvg(@palette-color-neutrals-grey1);
    }
    
    .iconCloseFilter {
      margin: .3rem .4rem .3rem 0;
      .colorSvg(@palette-color-neutrals-grey1);
    }

    &:hover {
      color: @palette-color-primary-blue;
      cursor: pointer;

      .iconFilter {
        .colorSvg(@palette-color-primary-blue);
      }

      .iconCloseFilter {
        .colorSvg(@palette-color-primary-blue);
      }
    }
  }
}
