.Feedback_rate_modal{
  position: fixed;
  z-index: 1100;
  width: 510px;
  max-width: ~"calc(100% - 28px)";
  bottom: 20px;
  right: 100px;
  background: white;
  border-radius: 16px;
  padding: 16px 24px;
  overflow: hidden;
  box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
  transition: all .3s;
}