@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.modal {
  :global(.ant-modal) {
    max-width: 40rem;
  }
}

.content {
  margin-bottom: 1rem;

  .bodyBase1Medium();
  color: @palette-color-neutrals-dark;
}
