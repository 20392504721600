@import '~@palette/styles/colors.less';

.wrapper {
  position: relative;
  height: 31.5rem;

  background-color: @palette-color-fill-cultured;
  border-radius: 1.6rem;

  padding: 2rem;

  display: flex;
  flex-direction: row;
  align-items: center;

  .loadingIcon {
    position: absolute;
    top: 1rem;
    left: 1rem;

    .colorSvg(@palette-color-secondary-light-blue)
  }

  .emptyState {
    margin: auto;
  }

  .widgets {
    height: 100%;
    min-width: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;

    overflow-y: hidden;
    overflow-x: auto;

    .widget + .widget {
      margin-left: 1.6rem;
    }
  }
}
