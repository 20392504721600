@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  .container {
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
    justify-content: space-between;
    min-height: 4rem;
    margin-top: 1rem;
  }

  .title {
    .headlineH2-2();
    color: @palette-color-neutrals-dark;
  }

  .subtitle {
    .bodyBase1Medium();
    color: @palette-color-neutrals-grey1;
  }

  .rollbackButton {
    .bodyBase1Medium();

    &:hover, &:active, &:focus {
      .bodyBase1Medium();
    }
  }
}