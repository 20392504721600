@import '~@palette/styles/colors.less';

.wrapper {
  .historyButton {
    height: auto;
    width: auto;
    padding: .4rem 0;
    margin-left: 1rem;

    .icon {
      .colorSvg(@palette-color-neutrals-grey2);
    }

    &:hover {
      .icon {
        .colorSvg(@palette-color-primary-blue);
      }
    }
  }
}
