@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  position: relative;
  min-width: 25rem;
  width: 25rem;
  height: 100%;
  padding: 1rem 2rem;
  border-radius: 2rem;
  background-color: @palette-color-neutrals-white;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  .titleSubtitleWrapper {
    min-height: 4rem;
    margin-bottom: 2rem;

    display: flex;
    flex-flow: column nowrap;
    align-items: center;

    .title {
      .bodyBase1Bold();
      color: @palette-color-neutrals-dark;
      text-align: center;
    }

    .subtitle {
      .caption1();
      color: @palette-color-neutrals-dark;
      text-align: center;

      margin-top: 0.4rem;
    }
  }

  .content {
    position: relative;
    flex: 1;

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
  }
}
