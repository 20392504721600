@import '~@palette/styles/colors.less';

.wrapper {
  display: flex;
  align-items: center;

  .freezeBtn {
    padding: 0;
    height: auto;
    width: auto;
    margin-right: 1rem;
  }

  .freezeBtnActiveIcon {
    margin-right: 1rem;
    cursor: not-allowed;

    .colorSvg(@palette-color-primary-blue);
  }
}
