@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  position: relative;
  padding: 0 4rem;
  margin-bottom: 4rem;

  .header {
    margin: 4rem 0;

    display: flex;
    flex-flow: column;

    .descriptionWrapper {
      margin-top: 1rem;
    }

    .description {
      .title2Bold();
      color: @palette-color-neutrals-dark;
    }
  }

  .content {
    .isProtectedAlert {
      margin-bottom: 4rem;
    }
  }
}
