@import '~@palette/styles/colors.less';

.wrapper {
  font-size: 2rem;
  line-height: 2rem;
  width: 2rem;
  height: 2rem;
  display: block;
  position: relative;

  :global(.ant-checkbox) {
    font-size: 2rem;
    line-height: 2rem;
    width: 2rem;
    height: 2rem;
    display: contents;

    :global(.ant-checkbox-inner) {
      border-radius: 0.3rem;
      border-color: @palette-color-border-normal;
      border-width: 0.2rem;
      width: 2rem;
      height: 2rem;
    }

    &:hover, &:focus {
      box-shadow: none;
      :global(.ant-checkbox-inner) {
        border-color: @palette-color-primary-blue;
      }
    }

    &:global(.ant-checkbox-checked::after) {
      border: 0;
    }

    &:global(.ant-checkbox-indeterminate) :global(.ant-checkbox-inner::after) {
      background-color: @palette-color-primary-blue;
      width: 1rem;
      height: 1rem;
    }

    &:global(.ant-checkbox-checked) :global(.ant-checkbox-inner) {
      background-color: @palette-color-primary-blue;
      border-color: @palette-color-primary-blue;
    }

    &:global(.ant-checkbox-disabled) :global(.ant-checkbox-inner) {
      background-color: @palette-color-neutrals-grey2 !important;
      border-color: @palette-color-neutrals-grey2 !important;
    }
  }
}
