@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  .title {
    .title2Bold();
    color: @palette-color-neutrals-grey3;
  }

  .periodResults {
    margin-top: 2rem;

    &:first-child {
      margin-top: 1.2rem;
    }

    &:nth-child(even) {
      :global(.ant-collapse-item) {
        :global(.ant-collapse-header) {
          background-color: @palette-color-fill-grey;
        }
      }
    }
  }
}
