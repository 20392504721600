@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  .contentWrapper {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;

    .avatarWrapper {
      width: 100%;
      max-width: 4.4rem;
      margin-bottom: 1.6rem;
    }

    .detailsWrapper {
      margin-left: 1.2rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .companyName {
        margin-bottom: .6rem;
        color: @palette-color-neutrals-dark;
        .bodyBase1Medium();
      }

      .currencyTag {
        margin-bottom: .6rem;
      }

      .seatsWrapper {
        display: flex;
        margin-top: .6rem;

        .icon {
          .colorSvg(@palette-color-secondary-light-blue);
        }

        .seatsTagWrapper {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;

          .seatsIcon {
            margin-left: .4rem;
            .colorSvg(@palette-color-secondary-light-blue);
          }
        }
      }
    }
  }

  .dumpDataWrapper {
    border-top: 0.1rem solid @palette-color-border-light;
    margin-top: 2rem;
    padding-top: 2rem;

    display: flex;
    flex-direction: column;

    .dumpDataTitle {
      .bodyBase2Bold();
      color: @palette-color-neutrals-dark;
    }

    .dumpDataDescription {
      margin-top: 1rem;

      .bodyBase2Medium();
      color: @palette-color-neutrals-dark;
    }

    .dumpDataActions {
      margin-top: 1.6rem;

      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 1rem;

      .dumpDataAction {
        justify-content: center;
      }
    }
  }
}
