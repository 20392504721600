@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: .7rem;

    .infos {
      flex: 1;
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-end;
      margin-right: 4rem;
      margin-bottom: 1rem;
      color: @palette-color-neutrals-grey3;
      .headlineH2-2();

      .icon {
        .colorSvg(@palette-color-neutrals-grey2);
      }

      .reportIcon {
        margin: 0 0 .5rem 1.5rem;
        .colorSvg(@palette-color-neutrals-grey1);
      }

      .clonedText {
        margin: 0 0 .6rem 1.5rem;

        .link {
          .icon {
            margin-right: .4rem;
            .colorSvg(@palette-color-primary-blue);
          }
        }
      }
    }

    .actions {
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-end;
      min-height: 4rem;
      padding-bottom: .6rem;

      .actionItem {
        &:not(:last-child) {
          margin-right: 1.5rem;
        }
      }
    }
  }

  .content {
    flex: 1;
  }
}
