@import '~antd/lib/style/themes/default.less';
@import '~antd/dist/antd.less';

@import '~react-resizable/css/styles.css';

@import '~@palette/styles/colors.less';
@import '~@palette/styles/fonts.less';
@import '~@palette/styles/typographies.less';
@import '~@palette/styles/palette-theme.less';

html {
  font-size: 62.5%; /* Now 10px = 1rem! */
}

body {
  font-size: @font-size-base; /* px fallback */
  font-size: @font-size-base-rem; /* default font-size for document */

  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
}

a {
  transition: none;
}

article, aside, details, figcaption, figure, footer, header, main, nav, section {
  display: block;
}

ol, ul, li {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, q:before,
blockquote:after, q:after {
  content: '';
  content: none;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

h1 {
  .headlineH1();
}

h2 {
  .headlineH2();
}

h3 {
  .headlineH3();
}

#root{
  min-height: 100%;
  height: 100%;
}

#App {
  min-height: 100%;
}

*::selection {
  color: @palette-color-neutrals-white;
  background-color: @palette-color-primary-blue;
}

/* CSS Class Helpers and Overrides (reviewed) */
.paletteNotification {
  .ant-message-notice-content {
    box-shadow: @palette-shadow-1;
    border: 0.1rem solid @palette-color-border-light;
    border-radius: 2rem;
    max-width: 60rem;
  }

  .ant-message-custom-content {
    display: flex;
    align-items: center;

    .bodyBase1Medium();
    color: @palette-color-neutrals-grey1;
  }
}

.Button_link_grey{
  color: @palette-color-neutrals-grey1;

  &:hover{
    color: @palette-color-primary-teal;
  }
}

.grey {
  color: @palette-color-neutrals-grey3;
}

a.Link_grey{
  color: @palette-color-neutrals-grey1;
  &:hover {
    color: @palette-color-primary-teal;
  }
}
a.Link_grey[disabled] {
  color: @palette-color-neutrals-grey2;
  &:hover {
    color: @palette-color-neutrals-grey2;
    cursor:not-allowed;
  }
}

/* CSS Class Helpers and Overrides (from former global.less file) */
.popins, .title {
  font-family: 'Montserrat', sans-serif;
}

.nanum {
  font-family: 'Nanum Gothic', sans-serif;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.bold {
  font-weight: bold;
}

.upper {
  text-transform: uppercase;
}

.light {
  font-weight: 100;
}

.italic {
  font-style: italic;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.ant-btn.ant-btn-background-ghost {
  &.grey, &.Rules_delete_button {
    color: #838383;
  }
}


.hoverUnderline{
  &:hover {
    text-decoration: underline;
  }
}
.lightgrey {
  color: #c7c7c7;
}
.center {
  text-align: center;
}
.container {
  margin-left: 12px;
  margin-right: 12px;
  position: relative;
  box-sizing: border-box;
}
.ant-table-small .ant-table-thead > tr > th.ant-table-cell{
  overflow-wrap: normal;
}
.ant-table.ant-table-small .ant-table-tbody > tr > td.ant-table-cell{
  min-width: 70px;
}
 .Black_alert{
  padding: 6px 6px 6px 24px;
  background: black;
  color: white;
  border-color: black;
  &.ant-alert-info .ant-alert-icon{
    color:white;
  }
  .ant-alert-content {
    align-self: center;

    .ant-alert-description{
      line-height: 14px;
    }
  }
}
.My_statement_top_title{
  padding-bottom: 30px;
  padding-top: 30px;
  // background: linear-gradient(150deg, #29c7cc 20%, #60aeec 57%, #2a95ea 92%);
  background: linear-gradient(225deg, #51b7bb 30%, #0a9ea2 74%, #8bccce 60%);
  position: relative;
  h1{
    color: white;
  }
}
.no-margin {
  margin: 0;
}
.normal{
  font-weight: 400;
}
.red {
  color: #fb0154;
}
.back-red {
  background-color: #fb0154;
}
.blue {
  color: #34f7f5;
}
.black {
  color: #1c1c1c !important;
}
.Plan_name_link{
  color: #1c1c1c;

  &:hover {
    color: #6dc2c2;
  }
}
#toast-container {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 999;
}
#toast-container .toast {
  padding: 10px 14px;
  margin: 4px;
  color: white;
}
#toast-container .toast-title {
  font-family: 'Montserrat', sans-serif;
}

#toast-container .toast-error {
  background: #f95352;
}
#toast-container .toast-success {
  background: #18ba9a;
}

#toast-container .toast-warning {
  background: rgb(228, 157, 54);
}
.div-wrapper {
  padding-top: 12px;
  padding-bottom: 80px;
}
.width_control {
  max-width: 400px;
  margin: auto;
}
.flex-reverse,
.flex {
  display: flex;
}
.flex-wrapper {
  display: flex;
  align-content: center;
  justify-content: center;
}
.Green_check{
  color:#00b700;
}
.blur {
  filter: blur(8px);
  user-select: none;
  &:hover{
    cursor: not-allowed;
  }
}
.disabled{
  cursor: not-allowed;
}
.No_select{
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.Bullet {
  width: 12px;
  height: 12px;
  border-radius: 100px;
  background: orange;
  display: inline-block;
  position: relative;
  top: -1px;
  margin-right: 6px;

  &.CONNECTED {
    background: #87d068;
  }
  &.DEACTIVATED {
    background: lightgrey;
  }
}
.Commissions_page_grey{
  box-shadow:0px 4px 7px 0px #dadada4a;
  margin-bottom: 20px;

  .Commissions_page_grey_inner{
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.input-large{
  font-size: 16px;
  height: 40px !important;
}
.ant-table-tbody > tr.blur:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td{
  background: transparent
}
.ant-popover {
  max-width: 100% !important;
}
.width-100 {
  width: 100%;
}
.flex-wrap{
  display: flex;
  flex-wrap: wrap;
}
.flex-center {
  align-items: center;
  justify-content: center;
}
.container-inner{
  min-height: 600px;
  width: 1060px;
  margin: auto;
  max-width: 100%;
}
button.ant-btn.Big {
  padding: 10px 22px !important;
  height: auto;
}
button.ant-btn.Button_disabled{
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.white{
  color: white;
}
.Title_nobottom{
  margin-bottom: 2px;
}
.Button_custom_icon {
  width: 24px;
  max-width: 24px;
  margin-right: 4px;
  filter: invert(1) grayscale(1);
}
.ant-alert-success.ant-alert-with-description .ant-alert-description,
.ant-alert-success.ant-alert-with-description .ant-alert-message {
  color: #43a015;
}
.Green_link {
  color: #43a015;
}
.Salesforce_button, .SALESFORCE_button{
  background: #25a1e0;
  border-color: #25a1e0;
  border-radius: 4px;
  &:hover, &:focus {
    background: #2090ca;
    border-color: #2090ca;
  }

  &.ant-tag {
    background: #25a1e042;
    border-color: transparent;
    color: #25a1e0;
    &:hover, &:focus {
      background: #25a1e042;
      border-color: transparent;
      color: #25a1e0;
    }
  }
}
.Hubspot_button, .HUBSPOT_button{
  background: #f1731f;
  border-color: #f1731f;
  color: white;
  border-radius: 4px;
  &:hover, &:focus {
    background: #d2641b;
    border-color: #d2641b;
  }

  &.ant-tag {
    background: #f1731f33;
    border-color: transparent;
    color: #f1731f;
    &:hover, &:focus {
      background: #f1731f33;
      border-color: transparent;
      color: #f1731f;
    }
  }
}
.Jobadder_button, .JOBADDER_button{
  background: #2772c2;
  border-color: #2772c2;
  color: white;
  border-radius: 4px;
  &:hover, &:focus {
    background: #2772c2;
    border-color: #2772c2;
  }

  &.ant-tag {
    background: #1f7af133;
    border-color: transparent;
    color: #2772c2;
    &:hover, &:focus {
      background: #1f7af133;
      border-color: transparent;
      color: #2772c2;
    }
  }
}
.CSV_button{
  &.ant-tag {
    background: #0000001a;
    border-color: transparent;
    color: black;
    &:hover, &:focus {
      background: #0000001a;
      border-color: transparent;
      color: black;
    }
  }
}
.PIPEDRIVE_button{
  &.ant-tag {
    background: white;
    border-color: transparent;
    color: black;
    &:hover, &:focus {
      background: white;
      border-color: transparent;
      color: black;
    }
  }
}
.Sellsy_button, .SELLSY_button {
  background: #009ae6;
  border-color: #009ae6;
  border-radius: 4px;
  &:hover, &:focus {
    background: #015daf;
    border-color: #015daf;
  }
  &.ant-tag {
    background: #009ae633;
    border-color: transparent;
    color: #009ae6;
    &:hover, &:focus {
      background: #009ae633;
      border-color: transparent;
      color: #009ae6;
    }
  }
}
.Quickbooks_button, .QUICKBOOKS_button, .Quickbooks_sandbox_button, .QUICKBOOKS_SANDBOX_button {
  background: #33a01b;
  border-color: #33a01b;
  border-radius: 4px;
  &:hover, &:focus {
    background: #1c6612;
    border-color: #1c6612;
  }
  &.ant-tag {
    background: #2ba01c33;
    border-color: transparent;
    color: #33a01b;
    &:hover, &:focus {
      background: #2ba01c33;
      border-color: transparent;
      color: #33a01b;
    }
  }
}
.Outreach_button, .OUTREACH_button {
  background: #5951ff;
  border-color: rgba(89, 81, 255, 0.5);
  border-radius: 4px;
  &:hover, &:focus {
    background: #3a35a2;
    border-color: rgba(89, 81, 255, 0.5);
  }
  &.ant-tag {
    background: #5951ff33;
    border-color: transparent;
    color: #5951ff;
    &:hover, &:focus {
      background: #5951ff33;
      border-color: transparent;
      color: #5951ff;
    }
  }
}
.Close_button, .CLOSE_button {
  background: #4364af;
  border-color: rgba(89, 81, 255, 0.5);
  border-radius: 4px;
  &:hover, &:focus {
    background: #2a3e6d;
    border-color: rgba(89, 81, 255, 0.5);
  }
  &.ant-tag {
    background: #4364af33;
    border-color: transparent;
    color: #4364af;
    &:hover, &:focus {
      background: #4364af33;
      border-color: transparent;
      color: #4364af;
    }
  }
}
.Pipedrive_button{
  background: #25282b;
  border-color: #25282b;
  color: white;
  border-radius: 4px;
  &:hover, &:focus {
    background: #131517;
    border-color: #131517;
  }
}
.Chargebee_button, .CHARGEBEE_button {
  background: #ff6a28;
  border-color: #ff6a28;
  border-radius: 4px;
  &:hover, &:focus {
    background: #ad441b;
    border-color: #ad441b;
  }
  &.ant-tag {
    background: #ff6a2833;
    border-color: transparent;
    color: #ff6a28;
    &:hover, &:focus {
      background: #ff6a2833;
      border-color: transparent;
      color: #ff6a28;
    }
  }
}
.Xero_button, .XERO_button {
  background: #13b4ea;
  border-color: #13b4ea;
  border-radius: 4px;
  &:hover, &:focus {
    background: #0c81a8;
    border-color: #0c81a8;
  }
  &.ant-tag {
    background: #13b4ea33;
    border-color: transparent;
    color: #13b4ea;
    &:hover, &:focus {
      background: #13b4ea33;
      border-color: transparent;
      color: #13b4ea;
    }
  }
}
.Stripe_button, .STRIPE_button {
  background: #635bff;
  border-color: #635bff;
  border-radius: 4px;
  &:hover, &:focus {
    background: #37338f;
    border-color: #37338f;
  }
  &.ant-tag {
    background: #635bff33;
    border-color: transparent;
    color: #635bff;
    &:hover, &:focus {
      background: #635bff33;
      border-color: transparent;
      color: #635bff;
    }
  }
}
.Gsheet_button, .GSHEET_button {
  background: #34a853;
  border-color: #34a853;
  border-radius: 4px;
  &:hover, &:focus {
    background: #1e6631;
    border-color: #1e6631;
  }
  &.ant-tag {
    background: #34a85333;
    border-color: transparent;
    color: #34a853;
    &:hover, &:focus {
      background: #34a85333;
      border-color: transparent;
      color: #34a853;
    }
  }
}
.Pennylane_button, .PENNYLANE_button {
  background: #4ac683;
  border-color: #4ac683;
  border-radius: 4px;
  &:hover, &:focus {
    background: #4ac683;
    border-color: #4ac683;
  }
  &.ant-tag {
    background: #4ac68333;
    border-color: transparent;
    color: #4ac683;
    &:hover, &:focus {
      background: #4ac68333;
      border-color: transparent;
      color: #4ac683;
    }
  }
}
.Crelate_button, .CRELATE_button {
  background: #338fec;
  border-color: #338fec;
  border-radius: 4px;
  &:hover, &:focus {
    background: #338fec;
    border-color: #338fec;
  }
  &.ant-tag {
    background: #4a96c633;
    border-color: transparent;
    color: #338fec;
    &:hover, &:focus {
      background: #4a96c633;
      border-color: transparent;
      color: #338fec;
    }
  }
}
.Snowflake_button, .SNOWFLAKE_button {
  background: #27AEDF;
  border-color: #27AEDF;
  border-radius: 4px;
  &:hover, &:focus {
    background: #27AEDF;
    border-color: #27AEDF;
  }
  &.ant-tag {
    background: #4a96c633;
    border-color: transparent;
    color: #27AEDF;
    &:hover, &:focus {
      background: #4a96c633;
      border-color: transparent;
      color: #27AEDF;
    }
  }
}
.Redshift_button, .REDSHIFT_button {
  background: #205c97;
  border-color: #205c97;
  border-radius: 4px;
  &:hover, &:focus {
    background: #205c97;
    border-color: #205c97;
  }
  &.ant-tag {
    background: #4a96c633;
    border-color: transparent;
    color: #205c97;
    &:hover, &:focus {
      background: #4a96c633;
      border-color: transparent;
      color: #205c97;
    }
  }
}
.SALESFORCE_back{
  background: #25a1e0 !important;
}
.HUBSPOT_back{
  background: #f1731f !important;
}
.JOBADDER_back{
  background: #2772c2 !important;
}
.PIPEDRIVE_back{
  background: #25282b !important;
}
.SELLSY_back{
  background: #009ae6 !important;
}
.QUICKBOOKS_back, .QUICKBOOKS_SANDBOX_back{
  background: #2ba01c !important;
}
.OUTREACH_back{
  background: #5951ff !important;
}
.CLOSE_back{
  background: #4364af !important;
}
.CHARGEBEE_back{
  background: #ff6a28 !important;
}
.XERO_back{
  background: #13b4ea !important;
}
.STRIPE_back{
  background: #635bff !important;
}
.GSHEET_back{
  background: #34a853 !important;
}
.PENNYLANE_back{
  background: #4ac683 !important;
}
.CRELATE_back{
  background: #338fec !important;
}
.SNOWFLAKE_back{
  background: #27AEDF !important;
}
.REDSHIFT_back{
  background: #205c97 !important;
}
.Green_input {
  &.ant-input-affix-wrapper {
    background: #edffff;
  }
  input{
    background: #edffff;
  }
}
.Page_top_title_wrapper{
  display: flex;

  h1{
    flex-grow: 1;
  }
}
.White_link{
  color: white;
  &:hover{
    color: #e6e6e6;
  }
}
.Grey_button{
  color:#838383;

  &:hover{
    color: #1eb1b5;
  }
}
.ant-tabs-tab .anticon {
  margin-right: 8px;
}
.Red_link{
  color: #ff7875;
  &.ant-btn-sm{
    font-size: 12px;
  }
  &:hover{
    color: #ff4d4f;

  }
}
.ant-dropdown-menu{
  overflow: hidden;
}
.ant-card, .ant-collapse {
  border-radius:16px;
}
.ant-card-head, .ant-modal-header{
  border-radius: 16px 16px 0 0;
}
.Progress_red .ant-progress-text{
  color: #de4f57;
}
.Progress_green .ant-progress-text{
  color: #52c41a;
}
.Progress_black .ant-progress-text{
  color: #4e4e4e;
}
.ant-menu-submenu, .ant-menu-submenu-inline{
  transition: border-color 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.05s cubic-bezier(0.645, 0.045, 0.355, 1)
}
.ant-menu-submenu .ant-menu-sub{
  transition: background 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-item, .ant-menu-submenu-title{
  transition: border-color 0.1s, background 0.1s, padding 0.05s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-horizontal .ant-menu-item, .ant-menu-horizontal .ant-menu-submenu-title{
  transition: border-color 0.1s, background 0.3s;
}
.ant-menu-horizontal .ant-menu-submenu{
  transition: border-color 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.1s cubic-bezier(0.645, 0.045, 0.355, 1)
}
.ant-table-small .ant-table-thead > tr > th {
  background-color: #f7f7f7;
}
.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #00718c;
}
.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header > div:last-child {
  width: 100%;
}

.ant-collapse>.ant-collapse-item:last-child,
.ant-collapse-item:last-child>.ant-collapse-content{
  border-radius: 0 0 16px 16px;
}
.ant-upload-list-picture-card .ant-upload-list-item-info::before{
  left:0;
}
.Select_lighter {
  .ant-select-selector{
    border-color: #efefef !important;
  }
}
.Select_team_custom {
  .ant-select-tree-indent-unit{
    width: 14px;
  }
}
.Drag_handle_wrapper{
  display: flex;
  align-items: center;
  margin-top: 12px;
}
.drag-handle {
  cursor: move!important;
}
.ant-statistic-content-suffix,
.ant-statistic-content-prefix{
  font-size: 18px;
}
.ant-table-wrapper {
  border-radius: 4px;
  box-shadow: 0px 0px 3px #80808042;
  margin-bottom: 2px;
  margin-left: 2px;
  margin-right: 2px;

  table {
    border-radius: 4px;
  }
}
.capialize{
  text-transform: capitalize;
}
.Typo_code_formula{
  white-space: break-spaces;
  word-break: break-all;
  margin-right: 0;
  border: none;

  code{
    border:none;
    font-size: 13px;
    background: #edf2fd;
    color: #2f54eb;
  }
}
.Hover_green{
  &:hover {
    color: #48b1b5;
    cursor: pointer;
  }
}
.ant-modal-content{
  border-radius: 16px;
}
.Condition {
  .AddonBefore {
    white-space: nowrap;
    position: relative;
    padding: 3px 11px;
    color: rgba(0,0,0,.85);
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    background-color: #fafafa;
    border: 1px solid #d9d9d9;
    border-right: none;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    height: 30px;
  }
  input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  &.ConditionTop {
    margin-bottom: -1px;
    .AddonBefore {
      border-bottom-left-radius: 0;
    }
    input {
      border-bottom-right-radius: 0;
      &:focus {
        z-index: 100;
      }
    }
    &:hover {
      input {
        z-index: 100;
      }
    }
  }
  &.ConditionBottom {
    .AddonBefore {
      border-top-left-radius: 0;
    }
    input {
      border-top-right-radius: 0;
    }
  }
}
.Green_table_columns{
  background: #e4f4f4;
}
.ant-table-tbody > tr.ant-table-row-level-0:hover > td.Green_table_columns {
  background: #e4f4f4;
}
// .ant-table {
//   border-radius: 6px;
//   box-shadow: 0px 0px 3px #00000021;
// }
// .Table_no_shadow .ant-table{
//   box-shadow: none;
//   border-radius: 4px;

// }

.No_scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
.Emoji_over_achieved{
  font-size: 20px;
}
.More_options {
  padding: 4px;
  cursor: pointer;

  &:hover{
    background: #e8e8e8;
    border-radius: 4px;
  }
}
// .ant-btn.ant-btn-link{
//   transition-duration: 0s;
//   &:hover {
//   background: #48b1b51a;
//   }
// }
.Palette_credits{
  position: fixed;
  z-index: 100;
  bottom: 0;
  left: 0;
  background: white;
  padding: 12px 14px;
  display: flex;
  align-items: center;
  font-size: 10px;
  color: grey;
  border-top-right-radius: 12px;
  transition: none;

  a{
    color: grey;
    &:hover {
      color: #1eb1b5;
      text-decoration: underline;
    }
  }


  img {
    width: 16px;
    max-width: 16px;
    height: 16px;
    max-height: 16px;
    margin-right: 6px;
  }
}
.First_steps_list{
  .ant-list-item-meta-title{
    font-weight: bold;
  }
}
.animate__animated.animate__fadeInUp{
  --animate-duration: 0.8s;
}
.Blue_alert{
  height: 100%;
  background-color: #1e7abf24;
  color: #1e7abf;
  border-color:#1e7abf45 ;
  .ant-alert-content {
   color: #1e7abf;

    .ant-alert-description,
    .ant-alert-message,
    .ant-alert-icon{
      color: #1e7abf;
    }
  }
  .ant-alert-icon{
    color: #1e7abf;
  }
}
@media screen and (max-width: 800px) {
  .Hide_on_mobile{
    display: none !important;
  }
  .Page_top_title_wrapper{
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 500px) {
  .Not_under_500{
    display: none !important;
  }
}


@media print {
  *{
    print-color-adjust: exact;
  }
  .No_print{
    display: none;
  }
}
