@import '~@palette/styles/typographies.less';
@import '~@palette/styles/colors.less';
@import '~@palette/styles/mixins.less';

.wrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 1.2rem 0;
  
  &:not(div:first-of-type) {
    border-top: .1rem solid @palette-color-border-light;
  }

  &.isDate:hover {
    .container {
      .type {
        display: none;
      }

      .storedAs {
        display: block;
      }
    }
  }

  .container {
    display: flex;
    align-items: center;
    flex: 2;
    .ellipsis();

    .property {
      min-width: 40%;
      max-width: 20rem;
      margin-right: 2rem;
      .ellipsis();
      .bodyBase1Medium();
      color: @palette-color-neutrals-dark;
    }

    .value {
      margin-right: 2rem;
      .bodyBase1Bold();
      .ellipsis();
      color: @palette-color-neutrals-dark;

      &.oldValue {
        text-decoration: line-through;
        background-color: @palette-color-fill-light-red;
      }

      &.highlighted {
        &:not(.oldValue) {
          background-color: @palette-color-fill-light-green;
        }

        &.highlightedChanges {
          background-color: @palette-color-fill-light-yellow;
        }

        border-radius: .4rem;
        padding: 0 .5rem;
      }
    }

    .arrowIcon {
      margin: 0 1rem 0 -1rem;
      .colorSvg(@palette-color-neutrals-grey2);
    }

    .type {
      .ellipsis();
      .bodyBase2MediumItalic();
      font-size: 1.2rem;
      line-height: 1.6rem;
      color: @palette-color-neutrals-grey1;
      padding-right: 2rem;
    }

    .storedAs {
      .ellipsis();
      .bodyBase2MediumItalic();
      font-size: 1.2rem;
      line-height: 1.6rem;
      color: @palette-color-neutrals-grey1;
      padding-right: 2rem;
      display: none;
    }
  }

  .fullWidth {
    flex: auto;
    width: 100%;
  }
}
