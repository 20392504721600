@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  .title2Medium();
  color: @palette-color-neutrals-dark;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  .curvedIcon {
    margin: 0 1rem 1rem 0;
    .colorSvg(@palette-color-fill-highlight);
  }

  .iconFolder {
    margin-right: 1rem;
    .colorSvg(@palette-color-primary-blue);

    &.yellow {
      .colorSvg(@palette-color-primary-yellow);
    }

    &.empty {
      .colorSvg(@palette-color-neutrals-grey2);
    }
  }
}
