@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  .bodyBase2Medium();
  color: @palette-color-neutrals-grey1;
  word-break: break-all;

  code {
    .code();
    color: @palette-color-shades-primary-blue-hover;

    padding: 0.2rem;
    border-radius: 0.4rem;
    background-color: @palette-color-fill-lavender;
  }
}
