.wrapper {
  max-width: 90rem;
  padding: 3rem 0 6rem;
  margin: 0 auto;

  .alert {
    .alertDescriptionWrapper {
      display: flex;
      flex-flow: row wrap;
      align-items: center;

      .alertDescriptionCTA {
        margin-left: 1.8rem;
      }
    }
  }
}
