@import '~@palette/styles/palette-theme.less';

.wrapper {
  margin-top: 3.2rem;

  @media(max-width: @screen-md-max) {
    margin-top: 1rem;
  }

  .pagination {
    margin-top: 4.4rem;
  }

  .emptyStateContainer {
    :global(.ant-empty-image) {
      height: 8rem;
    }
  }
}
