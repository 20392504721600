@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  .bodyBase2Bold();

  .tooltipWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    .tooltipIcon {
      margin-left: 0.2rem;
      .colorSvg(@palette-color-neutrals-grey2);
    }
  }

  .zeroValue {
    color: @palette-color-secondary-red;
  }

  .dot {
    width: 0.6rem;
    height: 0.6rem;
    border-radius: 50%;
    background-color: @palette-color-primary-blue;
    margin-left: 1rem;
    margin-bottom: 2rem;
  }
}
