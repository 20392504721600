@import '~@palette/styles/colors';

.wrapper {
  width: 20rem;
  min-width: 20rem;
  display: flex;
  flex-direction: row;
  align-items: center;

  .treeSelect {
    width: 100%;
  }

  .icon {
    .colorSvg(@palette-color-secondary-light-blue);
  }
}
