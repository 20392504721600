@import '~@palette/styles/mixins.less';

.wrapper {
  .widgetSizeSmall();

  .clappingHandsAnimation {
    width: 4rem;
    height: 4rem;
    position: absolute;
    top: -3rem;
    left: -1.5rem;
  }
}
