@import '~@palette/styles/colors.less';

.wrapper {
  .form {
    margin-top: 2rem;

    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    .formItem {
      width: 48%;

      .hireDatePicker {
        width: 100%;

        :global(.ant-picker-clear) {
          right: 4rem;
          :global(.anticon) {
            .colorSvg(@palette-color-neutrals-grey2);
          }

          &:hover {
            :global(.anticon) {
              .colorSvg(@palette-color-secondary-red);
            }
          }
        }
      }
    }
  }

  .submitBtn {
    width: 100%;
    justify-content: center;
    margin: auto;
  }
}
