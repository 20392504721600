@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.modal {
  :global(.ant-modal) {
    max-width: 100rem;
    width: fit-content !important;
  }

  .pagination {
    margin-top: 4.4rem;
  }
}
