@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.usersSelectDropdown {
  .userOption {
    width: 100%;
  }
}

.wrapper {
  position: relative;
  width: 100%;
  max-width: 30rem;
  min-height: 4rem;
  margin-bottom: 2.4rem;

  .nameContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;

    .avatar {
      margin-right: 1.2rem;
      opacity: 1;
      transition: all 0.1s ease-in-out;
    }

    .name {
      flex: 1;
      color: @palette-color-neutrals-dark;
      .headlineH3();
      opacity: 1;
      transition: all 0.1s ease-in-out;
      transform-origin: left;
    }

    .icon {
      margin-right: 1.1rem;
      .colorSvg(@palette-color-neutrals-dark);
      opacity: 1;
    }

    &.editable {
      .avatar {
        transform: scale(0.75);
        opacity: 0;
      }

      .name {
        transform: translateX(-1rem) scale(0.6);
        opacity: 0;
      }

      .icon {
        opacity: 0;
      }
    }
  }

  .usersSelect {
    width: 100%;
    opacity: 0;
    transition: opacity 0.1s ease-in-out;

    :global(.ant-select-selector) {
      padding: 1.1rem 1.6rem;
      border: 0;
      background-color: transparent;
      margin-left: -1.3rem;
    }

    &.editable {
      opacity: 1;
    }
  }
}
