@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  .container {
    margin-bottom: 4rem;

    &:not(:last-child) {
      padding-bottom: 4rem;
      border-bottom: .1rem solid @palette-color-border-light;
    }

    &:first-child {
      padding-bottom: 1.2rem;
    }

    .title {
      .headlineH2();
      color: @palette-color-neutrals-dark;
      margin-bottom: .4rem;
    }
  
    .subtitle {
      .bodyBase1Medium();
      color: @palette-color-neutrals-grey1;
      margin-bottom: 2.4rem;
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;
  }
}
