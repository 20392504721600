@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';
@import '~@palette/styles/mixins.less';

.wrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  .content {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    overflow: hidden;
    color: @palette-color-neutrals-grey1;
    .bodyBase2Medium();

    .link {
      margin-right: 1.5rem;
      .ellipsis();
    }

    .oldAmount {
      text-decoration: line-through;
      margin-right: .5rem;
    }

    .newAmount {
      margin-left: .5rem;
      color: @palette-color-neutrals-dark;
    }
  }

  .actions {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-end;

    .link {
      margin-left: 1.5rem;
      
      .icon {
        margin-right: .4rem;
        .colorSvg(@palette-color-primary-blue);
      }
    }
  }
}
