@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  .noQuotasAlertWrapper {
    .noQuotasAlertActions {
      margin-top: 1.5rem;
      display: flex;
      align-items: center;
    }
  }

  .quotaSetupWrapper {
    max-width: 74rem;
    margin: 0 auto;
  }

  .quotaSelectorWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    .quotaSelector {
      width: 30rem;
    }

    .quotaSelectorOr {
      margin-left: 1.5rem;

      .bodyBase2Medium();
      color: @palette-color-neutrals-grey1;
    }

    .createQuotaBtn {
      margin-left: 1.5rem;
    }
  }

  .linkQuotaBtn {
    margin-top: 1.5rem;
  }

  .missingUsersAlertWrapper {
    margin-top: 1.5rem;

    .profilesWrapper {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      overflow: auto;

      .profile {
        width: 25%;
        margin: 1.2rem 0;
      }
    }

    .confirmQuestion {
      margin-top: 1.5rem;

      .bodyBase2Bold();
      color: @palette-color-neutrals-dark;
    }

    .confirmBtn {
      margin-top: 1.5rem;
    }
  }

  .quotaTableWrapper {
    .quotaNameWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;

      .quotaLink {
        margin-left: 1.5rem;
      }
    }

    .quotaInfosWithActions {
      margin-top: 2.8rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .actions {
        display: flex;
        flex-direction: row;
        align-items: center;

        .action {
          margin-left: 2rem;

          &:first-child {
            margin-left: 0;
          }

          + .action {
            margin-left: 2rem;
          }
        }
      }
    }

    .quotaTable {
      margin-top: 1.5rem;
    }
  }
}
