@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 2.1rem 1.4rem;
  cursor: pointer;
  .bodyBase2Medium();
  color: @palette-color-neutrals-grey1;

  &:hover {
    color: @palette-color-primary-blue;
    background-color: @palette-color-fill-lavender;

    .datePicker {
      > :global(.ant-picker-input) {
        :global(.ant-picker-clear) {
          opacity: 1;
        }
      }
    }
  }

  .datePicker {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    padding: 0;
    border: 0;
    background: transparent;

    > :global(.ant-picker-input) {
      input, :global(.ant-picker-suffix) {
        visibility: hidden;
      }

      :global(.ant-picker-clear) {
        margin-right: 1rem;
        background-color: @palette-color-fill-lavender;

        :global(.anticon) {
          .colorSvg(@palette-color-secondary-red);
        }
      }
    }
  }
}
