.MappingItem {
  display: flex;
}
.Connector_details_label{
  font-size: 12px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.Connector_details_vignette_refresh{
  display: flex;
    justify-content: flex-end;
    width: 100%;
    letter-spacing: 1px;
}
.Connector_details_is_user_checkbox {
  display: flex;
  align-items: flex-start;

  svg * {
    fill: #0a1f38 !important;
  }
}
.Connector_details_card.ant-card{
  width: 950px;
  border:none;
  max-width: 100%;
  margin:auto;
  margin-top: 30px;
  box-shadow: 4px 5px 16px 2px #00000026;


  .ant-card-head{
    background: #093348;
    color: white;
    letter-spacing: 5px;
    font-size: 20px;
  }
  &.Connector_HUBSPOT{
    .ant-card-head{
      background: #f1731f;
    }
  }
  &.Connector_JOBADDER{
    .ant-card-head{
      background: #2772c2;
    }
  }
  &.Connector_SALESFORCE{
    .ant-card-head{
      background: #25a1e0;
    }
  }
  &.Connector_PIPEDRIVE{
    .ant-card-head{
      background: #25282b;
    }
  }
  &.Connector_SELLSY{
    .ant-card-head{
      background: #009ae6;
    }
  }
  &.Connector_QUICKBOOKS, &.Connector_QUICKBOOKS_SANDBOX {
    .ant-card-head {
      background: #2ba01c;
    }
  }
  &.Connector_OUTREACH {
    .ant-card-head {
      background: #5951ff;
    }
  }
  &.Connector_CLOSE {
    .ant-card-head {
      background: #4364af;
    }
  }
  &.Connector_CHARGEBEE {
    .ant-card-head {
      background: #ff6a28;
    }
  }
  &.Connector_XERO {
    .ant-card-head {
      background: #13b4ea;
    }
  }
  &.Connector_STRIPE {
    .ant-card-head {
      background: #635bff;
    }
  }
  &.Connector_GSHEET {
    .ant-card-head {
      background: #34a853;
    }
  }
  &.Connector_PENNYLANE {
    .ant-card-head {
      background: #4ac683;
    }
  }
  &.Connector_CRELATE {
    .ant-card-head {
      background: #338fec;
    }
  }
  &.Connector_SNOWFLAKE {
    .ant-card-head {
      background: #27AEDF;
    }
  }
  &.Connector_REDSHIFT {
    .ant-card-head {
      background: #205c97;
    }
  }
}

.Drag_line{
  border-bottom: 2px dashed #1eb1b5 !important;
}
.Connector_collapse {
  .ant-collapse-item{
    background: white;
  }
  .Connector_configure_resource {
    display: inline-flex;
    margin-left: 30px;
  }
  &.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header{
    padding: 12px 0px;
    padding-right: 40px;
  }
  .ant-collapse-content > .ant-collapse-content-box{
    padding: 16px 0px;
  }
}
.Connector_modal_close_icon {
  svg * {
    fill: #878787 !important;
  }
}
