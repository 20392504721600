 .ConnectCRM_wrapper{
  border-radius: 18px;
  margin-top: 6px;
  margin-bottom: 6px;
  background-color: white;
  box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.1);
  width: 1010px;
  max-width: 100%;
  margin: auto;
  .ConnectCRM_inner {
    padding: 40px;
    display: flex;
    width: 100%;
    max-width: 100%;
    position: relative;
    align-items: left;
    flex-direction: column;
    transition: all 1s;
  }

  .Source_type_wrapper{
    margin-top: 34px;
    margin-bottom: 24px;
    h3{
      margin-left: 8px;
    }
  }
  .ConnectCRM_inner_list{
    display: flex;
    flex-wrap: wrap;
  }
  .CRM_not_listed{
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: flex-end;
    color: grey;
    text-align: center;
    font-size: 14px;
    margin-top: 20px;
  }
 }
.Add_connector_steps{
  margin-top: 40px;
  .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot{
    background: rgba(0, 0, 0, 0.25);
  }
  .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot{
    background: #e2e2e2;
  }
  
  .ant-steps-item{
    max-width: 34px;
  }
  .ant-steps-dot .ant-steps-item-icon, .ant-steps-dot.ant-steps-small .ant-steps-item-icon{
    margin-left: 12px;
  }
  .ant-steps-dot .ant-steps-item-tail, .ant-steps-dot.ant-steps-small .ant-steps-item-tail{
    margin:0 0 0 16px;
  }
}
.Security_alert {
  .ant-alert-message{
    padding-right: 312px;
  }
}
.Soc2_panel {
  display: flex;
  padding: 12px;
  background: #effbff;
  border-radius: 8px;
  margin: 12px;
  margin-top: -26px;
  margin-bottom: 0px;
  height: fit-content;
}
 @media screen and (max-width: 800px) {
  .Soc2_panel {
    margin: 0px;
    margin-top: 12px;
  }
  .Security_alert {
    .ant-alert-message{
      padding-right: 0px;
    }
  }
 }
  @media screen and (max-width: 500px) {
  .ConnectCRM_wrapper{
    .ConnectCRM_inner {
      padding: 20px;
    }
   }
  
}
