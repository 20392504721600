@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  cursor: pointer;

  .link {
    .bodyBase2Medium();
    color: @palette-color-neutrals-grey1;
  }

  &:hover {
    .link {
      text-decoration: underline;
    }
  }
}
