@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  display: flex;
  flex-direction: column;

  .header {
    flex: 0 0 auto;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .quotaInfosWrapper {
      display: flex;
      align-items: baseline;

      .quotaName {
        .headlineH2();
        color: @palette-color-neutrals-dark;
      }

      .infos {
        margin-left: 0.5rem;
      }
    }
  }

  .table {
    width: 98%;
    margin: 2rem auto 0 auto;
  }

  .alert {
    margin-top: 2rem;
  }

  .addNewDefaultValueWrapper {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
  }
}
