@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 24.5rem;

  .targetWrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-bottom: .6rem;

    .targetType {
      .bodyBase2Medium();
      color: @palette-color-neutrals-grey2;
      white-space: break-spaces;
    }

    .targetProgressValue {
      .bodyBase2Medium();
      color: @palette-color-neutrals-grey1;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
