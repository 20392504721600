@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  .emptyState {
    margin: auto;
  }

  .header {
    margin-bottom: 2rem;
    width: 100%;

    .nameWrapper {
      display: flex;
      align-items: center;
      justify-content: center;

      .nameLink {
        .bodyBase1Bold();
        display: inline-block;
      }

      .nameEditButton {
        margin-left: 0.5rem;
        padding: 0;
        height: 1.4rem;
        width: 1.4rem;
      }
    }

    .planSelectWrapper {
      height: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;

      .nameEditCancelButton {
        margin-left: -1rem;
        padding: 0;
        height: 1.4rem;
        width: 1.4rem;
      }
    }

    .detailsWrapper {
      margin-top: 0.5rem;
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: center;

      .frequency {
        margin-right: 1rem;
        .bodyBase2Medium();
        color: @palette-color-neutrals-grey1;
        white-space: normal;
      }

      .scope {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-end;

        span {
          margin-left: 0;
          .caption1();
          padding: 0.2rem 0.5rem;

          & + span {
            margin-left: 0.5rem;
          }
        }
      }
    }

    .periodSelector {
      margin-top: 1rem;
    }
  }

  .title {
    margin-bottom: 3rem;
    .bodyBase2Bold();
    color: @palette-color-neutrals-dark;
    text-align: center;
  }

  .content {
    position: relative;
    flex: 1;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
  }
}
