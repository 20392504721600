@import '~@palette/styles/colors.less';
@import '~@palette/styles/mixins.less';
@import '~@palette/styles/palette-theme.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  border-radius: @border-radius-base;
  padding: .4rem .8rem;
  background-color: @palette-color-neutrals-white;
  border: .1rem solid @palette-color-border-normal;

  .content {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    .ellipsis();

    .icon {
      min-width: 1.8rem;
      .colorSvg(@palette-color-neutrals-grey2);
    }

    .label {
      .caption1();
      color: @palette-color-neutrals-grey1;
      .ellipsis();

      &:not(.no-margin) {
        margin-left: .4rem;
      }
    }
  }

  &.warning {
    background-color: @palette-color-fill-soft-vanilla;
    border: .1rem solid @palette-color-fill-soft-vanilla;

    .content {
      .icon {
        .colorSvg(@palette-color-secondary-orange);
      }
  
      .label {
        .caption1Bold();
        color: @palette-color-secondary-orange;
      }
    }
  }

  &.success {
    background-color: @palette-color-fill-white-grape;
    border: .1rem solid @palette-color-fill-white-grape;

    .content {
      .icon {
        .colorSvg(@palette-color-primary-teal);
      }
  
      .label {
        .caption1Bold();
        color: @palette-color-primary-teal;
      }
    }
  }

  &.info {
    background-color: @palette-color-fill-light-blue;
    border: .1rem solid @palette-color-fill-light-blue;

    .content {
      .icon {
        .colorSvg(@palette-color-primary-blue);
      }
  
      .label {
        .caption1Bold();
        color: @palette-color-primary-blue;
      }
    }
  }

  &.lavender {
    background-color: @palette-color-fill-lavender;
    border: .1rem solid @palette-color-fill-lavender;

    .content {
      .icon {
        .colorSvg(@palette-color-secondary-lavender);
      }
  
      .label {
        .caption1Bold();
        color: @palette-color-neutrals-grey1;
      }
    }
  }

  &.error {
    background-color: @palette-color-fill-secondary-red;
    border: .1rem solid @palette-color-fill-secondary-red;

    .content {
      .icon {
        .colorSvg(@palette-color-secondary-red);
      }
  
      .label {
        .caption1Bold();
        color: @palette-color-secondary-red;
      }
    }
  }
}
