@import '~@palette/styles/palette-theme.less';

.wrapper {
  height: 100%;
  max-width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  margin: auto;

  .emptyState {
    height: 75%;
    width: 75%;
  }

  .image {
    width: 80%;
    height: auto;
  }

  .description {
    margin-top: -20rem;
  }


  @media(min-width: @screen-xl) {
    max-width: 110rem;
  }

  @media(min-width: @screen-xxl) {
    max-width: 140rem;
  }
}
