@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';
@import '~@palette/styles/mixins.less';

.wrapper {
  .widgetSizeLarge();

  .content {
    flex: 1;
    width: 100%;
  }
}
