.wrapper {
  .form {
    display: flex;
    flex-flow: row nowrap;

    .input {
      margin-right: 1rem;
    }
  }
}
