@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  padding-left: 3rem;
  border-left: 0.2rem solid @palette-color-fill-antiflash-white;
  margin-left: 0.2rem;
  padding-top: .5rem;

  &.contentFromRoot {
    margin-left: 0.5rem;
    padding-left: 0;
    border-left: 0;
  }

  &.draggableIsOver {
    background-color: @palette-color-fill-sky;
  }
}
