@import '~@palette/styles/colors.less';
@import '~@palette/styles/palette-theme.less';

.wrapper {
  display: flex;
  flex-flow: row nowrap;
  height: 100%;
  width: 100%;
  overflow: auto;

  &.autoWidth {
    padding-left: calc((100% - 140rem) / 2);
  }

  .leftSide {
    width: 32rem;
    min-width: 32rem;
    display: flex;
    flex-direction: column;

    .header {
      padding: 3rem 2.4rem 0;
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: space-between;
      
      &:not(.borderless) {
        border-bottom: .1rem solid @palette-color-border-light;
      }
    }

    .leftSideContent {
      padding: 2rem 2.4rem;
      overflow-y: auto;
    }
  }

  .main {
    flex: 1;

    .mainWrapper {
      padding: 3rem 2.4rem;
      min-height: 100%;

      &:not(.borderless) {
        border-left: .1rem solid @palette-color-border-light;
      }
    }
  }

  @media(min-width: @screen-lg-min) {
    max-width: 100%;
    overflow: hidden;

    .main {
      overflow: auto;
    }
  }
}
