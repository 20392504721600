@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  padding: 0 4rem;

  .overwriteEntry {
    padding: 1.6rem 0;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;

    &:not(:last-child) {
      border-bottom: .1rem solid @palette-color-border-light;
    }

    .content {
      display: flex;
      flex-flow: column nowrap;

      p {
        .bodyBase1Medium();
        color: @palette-color-neutrals-dark;
        margin-bottom: 0;
        word-break: break-word;

        strong {
          .bodyBase1Bold();
        }
      }
        
      span {
        .bodyBase1Medium();
        color: @palette-color-neutrals-grey1;
      }
    }

    .action {
      margin-left: 4rem;

      .rollbackButton {
        .bodyBase1Medium();
    
        &:hover, &:active, &:focus {
          .bodyBase1Medium();
        }
      }
    }
  }
}