@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  width: fit-content;
  max-width: calc(100% - 1.6rem);

  &:hover {
    cursor: pointer;
  }

  &.disabled {
    cursor: default;
  }
}

.input {
  width: calc(100% - 1.6rem);

  padding: 0.4rem 1.6rem;
  background-color: @palette-color-neutrals-white !important;
  border: 0.1rem solid @palette-color-primary-blue;
  border-radius: 0.8rem;

  .code();
  color: @palette-color-neutrals-dark;
  height: 2.6rem;
}
