@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';
@import '~@palette/styles/palette-theme.less';

.wrapper {
  border-radius: 1.8rem;
  border: 0.1rem solid @palette-color-border-normal;
  padding: 1rem 1.8rem 0 1.8rem;
  min-width: fit-content;
  display: flex;
  flex-flow: column nowrap;

  .spacedBlocks {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;

    &.spacedBlocksInFooter {
      margin-bottom: 1rem;
    }

    &.spacedBlocksInversed {
      flex-direction: row-reverse;
    }
  }

  .nameDatesWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 1rem;
    margin-bottom: 1rem;

    .name {
      .bodyBase1Bold();
    }

    .dates {
      margin-left: 1.4rem;
      color: @palette-color-neutrals-dark;
      .bodyBase1Medium();
    }
  }

  .statusesWrapper {
    display: flex;
    flex-flow: row wrap;
    align-items: center;

    .totalWrapper {
      min-width: auto;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 1rem;

      .totalLabel {
        color: @palette-color-neutrals-grey1;
        .bodyBase2Medium();
      }

      .totalAmount {
        margin-left: 0.6rem;
        color: @palette-color-neutrals-dark;
        .bodyBase2Bold();
      }
    }
  }
}
