.wrapper {
  .joinLeaveDateWarning {
    width: fit-content;
    margin: 0 auto 2rem auto;
  }

  .content {
    .tabs {
      width: 100%;
      margin: 0 auto;
  
      :global(.ant-tabs-nav) {
        margin-bottom: 3rem;
      }
    }
  }
}
