@import '~@palette/styles/colors.less';
@import '~@palette/styles/fonts.less';
@import '~@palette/styles/typographies.less';
@import '~@palette/styles/palette-theme.less';

.wrapper {
  .tabs {
    width: 100%;
    margin: 0 auto;

    :global(.ant-tabs-nav) {
      margin-bottom: 3rem;

      :global(.ant-tabs-tab) {
        padding-top: 0;
      }
    }

    .tabsConnectorsContainer {
      display: flex;
      flex-flow: row wrap;
      align-items: flex-start;

      .tabItemConnectors {
        flex: 2;
        min-width: 30rem;

        @media(min-width: @screen-lg-min) {
          margin-right: 4rem;
        }

        .formItemWrapper {
          margin-bottom: 3rem;

          .typeFormItemLabel {
            margin-bottom: .6rem;
            line-height: 2.4rem;
          }
        }
      }

      .tabItemResources {
        flex: 3;
        min-width: 30rem;

        .typeFormItemLabel {
          display: flex;
          flex-direction: row;
          align-items: center;
          flex-wrap: wrap;
          margin-bottom: .8rem;
      
          mark {
            margin: 0 0.5rem;
            padding: 0.2rem 0.5rem;
            background-color: @palette-color-fill-lavender;
            font-weight: @palette-font-weight-bold;
          }
        }
      }
    }
  }

  .matchingWrapper {
    padding-top: 3rem;
    border-top: .1rem solid @palette-color-border-normal;
    margin-bottom: 2.8rem;

    .matchingLabel {
      margin-bottom: 1rem;

      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;

      .inputLabel();
      color: @palette-color-neutrals-grey1;

      mark {
        margin: 0 0.5rem;
        padding: 0.2rem 0.5rem;
        background-color: @palette-color-fill-lavender;
        font-weight: @palette-font-weight-bold;
      }
    }

    .matchingArea {
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: flex-start;

      .matchingAreaShouldMatchWrapper {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-evenly;
        margin: 3rem 0;
        .bodyBase1Bold();
        color: @palette-color-primary-blue;
        width: 100%;

        @media(min-width: @screen-lg-min) {
          width: auto;
          margin: 6rem 0;
        }

        .matchingAreaShouldMatchLabel {
          text-align: center;
        }

        .matchingAreaIcon {
          margin: 0 1rem;
          .colorSvg(@palette-color-primary-blue);
        }
      }
    }

    .matchingFieldFormItemWrapper {
      width: 100%;

      @media(min-width: @screen-lg-min) {
        width: 25rem;
      }

      .matchingFieldFormItemLabelWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        border: 0.1rem solid @palette-color-border-light;
        border-radius: @border-radius-base;
        padding: 0.5rem 1rem;

        .matchingFieldFormItemLabel {
          display: flex;
          flex-direction: row;
          align-items: center;

          .matchingFieldFormItemIcon {
            font-size: 1.8rem;
            color: @palette-color-primary-blue;
            .colorSvg(@palette-color-primary-blue);
          }

          .matchingFieldFormItemLabelContent {
            margin-left: 1rem;

            .bodyBase2Bold();
            color: @palette-color-primary-blue;
          }
        }

        .matchingFieldFormItemConnector {
          padding: 0.2rem 0.5rem;
          background-color: @palette-color-fill-lavender;
          border-radius: @border-radius-base;

          .bodyBase2Bold();
          color: @palette-color-neutrals-dark;
        }
      }

      .matchingFieldFormItem {
        margin-top: 1rem;
        margin-bottom: 0;
      }

      @media(min-width: @screen-xxl-min) {
        width: 35rem;
      }
    }
  }
}
