@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';
@import '~@palette/styles/palette-theme.less';
@import '~@palette/styles/mixins.less';

.wrapper {
  padding: 1.6rem 1.2rem;
  border-radius: @border-radius-base;
  cursor: pointer;

  &:hover {
    background-color: @palette-color-fill-sky;
  }

  .name {
    .bodyBase1Medium();
    color: @palette-color-neutrals-dark;
    .ellipsis();
  }

  &.selected {
    background-color: @palette-color-fill-sky;

    .name {
      .bodyBase1Bold();
      color: @palette-color-primary-blue;
    }

    .infos {
      .bodyBase2Bold();
      color: @palette-color-neutrals-dark;
    }
  }
}
