@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  .backLink {
    align-self: flex-start;
    margin-bottom: 3.6rem;

    .icon {
      .colorSvg(@palette-color-primary-blue);
    }
  }

  .userContainer {
    margin-bottom: 2rem;
  }

  .fromLink {
    margin-bottom: .6rem;
  }

  .suggestedAmount {
    margin-bottom: 1.2rem;
  }

  .actionsContainer {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    margin-right: 1.5rem;
  }
}
