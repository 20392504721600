@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 1rem 1rem 0.5rem 1rem;
  border-bottom: .1rem solid @palette-color-border-light;

  &:hover {
    background-color: @palette-color-fill-table-group;
  }

  .row {
    display: flex;
    flex-flow: row wrap;
    align-items: center;

    .periodName {
      min-width: 10rem;
      margin-bottom: 0.5rem;
      margin-right: 1rem;
      .bodyBase2Bold();
      color: @palette-color-neutrals-dark;
    }

    .periodDates {
      min-width: 20rem;
      margin-bottom: 0.5rem;
      margin-right: 2rem;
      .bodyBase2Medium();
      color: @palette-color-neutrals-grey1;
    }

    .who {
      min-width: 15rem;
      margin-bottom: 0.5rem;
      margin-right: 2rem;
    }

    .actionDone {
      min-width: 30rem;
      margin-bottom: 0.5rem;
      margin-right: 2rem;
      .bodyBase2MediumItalic();
    }

    .amount {
      min-width: 15rem;
      margin-bottom: 0.5rem;
      margin-right: 1.5rem;
      .bodyBase1Bold();
    }

    .actionsContainer {
      display: flex;
      justify-content: flex-end;

      .exportButton {
        height: auto;
        padding: 0;
        margin-bottom: 0.5rem;
      }
    }
  }
}
