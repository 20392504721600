@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  .connectorsDescription {
    margin-bottom: 0.3rem;

    .bodyBase1Medium();
    color: @palette-color-neutrals-grey1;
  }

  .connectorsSelect {
    margin-bottom: 2.8rem;
    width: 30rem;
  }

  .addUsersWrapper {
    + .addUsersWrapper {
      margin-top: 2.8rem;
    }

    .addUsersTitleWrapper {
      .addUsersTitle {
        .bodyBase1Bold();
        color: @palette-color-neutrals-dark;
      }

      .addUsersSubTitle {
        .bodyBase2Medium();
        color: @palette-color-neutrals-grey1;
      }
    }

    .usersSelectionPanel {
      margin-top: 2.8rem;

      max-height: 67rem;
      height: 67rem;
      width: 59rem;

      > div:last-child {
        max-height: calc(100% - 8rem);
      }
    }

    .addUsersBtn {
      margin-top: 2rem;
    }

    .selectedUsersWrapper {
      margin-top: 2.8rem;

      border: 0.2rem solid @palette-color-border-normal;
      border-radius: 1rem;
      padding: 1.5rem;

      .selectedUsersTitle {
        .bodyBase1Bold();
        color: @palette-color-neutrals-dark;
      }

      .selectedUsersPanel {
        margin-top: 1rem;
      }
    }
  }
}
