@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';
@import '~@palette/styles/palette-theme.less';

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 0.8rem 1.6rem;
  border-radius: @border-radius-base;
  border: 0.1rem solid @palette-color-border-normal;

  .periodYearWrapper {
    padding-right: 0.8rem;
    border-right: 0.1rem solid @palette-color-border-normal;

    &:last-child {
      padding-left: 0.8rem;
      padding-right: 0;
      border-right: none;
    }

    .editablePeriodYearContent {
      .contentWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;

        .content {
          .bodyBase1Bold();
          color: @palette-color-neutrals-dark;
        }

        .contentIcon {
          margin-left: 0.2rem;
          .colorSvg(@palette-color-neutrals-grey2);
        }
      }
    }

    &:hover {
      .editablePeriodYearContent {
        .contentWrapper {
          .content {
            color: @palette-color-primary-blue;
          }
          .contentIcon {
            .colorSvg(@palette-color-primary-blue);
          }
        }
      }
    }

    &.popoverVisible {
      .editablePeriodYearContent {
        .contentWrapper {
          .content {
            color: @palette-color-primary-blue;
          }
          .contentIcon {
            .colorSvg(@palette-color-primary-blue);
          }
        }
      }
    }
  }
}
