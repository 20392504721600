@import '~@palette/styles/colors.less';

.wrapper {  
  .deleteButton {
    justify-content: center;
    padding: 0;
    height: auto;

    &:hover {
      .deleteIcon {
        .colorSvg(@palette-color-secondary-red);
      }
    }
  }
}
