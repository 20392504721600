@import '~@palette/styles/typographies.less';
@import '~@palette/styles/colors.less';

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  .bodyBase2Medium();
  color: @palette-color-neutrals-dark;

  div:first-child {
    margin-right: 0.5rem;
  }

  div:last-child {
    margin-left: 0.5rem;
  }

  .condition {
    margin: 0 0.3rem;
  }

  .conditionWithOperator {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;

    .operator {
      margin: 0 0.3rem;

      .bodyBase2Bold();
      color: @palette-color-primary-blue;
    }
  }
}
