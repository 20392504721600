@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';
@import '~@palette/styles/palette-theme.less';

.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;

  .bodyBase1Medium();
  color: @palette-color-shades-navy-sky-blue;

  &:hover {
    color: inherit;
  }

  :global(a) {
    color: inherit;

    &:hover {
      color: inherit;
    }
  }

  .button {
    margin-left: 1rem;
    padding: 0.2rem 0.5rem;
    min-width: auto;
    border-radius: @border-radius-base;
    width: auto;
    height: auto;
    background-color: @palette-color-shades-navy-sky-blue;
    display: none;

    :global(.anticon) {
      .colorSvg(@palette-color-shades-sea-blue);
    }

    &:hover {
      background-color: @palette-color-neutrals-white;
    }
  }
}

:global(.ant-layout-sider:hover), :global(.ant-menu-item-selected), :global(.ant-tooltip) {
  .wrapper {
    .button {
      display: inline-block;
      
      .icon {
        pointer-events: none;
      }
    }
  }
}
