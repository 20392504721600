@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';
@import '~@palette/styles/palette-theme.less';

.wrapper {
  position: relative;
  padding: 0 4rem 4rem;

  .toolbox {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: .4rem;
    width: 100%;

    .search {
      width: 16rem;
      min-width: 16rem;
      margin-left: 2rem;

      @media(min-width: @screen-md-min) {
        width: 24rem;
        min-width: 24rem;
        margin-left: 1rem;
      }

      :global(.ant-input-wrapper) {
        :global(.ant-input-affix-wrapper) {
          input {
            padding-right: 2rem;
          }
        }
      }
    }

    .actionsWrapper {
      display: flex;
      flex-flow: row-reverse nowrap;
      align-items: center;
      position: absolute;
      bottom: 6.5rem;
      right: 0;

      .actionBtn {
        margin-left: 1.5rem;
      }

      .action {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: .2rem 0;
        height: auto;
        margin-left: 1.5rem;

        @media(min-width: @screen-xxl-min) {
          margin-left: 2rem;
        }

        .actionIcon {
          .colorSvg(@palette-color-neutrals-grey1);
        }

        .actionLabel {
          display: none;
          margin-left: 0.4rem;
          .bodyBase2Medium();
          color: @palette-color-neutrals-grey1;

          @media(min-width: @screen-md-min) {
            display: block;
          }
        }

        &:hover {
          .actionIcon {
            .colorSvg(@palette-color-primary-blue);
          }

          .actionLabel {
            color: @palette-color-primary-blue;
          }
        }
      }
    }
  }

  .tabs {
    overflow: visible;

    :global(.ant-tabs-nav) {
      margin-bottom: 1.6rem;
    }
  }
}
