.wrapper {
  width: 2.4rem;
  height: 2.4rem;
}

:global(.ant-dropdown-menu) {
  :global(.ant-dropdown-menu-item) {
    .iconMenuItem {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 1.4rem;
    }
  }
}
