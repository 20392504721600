@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.modal {
  :global(.ant-modal) {
    max-width: 40rem;
  }

  .titleWrapper {
    .headlineH2-2();
    color: @palette-color-neutrals-dark;
  }

  .content {
    max-width: 40rem;

    .alert {
      margin-bottom: 2.8rem;
    }

    .dateField {
      width: 100%;
    }
  }
}
