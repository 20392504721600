@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  position: relative;
  padding: 0 4rem 4rem;

  .filters {
    flex: 0 0 auto;

    margin-bottom: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;

    .search {
      width: 28rem;
      min-width: 28rem;

      margin-left: 1.2rem;
    }

    .onlyActiveFilter {
      margin-left: 1.2rem;

      display: flex;
      align-content: center;

      .onlyActiveFilterLabel {
        margin-left: 0.7rem;

        .bodyBase1Medium();
        color: @palette-color-neutrals-dark;
      }
    }

    .resetFiltersBtn {
      margin-left: 1.2rem;
    }
  }

  .table {
    width: 100%;
    height: calc(100vh - 20.2rem);
    position: relative;

    .emptyState {
      position: absolute;
      top: 5rem;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
