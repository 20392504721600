@import '~@palette/styles/colors.less';
@import '~@palette/styles/palette-theme.less';

.wrapper {
  box-shadow: @box-shadow-base;
  background-color: @palette-color-neutrals-white;
  border-radius: @border-radius-base;
  padding: 2rem;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  .usersVariablesSelect {
    width: 22rem;
  }

  .inBulkEditBtn {
    margin-left: 2rem;
  }
}
