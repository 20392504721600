@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  width: 100%;

  .bodyBase2Medium();
  color: @palette-color-neutrals-dark;
  padding: 1.3rem 1.4rem;
  white-space: break-spaces;

  &.borderless {
    padding: 1rem 0.5rem;

    .bodyBase2Medium();
    color: @palette-color-neutrals-grey2;
  }

  .sortIcon {
    margin-left: .5rem;
    vertical-align: bottom;
    .colorSvg(@palette-color-neutrals-grey2);

    &.sortIconActive {
      .colorSvg(@palette-color-primary-blue);
    }
  }
}
