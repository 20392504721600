@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.typeSelectDropdown {
  width: fit-content !important;
}

.wrapper {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;

  .navigationWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 4rem;

    .prevNextBtn {
      padding: 0;
      width: 2.4rem;
      height: 2.4rem;

      :global(.anticon) {
        .colorSvg(@palette-color-neutrals-dark);
      }

      &:hover, &:active, &:focus {
        :global(.anticon) {
          .colorSvg(@palette-color-primary-blue);
        }
      }

      &.disabled {
        :global(.anticon) {
          .colorSvg(@palette-color-neutrals-grey2);
        }

        &:hover, &:active, &:focus {
          :global(.anticon) {
            .colorSvg(@palette-color-neutrals-grey2);
          }
        }
      }
    }

    .typeSelectWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      min-width: 22.5rem;

      .typeSelectIcon {
        .colorSvg(@palette-color-primary-blue);
      }

      .typeSelect {
        margin-left: 0.4rem;

        :global(.ant-select-selector) {
          padding: 0;
          height: 3.2rem;
          border-radius: initial;
          border: none;

          :global(.ant-select-selection-item) {
            .headlineH2();
            color: @palette-color-neutrals-dark;
          }
        }
      }

      &:hover {
        .typeSelect {
          :global(.ant-select-selector) {
            :global(.ant-select-selection-item) {
              color: @palette-color-primary-blue;
            }
          }
        }
      }
    }
  }

  .datesRangePicker {
    width: 25rem;
    padding: 0.8rem 4rem 0.8rem 0.8rem;
    margin-left: 1.5rem;

    &:global(.ant-picker-range) {
      :global(.ant-picker-active-bar) {
        margin-left: .8rem;
      }
    }
  }
}
