@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  min-height: 2.4rem;

  .value {
    .bodyBase2Medium();
    color: @palette-color-neutrals-dark;
  }

  .button {
    margin: 0;
    padding: .4rem 0;
    height: auto;
    justify-content: center;
  }
}
