@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  .bodyBase2Medium();
  color: @palette-color-neutrals-grey1;
  background-color: transparent;
  width: 100%;
  position: relative;

  cursor: pointer;

  .input {
    padding-right: 2.3rem;
  }

  .switchWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.isAddNewRow {
    background-color: @palette-color-fill-grey;
    width: 100%;
    height: 100%;
  }
}
