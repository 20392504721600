@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.modal {
  :global(.ant-modal) {
    max-width: 60rem;
  }

  .titleWrapper {
    .headlineH2-2();
    color: @palette-color-neutrals-dark;
  }

  .paymentsDoneAlert {
    margin-bottom: 1rem;
  }

  .resetToOriginalAmountButton {
    padding: 0;
    max-width: 13rem;

    span {
      white-space: pre-line;
      text-align: left;
    }
  }
}
