.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem 1rem 1.4rem;
  width: 100%;
  position: relative;

  .profile {
    width: calc(100% - 2rem);
  }

  .addQuotaUserLeaveDate {
    display: none;

    &.lastPeriodDefined {
      display: block;
    }
  }

  &:hover {
    .addQuotaUserLeaveDate {
      display: block;
    }
  }

  .removeUserFromQuota {
    display: none;
  }

  &:hover {
    .removeUserFromQuota {
      display: block;
    }
  }
}
