@import '~@palette/styles/colors.less';

.wrapper {
  margin-bottom: 0;

  .dateField {
    width: 100%;

    :global(.ant-picker-suffix) {
      right: .1rem;
    }

    :global(.ant-picker-clear) {
      opacity: 1;
      right: 2.8rem;
      :global(.anticon) {
        .colorSvg(@palette-color-neutrals-grey2);
      }

      &:hover {
        :global(.anticon) {
          .colorSvg(@palette-color-secondary-red);
        }
      }
    }
  }
}
