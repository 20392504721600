@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';
@import '~@palette/styles/palette-theme.less';

.wrapper {
  min-width: 44rem;

  .summaryStep {
    &:not(.rulesContainer) {
      margin-bottom: 3rem;
    }

    .title {
      color: @palette-color-neutrals-dark;
      .bodyBase1Bold();
      margin-bottom: .8rem;
    }

    .subtitle {
      color: @palette-color-neutrals-grey2;
      .bodyBase2Medium();
      margin-bottom: 1.6rem;
    }

    .card {
      border: .1rem solid @palette-color-border-light;
      border-radius: @border-radius-base;

      .cardItem {
        display: flex;
        flex-flow: row nowrap;

        &:first-child {
          .cardItemLeft {
            padding-top: 2.4rem;
            border-top-left-radius: @border-radius-base;
          }

          .cardItemRight {
            padding-top: 2.4rem;
          }
        }

        &:last-child {
          .cardItemLeft {
            padding-bottom: 2.4rem;
            border-bottom-left-radius: @border-radius-base;
          }

          .cardItemRight {
            padding-bottom: 2.4rem;
          }
        }

        .cardItemLeft {
          flex: 1;
          background-color: @palette-color-fill-grey;
          .bodyBase2Medium();
          color: @palette-color-neutrals-grey2;
          padding: .8rem 3rem;
        }

        .cardItemRight {
          flex: 2;
          .bodyBase2Medium();
          color: @palette-color-neutrals-dark;
          padding: .8rem 3rem;
        }
      }
    }

    .content {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .bodyBase1Medium();
      color: @palette-color-neutrals-grey1;

      &.empty {
        margin-bottom: 3rem;
      }

      .iconLinked {
        .colorSvg(@palette-color-primary-blue);
      }

      .quotaLink {
        .bodyBase1Medium();
        color: @palette-color-primary-blue;
      }
    }
  }
}
