@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';
@import '~@palette/styles/palette-theme.less';
@import '~@palette/styles/mixins.less';

.wrapper {
  padding: 1.2rem;
  border-radius: @border-radius-base;
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;

  &:hover {
    background-color: @palette-color-fill-sky;
  }

  .column {
    .bodyBase2Medium();
    color: @palette-color-neutrals-grey1;
    .ellipsis();
    flex: .8;

    &:last-child {
      color: @palette-color-neutrals-dark;
      text-align: right;
    }

    &:not(:last-child) {
      margin-right: 1rem;
    }

    &:nth-child(1) {
      flex: 2;
      .bodyBase1Medium();
      color: @palette-color-neutrals-dark;
    }

    &:nth-child(2) {
      flex: 1.5;
    }

    &:nth-child(3) {
      flex: 1;
    }

    &:nth-child(4) {
      text-decoration: line-through;
      text-align: right;
    }
  }

  &.selected {
    background-color: @palette-color-fill-sky;

    .column {
      color: @palette-color-primary-blue;
      
      &:first-child {
        .bodyBase1Bold();
      }
    }
  }
}
