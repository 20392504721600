@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  display: flex;
  flex-flow: column nowrap;

  .text {
    .bodyBase1Medium();
    color: @palette-color-neutrals-dark;
    margin-bottom: 1.6rem;

    .buttonLink {
      color: @palette-color-primary-blue;
      padding: 0;
      margin: 0;
      display: inline-flex;
      font-size: inherit;
      height: auto;
    }
  }
}