@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.modal {
  :global(.ant-modal) {
    max-width: 62rem;
  }

  .quotaName {
    .headlineH2();
    color: @palette-color-neutrals-dark;
    margin-top: 0.8rem;
  }
}