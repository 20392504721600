@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  display: flex;
  flex-flow: column nowrap;

  .textContent {
    margin-bottom: 1.6rem;
    color: @palette-color-neutrals-dark;
    .bodyBase2Medium();
  }

  .inputContainer {
    padding-left: 0;

    &:global(.ant-radio-group) {
      :global(.ant-radio-wrapper) {
        color: @palette-color-neutrals-dark;
        .bodyBase2Medium();
      }
    }
  }
}
