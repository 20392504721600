.Connector_vignette_refresh{
  padding: 10px 18px;
  position: absolute;
  display: flex;
  /* justify-content: flex-end; */
  top: 0;
  right: 0;
  z-index: 1;
  cursor: pointer;
}
.Connector_vignette_wrapper{
  border-radius: 16px;
  margin: 12px;
  background-color: #ffffff;
  background: #093348;
  color: white;
  box-shadow: 1px 2px 12px rgba(0, 0, 0, 0.1);
  position: relative;

  .Connector_vignette_inner{
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 320px;
    max-width: 100%;
    position: relative;
    height: 100%;

    .Connector_vignette_type{
      letter-spacing: 5px;
      color: white;
    }
  }
  &.SALESFORCE_back {
    background: #1c86bb !important;
}
}
.Connectors_list{
  display: flex;
  flex-wrap: wrap;
}
.Connector_pastille{
  min-width: 18px;
  min-height: 18px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  line-height: 30px;
  position: absolute;
  top:12px;
  left:12px;
}