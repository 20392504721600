@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';
@import '~@palette/styles/mixins.less';

.wrapper {
  .titleWrapper {
    display: flex;
    flex-flow: row wrap;
    align-items: center;

    .title {
      .title1Bold();
      color: @palette-color-neutrals-dark;
    }

    .lottieAnimation {
      margin: 0 0 .8rem .8rem;
      width: 3.2rem;
      height: 3.2rem;
    }
  }

  .widgets {
    margin-top: 2.4rem;

    .widgetsGrid();
  }
}
