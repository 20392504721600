@import '~@palette/styles/typographies.less';
@import '~@palette/styles/colors.less';

.periodsWrapper {
  display: flex;
  flex-direction: column;

  .periodNameLink {
    + .periodNameLink {
      margin-top: 0.5rem;
    }

    color: @palette-color-secondary-light-blue;

    &:hover {
      color: @palette-color-secondary-light-blue;
    }
  }
}

.wrapper {
  display: flex;
  flex-flow: row wrap;
  align-items: center;

  .nameWrapper {
    display: flex;
    flex-flow: row wrap;
    align-items: center;

    .name {
      .bodyBase1Bold();
      display: inline-block;
    }

    .scope {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-end;

      span {
        margin-left: 0;
        .caption1();
        padding: 0.2rem 0.5rem;

        & + span {
          margin-left: 0.5rem;
        }
      }
    }
  }

  .periodsCountTag {
    margin-left: 1rem;

    &:hover {
      cursor: pointer;
    }
  }
}
