@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  .collapse {
    :global(.ant-collapse-item) {
      :global(.ant-collapse-header) {
        height: auto;
      }
    }
  }

  .content {
    margin-top: 1.2rem;
  }
}
