@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  .bodyBase2Medium();
  color: @palette-color-neutrals-grey1;
  width: 100%;
  position: relative;
  padding: 1.2rem 1.3rem;
  user-select: none;

  cursor: default;

  .editablePeriodYear {
    .bodyBase2Medium();
    color: @palette-color-neutrals-grey1;
    width: 100%;
    position: relative;
    border: 0.1rem solid transparent;
    user-select: none;

    cursor: default;

    .editablePeriodYearContent {
      padding: 1.2rem 1.3rem;
    }

    &:hover {
      cursor: pointer;
      color: @palette-color-neutrals-dark;
      border-color: @palette-color-primary-blue;
    }

    &.popoverVisible {
      cursor: pointer;
      color: @palette-color-neutrals-dark;
      border-color: @palette-color-primary-blue;
    }

    &.toDelete {
      text-decoration: line-through;

      input {
        text-decoration: line-through;
      }
    }
  }

  &.editable {
    padding: 0;
  }

  &.toDelete {
    text-decoration: line-through;

    input {
      text-decoration: line-through;
    }
  }

  .lockedIndicator {
    display: none;
  }

  &:hover {
    .lockedIndicator {
      display: flex;
    }
  }
}
