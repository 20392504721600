@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  .header {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3rem;

    .infos {
      display: flex;
      flex-flow: column nowrap;
      margin-right: 2rem;

      .title {
        display: flex;
        flex-flow: row wrap;
        align-items: baseline;
        margin-bottom: .6rem;

        .planName {
          color: @palette-color-neutrals-dark;
          margin-right: 1rem;
        }
      }

      .metric {
        .bodyBase1Medium();
        color: @palette-color-neutrals-grey1;

        span {
          margin-left: .5rem;
          .bodyBase1Bold();
        }
      }
    }

    .actions {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
    }
  }

  .alertContainer {
    margin-bottom: 2rem;
  }

  .table {
    .footerCell {
      display: flex;
      flex-flow: column nowrap;
      flex: 1;
      overflow: hidden;
    }
  }
}
