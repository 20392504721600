@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  border: 0.2rem solid @palette-color-border-normal;
  border-radius: 1rem;
  padding: 2rem 1rem;
  cursor: pointer;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;

  .name {
    .bodyBase1Bold();
    color: @palette-color-neutrals-dark;
  }

  .description {
    margin-top: 0.5rem;
    .caption1();
    color: @palette-color-neutrals-dark;
    min-height: 4.8rem;
  }

  .pictureWrapper {
    width: 90%;
    margin: .4rem auto 0 auto;

    .picture {
      width: 100%;
    }
  }

  &:hover {
    border-color: @palette-color-shades-navy-cloudy-blue;
  }

  &.selected {
    border-color: @palette-color-primary-blue;
  }
}
