@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  .contentWrapper {
    display: flex;
    flex-flow: column nowrap;

    .textContent {
      margin-bottom: 1.6rem;
      color: @palette-color-neutrals-dark;
      .bodyBase2Medium();
    }

    .ratesWrapper {
      text-align: center;

      .icon {
        .colorSvg(@palette-color-secondary-light-blue);
      }

      .rateLine {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        margin-bottom: .6rem;

        .details {
          display: flex;
          align-items: center;
          justify-self: flex-start;

          .tag {
            min-width: 5rem;
            padding: .4rem;
          }

          .chevronRight {
            .colorSvg(@palette-color-neutrals-grey1);
          }
          
          .dots {
            color: @palette-color-neutrals-dark;
            margin: 0 .8rem;
            .bodyBase2Bold();
          }
        }
  
        .actions {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
        }
      }
    }
  }
}
