.wrapper {
  margin-top: 3.2rem;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-end;

  .row {
    display: flex;
    flex-flow: row nowrap;

    .nextBtn {
      width: 10rem;
      justify-content: center;
      margin-left: 1rem;
    }
  }
}
