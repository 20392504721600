@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  .bodyBase1Medium();
  background-color: @palette-color-fill-sky;
  color: @palette-color-neutrals-grey1;
  text-align: center;
  padding: 0.5rem 1.5rem;
  display: flex;
  justify-content: center;
  height: 32px;

  a {
    color:@palette-color-neutrals-grey1;
    font-weight: bold;
    &:hover{
      text-decoration: underline;
    }
  }
}
.separator{
  display: block;
  margin-left: 18px;
  padding-right: 18px;
  border-left: 1px solid;
  border-color: @palette-color-neutrals-grey1;
}
.textLong{
  display: block;
}
.textShort{
  display: none;
}
@media screen and (max-width: 790px) {
  .textLong{
    display: none;
  }
  .textShort{
    display: block;
  }
}