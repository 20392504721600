@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  .endPeriodWrapper {
    margin-bottom: 2.8rem;
    position: relative;
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    .endPeriodInput {
      margin-bottom: 0;
      transition: none;
    }

    .removeEndPeriodBtn {
      position: absolute;
      top: 2.8rem;
      right: -4rem;
    }
  }

  .fiscalYearShiftWrapper {
    margin: 1rem 0 2rem;

    .fiscalYearShiftLabel {
      .inputLabel();
      color: @palette-color-neutrals-grey1;
    }

    .fiscalYearShiftInputWrapper {
      display: flex;
      flex-flow: row wrap;
      align-items: center;

      .inputLabel();
      color: @palette-color-neutrals-dark;

      .fiscalYearShiftFormItem {
        margin: 0 1rem;
      }

      .fiscalYearShiftFormInput {
        max-width: 8rem;
      }
    }

    .fiscalYearShiftHint {
      margin-top: 0.5rem;
      .inputLabel();
      color: @palette-color-neutrals-grey2;
    }
  }

  .beginEndDatesForDayTypeWrapper {
    display: flex;
    flex-direction: column;

    .beginDateFrequencyWrapper {
      display: flex;
      flex-flow: row wrap;
      align-items: flex-start;
      justify-content: flex-start;
    }

    .customBeginAtWrapper {
      margin-bottom: 1.8rem;

      &.onlyInput {
        margin-bottom: 2.8rem;
      }

      .customBeginAtInput {
        margin-bottom: 0;
        margin-right: 4rem;

        :global(.ant-form-item-label) {
          padding-bottom: 2.7rem;
        }
      }
    }

    .frequencyInput {
      width: 25rem;
      margin-bottom: 2.8rem;
    }

    &.beginPeriodOnly {
      .beginDateFrequencyWrapper {
        margin-bottom: 0;
      }
    }
  }

  .beginEndDatesWrapper {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: flex-start;

    &.beginPeriodOnly {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 1.8rem;

      .beginPeriodInput {
        margin-bottom: 0;
      }
    }

    .beginPeriodInput {
      transition: none;
      margin-right: 4rem;
      margin-bottom: 2.8rem;
    }

    .customBeginAtInput {
      margin-right: 4rem;
    }
  }

  .datePicker {
    width: 25rem;
  }
}
