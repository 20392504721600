@import '~@palette/styles/colors.less';

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;

  .leftSideHeader {
    width: 100%;
    display: flex;
    flex-direction: column;

    .title {
      color: @palette-color-neutrals-dark;
    }

    .addDataConnectionButton {
      margin-top: 1.6rem;
    }
  }

  .tabs {
    margin-top: 3.2rem;
    flex: 1;
  }
}
