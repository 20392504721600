@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  .titleActionWrapper {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
  }

  .noAccountWrapper {
    margin-top: 2rem;

    .noAccount {
      .bodyBase2Medium();
      color: @palette-color-neutrals-dark;
    }
  }

  .form {
    margin-top: 2rem;

    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    .formItem {
      width: 48%;
    }
  }

  .submitBtn {
    width: 100%;
    justify-content: center;
    margin: auto;
  }
}
