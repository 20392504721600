@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.popover {
  padding-top: 0;

  :global(.ant-popover-inner) {
    border-radius: 1rem;

    :global(.ant-popover-inner-content) {
      padding: 1rem 2.4rem;
    }
  }
}

.popoverContent {
  display: flex;

  .cellTypesWrapper {
    width: 22rem;
    background-color: @palette-color-fill-grey;
    padding: 1rem 2.4rem;

    + .cellTypesWrapper {
      margin-left: 1rem;
    }

    .cellTypesCategory {
      width: 100%;
      .bodyBase1Bold();
      color: @palette-color-neutrals-dark;
      text-align: center;
    }

    .cellTypesList {
      width: 100%;
      margin-top: 2.4rem;

      .cellType {
        .bodyBase2Medium();
        color: @palette-color-neutrals-grey1;
        cursor: pointer;
        padding: 0.5rem 1rem;
        border-radius: 1rem;

        + .cellType {
          margin-top: 1rem;
        }

        &:hover {
          color: @palette-color-primary-blue;
          background-color: @palette-color-fill-sky;
        }
      }
    }
  }
}

.wrapper {
  width: 100%;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    content: '';
    background: @palette-color-border-light;
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    width: 50%;
    height: 0.1rem;
    transform: translateY(-50%);
    z-index: 0;
  }

  &:after {
    content: '';
    background: @palette-color-border-light;
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    width: 50%;
    height: 0.1rem;
    transform: translateY(-50%);
    z-index: 0;
  }

  .iconWrapper {
    width: 4rem;
    height: 3.2rem;
    border-radius: 1rem;
    border: 0.1rem solid @palette-color-neutrals-grey2;
    background-color: @palette-color-neutrals-white;
    padding: 1.2rem 0.8rem;
    z-index: 1;

    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      .colorSvg(@palette-color-neutrals-grey2);
    }

    &:hover {
      .icon {
        .colorSvg(@palette-color-primary-blue);
      }
    }
  }
}
