.Landing_top_panel {
  background: #f3f3f3;

  .tagline {
    padding-top: 20px;
  }
  .tagline_hint {
    padding-bottom: 20px;
    font-size: 18px;
  }
}
.Landing_top_logo {
  max-width: 250px;
}
.Landing_influencer_list_wrapper {
  background: linear-gradient(150deg, #fb0154, #7070fd);
}
.Landing_arguments_wrapper {
  background: linear-gradient(150deg, #4ccac9 5%, #81f7f6 50%, #32e0df 85%);
  color: #443f4c;

  h1 {
    color: #443f4c;
  }

  p {
    font-size: 18px;
  }
  .Search_icon {
    font-size: 90px;
    margin-bottom: 20px;
  }
}
.List_top_global_wrapper{
  background: #f3f3f3;
}
.App_content_wrapper{
  max-width: 100%;
  flex-grow:1;
  min-width: 0
}

.Connectors_container{
  margin-top: 6px;
  margin-bottom: 6px;
  width: 100%;
  max-width: 612px;

  .Connectors_wrapper{
    margin-bottom: 20px;

    h3 {
      margin-left: 8px;
    }
  }

  .Connectors_list{
    display: flex;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 800px) {
  .flex,
  .flex-wrapper {
    flex-wrap: wrap;
  }
  .flex-reverse {
    flex-wrap: wrap-reverse;
  }
  .Header_left_wrapper,
  .Header_right_wrapper {
    flex-basis: 100%;
    padding: 0;
  }
  .Header_right_wrapper {
    margin-left: auto;
    margin-right: auto;
  }
  .Header_left_wrapper {
    margin-left: auto;
    margin-right: auto;
  }
  .Landing_influencer_list {
    flex-wrap: wrap;
  }

  .Landing_influencer_list .Landing_influencer_list_element {
    width: 310px;
  }

  .List_top_wrapper {
    flex-direction: column;
    align-items: center;
    text-align: center;

    .List_top_element {
      &.List_top_right {
        padding-left: 0px;
        padding-top: 20px;
      }
      &.List_top_left {
        border-right: none;
        padding-right: 0px;
        padding-bottom: 20px;
        border-bottom: 1px solid grey;
      }
    }
  }
}

// .ant-divider {
//   background: #c5c5c5 !important;
// }