@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.modal {
  :global(.ant-modal) {
    min-width: 65rem;
    max-width: 65rem;
  }
}

.description {
  .bodyBase1Medium();
  color: @palette-color-neutrals-dark;
}

.moveToRootSwitchWrapper {
  margin-top: 1.5rem;

  display: flex;
  flex-direction: row;
  align-items: center;

  .moveToRootSwitch {
    margin-right: 0.7rem;
    margin-bottom: 0;
  }

  .moveToRootLabel {
    .inputLabel();
    color: @palette-color-neutrals-dark;
  }
}

.teamSelector {
  margin-top: 1.5rem;

  width: 100%;
}
