@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';
@import '~@palette/styles/fonts.less';

.wrapper {
  height: 100%;

  .variables {
    height: calc(100% - 4rem);
    overflow: auto;

    .variable {
      padding: 1.7rem 0;
      border-bottom: 0.1rem solid @palette-color-border-light;

      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;

      .name {
        width: 17rem;

        .bodyBase2Medium();
        color: @palette-color-neutrals-grey1;
      }

      .value {
        margin-left: 1rem;
        max-width: calc(100% - 18rem);

        .bodyBase1Bold();
        color: @palette-color-neutrals-dark;
      }
    }
  }
}
