@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.withAddon {
  display: flex;
  flex-direction: row;
  align-items: center;

  position: relative;

  .clearIcon {
    margin-right: 1rem;
    margin-left: 1rem;
  }

  .wrapper {
    height: auto;
  }

  &.withAddonBefore {
    .addonBefore {
      position: absolute;
      top: 50%;
      left: 1.5rem;
      z-index: 1;
      transform: translateY(-50%);

      .inputValue();
      color: @palette-color-neutrals-grey1;
    }
  }

  &.withAddonAfter {
    .addonAfter {
      position: absolute;
      top: 50%;
      right: 1.5rem;

      transform: translateY(-50%);

      .inputValue();
      color: @palette-color-neutrals-grey1;
    }
  }
}

.clearIcon {
  .colorSvg(@palette-color-neutrals-grey2) !important;

  &:hover {
    .colorSvg(@palette-color-primary-blue) !important;
  }
}

.wrapper {
  display: flex;
  align-items: center;

  &:global(.ant-input-disabled) {
    color: @palette-color-neutrals-dark;
    border-color: @palette-color-border-normal;

    &:hover {
      border-color: @palette-color-border-normal;
      border-right-width: 0.2rem !important;
    }
  }
}

.wrapper, .wrapper:global(.ant-input-affix-wrapper) {
  padding: 0.8rem 1.6rem;
  border: 0.2rem solid @palette-color-border-normal;
  border-radius: 1.2rem;
  width: 100%;

  &:not(:global(.ant-input-number-disabled)):not(:global(.ant-input-disabled)) {
    background-color: transparent;
  }

  &.big {
    padding: 1.3rem 1.6rem;
  }

  .inputValue();
  color: @palette-color-neutrals-dark;

  &::placeholder {
    color: @palette-color-neutrals-grey2;
    border-right-width: 0.2rem !important;
  }

  &:hover {
    border-color: @palette-color-shades-navy-cloudy-blue;
    border-right-width: 0.2rem !important;
    box-shadow: none;
  }

  &:focus {
    border-color: @palette-color-primary-blue;
    border-right-width: 0.2rem !important;
    box-shadow: none;
  }

  :global(.ant-input-number-input-wrap) {
    width: 100%;
  }

  :global(.ant-input-number-input) {
    height: 2rem;
    padding: 0;
    border: 0;
    border-radius: 0;

    .inputValue();
    color: @palette-color-neutrals-dark;

    &::placeholder {
      color: @palette-color-neutrals-grey2;
      border-right-width: 0.2rem !important;
    }
  }

  &:global(.ant-input-number-focused) {
    border-color: @palette-color-primary-blue;
    border-right-width: 0.2rem !important;
    box-shadow: none;
  }

  &:global(.ant-input-affix-wrapper) {
    input {
      border-radius: 0;
      .inputValue();
      color: @palette-color-neutrals-dark;

      &::placeholder {
        color: @palette-color-neutrals-grey2;
      }
    }

    :global(.ant-input-suffix) {
      position: absolute;
      right: 0.8rem;
      top: 50%;
      transform: translateY(-50%);
      z-index: 2;
    }
  }

  &:global(.ant-input-number-disabled) {
    color: @palette-color-neutrals-dark;
    border-color: @palette-color-border-normal;

    &:hover {
      border-color: @palette-color-border-normal;
      border-right-width: 0.2rem !important;
    }
  }
}

.searchWrapper {
  width: 100%;
  padding-left: 0 !important;
  padding-right: 0 !important;

  :global(.ant-input-wrapper) {
    display: flex;
    align-items: center;
    position: relative;

    .bodyBase2Medium();
    color: @palette-color-neutrals-dark;

    :global(.ant-input-group-addon) {
      background-color: transparent;

      &:first-child {
        position: absolute;
        left: 0.8rem;
        z-index: 2;
        display: flex;
        align-items: center;
        border: 0;

        :global(.anticon) {
          .colorSvg(@palette-color-neutrals-dark);
        }
      }

      &:last-child {
        display: none;
      }
    }

    :global(.ant-input-affix-wrapper) {
      border: 0 !important;
      border-radius: 1.2rem !important;
      padding: 0 !important;

      .bodyBase2Medium();
      color: @palette-color-neutrals-dark;

      input {
        padding: 0.9rem 3.7rem 0.9rem 3.7rem;
        background-color: @palette-color-fill-grey;
        border: 0.2rem solid @palette-color-fill-grey;
        border-radius: 1.2rem !important;

        .bodyBase2Medium();
        color: @palette-color-neutrals-dark;

        &::placeholder {
          color: @palette-color-neutrals-grey2;
          border-right-width: 0.2rem !important;
        }

        &:hover {
          border-color: @palette-color-shades-navy-cloudy-blue;
          border-right-width: 0.2rem !important;
          box-shadow: none;
        }

        &:focus {
          border-color: @palette-color-primary-blue;
          border-right-width: 0.2rem !important;
          box-shadow: none;
        }
      }

      &:global(.ant-input-affix-wrapper-focused) {
        border-color: @palette-color-primary-blue;
        border-right-width: 0.2rem !important;
        box-shadow: none;
      }

      :global(.ant-input-suffix) {
        position: absolute;
        right: 0.8rem;
        top: 50%;
        transform: translateY(-50%);
        z-index: 2;
      }
    }
  }

  &.themeWhite {
    :global(.ant-input-wrapper) {
      :global(.ant-input-affix-wrapper) {
        input {
          background-color: @palette-color-neutrals-white;
        }
      }
    }
  }
}

.radioWrapper {
  display: flex;
  flex-direction: row;

  &.alignColumns {
    flex-direction: column;
  }

  &:global(.ant-radio-group) {
    padding-left: .4rem;

    :global(.ant-radio-wrapper) {
      padding: .5rem 0;
      .inputValue();

      &:hover {
        :global(.ant-radio-inner) {
          border-color: @palette-color-primary-blue;
        }
      }
    }

    :global(.ant-radio-input:focus) {
      :global(.ant-radio-inner) {
        box-shadow: 0 0 0 .3rem @palette-color-shades-navy-sky-blue;
      }
    }

    :global(.ant-radio-checked) {
      :global(.ant-radio-inner) {
        border-color: @palette-color-primary-blue;
      }
    }

    :global(.ant-radio-inner) {
      border-color: @palette-color-border-normal;

      &::after {
        background-color: @palette-color-primary-blue;
      }
    }

    &::after {
      border: .1rem solid @palette-color-primary-blue;
    }
  }
}
