@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  .bracket {
    margin-top: 1.8rem;

    &:first-child {
      margin-top: 0;
    }

    .tierLabel {
      .title2Medium();
      color: @palette-color-neutrals-dark;

      margin-bottom: 1rem;
    }

    .detailWrapper {
      margin-top: 1.4rem;
      display: flex;
      flex-direction: row;
      align-items: center;

      &:first-child {
        margin-top: 0;
      }

      .detailTitle {
        .bodyBase2Bold();
        color: @palette-color-neutrals-dark;
      }

      .detailValue {
        margin-left: 0.6rem;

        .bodyBase2Medium();
        color: @palette-color-neutrals-grey1;
      }

      &.detailFormulaWrapper {
        flex-direction: column;
        align-items: flex-start;

        .detailValue {
          margin-left: 0;
          margin-top: 0.5rem;
        }
      }
    }
  }
}
