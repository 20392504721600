@import '~@palette/styles/colors.less';

.wrapper {
  .inputTable + .inputTable {
    margin-top: 2.4rem;
  }

  .newInputTable {
    margin-top: 2.4rem;

    width: 100%;
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    &:before {
      content: '';
      background: @palette-color-border-light;
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      width: 50%;
      height: 0.1rem;
      transform: translateY(-50%);
      z-index: 0;
    }

    &:after {
      content: '';
      background: @palette-color-border-light;
      display: block;
      position: absolute;
      top: 50%;
      right: 0;
      width: 50%;
      height: 0.1rem;
      transform: translateY(-50%);
      z-index: 0;
    }

    .iconWrapper {
      width: 4rem;
      height: 3.2rem;
      border-radius: 1rem;
      border: 0.1rem solid @palette-color-neutrals-grey2;
      background-color: @palette-color-neutrals-white;
      padding: 1.2rem 0.8rem;
      z-index: 1;

      cursor: pointer;

      display: flex;
      align-items: center;
      justify-content: center;

      .icon {
        .colorSvg(@palette-color-neutrals-grey2);
      }

      &:hover {
        .icon {
          .colorSvg(@palette-color-primary-blue);
        }
      }
    }
  }
}
