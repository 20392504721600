.wrapper {
  width: 100%;

  .emptyStateWrapper {
    margin-top: 4rem;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .pagination {
    margin-top: 4.4rem;
  }
}
