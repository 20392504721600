@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';
@import '~@palette/styles/palette-theme.less';

.wrapper {
  :global(.ant-drawer-content-wrapper) {
    width: 100% !important;

    @media(min-width: @screen-md-min) {
      width: 60rem !important;
    }
  }

  :global(.ant-drawer-content) {
    :global(.ant-drawer-header) {
      padding: 4.6rem 4rem 0 4rem;
      border-bottom: none;

      :global(.ant-drawer-header-title) {
        flex-direction: row-reverse;

        :global(.ant-drawer-close) {
          :global(.ant-modal-close-x) {
            width: 2.8rem;
            height: 2.8rem;
            line-height: 2.8rem;
          }

          :global(.anticon) {
            .colorSvg(@palette-color-neutrals-grey1);
          }

          &:hover {
            :global(.anticon) {
              .colorSvg(@palette-color-neutrals-grey3);
            }
          }
        }

        :global(.ant-drawer-title) {
          .headlineH2-2();
          color: @palette-color-neutrals-dark;
        }
      }
    }

    :global(.ant-drawer-body) {
      padding: 2.6rem 4rem 5rem 4rem;
    }
  }

  .content {
    height: 100%;

    .commissionDetails {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .commissionValueAndDealValue {
      border: 0.1rem solid @palette-color-border-light;
      border-radius: 1.8rem;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-top: 0;

      padding: 2rem 0;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .commissionValue, .commissionDealValue {
        width: 50%;
      }
    }

    .commissionDrawerTabs {
      margin-top: 2.6rem;
    }
  }
}
