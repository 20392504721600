@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.modal {
  :global(.ant-modal) {
    max-width: 60rem;
  }

  .titleWrapper {
    .headlineH2-2();
    color: @palette-color-neutrals-dark;
  }

  .table {
    width: 98%;
    margin: 0 auto;

    .detailsCell {
      padding: 1rem 1.4rem;
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      align-items: flex-end;
      color: @palette-color-neutrals-grey1;
      .caption1();

      span {
        margin-right: .6rem;
        color: @palette-color-neutrals-dark;
        .bodyBase2Bold();
      }
    }
  }
}
