@import '~@palette/styles/colors.less';
@import '~@palette/styles/mixins.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  .summary {
    display: flex;
    flex-direction: column;

    .title {
      .title2Bold();
      color: @palette-color-neutrals-dark;
      margin-bottom: 1.6rem;
    }

    .subtitle {
      color: @palette-color-neutrals-dark;
      .bodyBase1Bold();
      margin-bottom: .8rem;
    }

    .value {
      color: @palette-color-neutrals-grey1;
      .code();
      .ellipsis();
      margin-bottom: 1.6rem;
    }
  }
}
