@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  position: relative;
  cursor: pointer;

  .yearPickerWrapper {
    .yearPicker {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      padding: 0;
      border: 0;
      background: transparent !important;
      z-index: 1;

      > :global(.ant-picker-input) {
        visibility: hidden;
      }
    }
  }
}
