@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  position: relative;
  padding: 0 4rem 4rem;

  display: flex;
  flex-flow: row wrap;

  .subTeamsWrapper {
    flex: 1;

    margin: 1rem;

    .subTeamsLabelActionsWrapper {
      margin-left: 1rem;

      display: flex;
      flex-direction: row;
      align-items: center;

      .subTeamsLabel {
        min-width: fit-content;

        margin-left: 1rem;

        .title2Bold();
        color: @palette-color-neutrals-dark;
      }

      .newTeamBtn {
        margin-left: 2rem;
      }
    }

    .subTeamsList {
      margin-top: 1rem;
    }
  }

  .membersWrapper {
    flex: 1;

    margin: 1rem;

    .membersLabelActionsWrapper {
      margin-left: 1rem;

      display: flex;
      flex-direction: row;
      align-items: center;

      .membersLabel {
        min-width: fit-content;

        .title2Bold();
        color: @palette-color-neutrals-dark;
      }

      .addMembersToTeamBtn {
        margin-left: 2rem;
      }
    }

    .membersList {
      margin-top: 1rem;
    }
  }
}
