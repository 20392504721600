@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  margin-left: 1rem;
  width: 2.4rem;
  height: 2.4rem;
  border: .1rem solid @palette-color-neutrals-dark;
  border-radius: 50%;
  background-color: @palette-color-secondary-light-teal;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: .2rem;

  .counter {
    color: @palette-color-neutrals-white;
    .caption1();
  }
}
