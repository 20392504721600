@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';
@import '~@palette/styles/palette-theme.less';

.wrapper {
  margin-bottom: 1.6rem;

  .collapse {
    border-radius: @border-radius-base;
    border-width: .1rem;
    border-color: @palette-color-border-normal;

    :global(.ant-collapse-item) {
      :global(.ant-collapse-header) {
        padding: 1rem 2rem;
      }
    }

    .actionButton {
      width: auto;
      margin: 0;
      margin-left: 1rem;

      .actionIcon {
        .colorSvg(@palette-color-primary-blue);
      }

      .actionTrashIcon {
        .colorSvg(@palette-color-secondary-red);
      }
    }

    .ruleTitle {
      .title1Bold();
      color: @palette-color-neutrals-dark;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;

      .actionButton {
        margin: 0 .4rem;

        &:first-of-type {
          margin-left: 1rem;
        }
      }
    }

    .sectionItem {
      margin-bottom: .5rem;
      display: flex;
      flex-flow: column nowrap;

      &:not(:last-child) {
        margin-bottom: 2rem;
        padding-bottom: 2rem;
        border-bottom: .1rem solid @palette-color-border-light;
      }

      .fieldTitle {
        .title2Bold();
        color: @palette-color-neutrals-dark;
        margin-bottom: .8rem;

        &.withExtra {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: space-between;

          .actionButton {
            padding-bottom: .6rem;
          }
        }
      }

      .fieldSubtitle {
        .bodyBase1Medium();
        color: @palette-color-neutrals-grey1;
        margin-bottom: 1.6rem;
      }

      .fieldDisclaimer {
        .caption1();
        color: @palette-color-neutrals-grey2;
        margin-top: 1rem;
      }

      .inputFieldError {
        .bodyBase2Medium();
        color: @palette-color-secondary-red;
        margin-top: 0.5rem;
      }
    }
  }
}
