@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  border-top: 0.1rem solid @palette-color-border-normal;
  padding-top: 4rem;

  .section {
    margin-bottom: 2rem;

    .sectionTitle {
      .headlineH2();
      color: @palette-color-neutrals-dark;
    }

    .sectionContent {
      margin-top: 1rem;

      .link {
        display: block;
        margin-top: 0.5rem;
      }
    }
  }

  .userDetailsDiffTable {
    position: relative;

    .emptyState {
      position: absolute;
      top: 5rem;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
