.wrapper {
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: .8rem;

    .infos {
      flex: 1;
      display: flex;
      flex-flow: column nowrap;
      margin-right: 4rem;

      .title {
        margin-bottom: .8rem;
      }
    }

    .actions {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      min-height: 4rem;

      .removeDashboardButton {
        margin-left: .6rem;
      }
    }
  }

  .content {
    flex: 1;
  }

  .loaderContainer {
    padding: 2rem;
  }
}
