.wrapper {
  overflow-x: hidden;

  .selectorBlocks {
    display: flex;
    overflow-x: auto;

    .selectorBlock {
      margin: 0.8rem;
      min-width: 30rem;
      width: 30rem;
      height: 38rem;
    }
  }

  .preview {
    margin-top: 2.4rem;
  }
}
