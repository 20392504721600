@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  padding: 3.2rem;
  border: 0.1rem solid @palette-color-border-light;
  border-radius: 1.6rem;

  .collapse {
    :global(.ant-collapse-item) {
      :global(.ant-collapse-header) {
        height: auto;
      }
    }
  }

  .header {
    width: 100%;

    .title {
      color: @palette-color-neutrals-dark;
      .headlineH2();
    }

    .subtitle {
      margin-top: 0.4rem;
      color: @palette-color-neutrals-grey1;
      .bodyBase2Medium();
    }
  }

  .content {
    margin-top: 2.4rem;

    .resultsBlock {
      margin-top: 3.2rem;

      &::before {
        content: '';
        display: block;
        width: 100%;
        height: 0.1rem;
        background-color: @palette-color-border-light;
        margin-bottom: 3.2rem;
      }
    }
  }
}
