@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 0 2rem;

  .noPeriodsInPlan {
    width: 100%;
    margin-top: 0.8rem;

    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .select {
    width: 100%;
    margin-top: 0.8rem;

    text-align: center;
  }

  .planName {
    margin-top: 2.5rem;
    .title2Bold();
    color: @palette-color-primary-blue;
  }

  .periodUsersCount {
    margin-top: 1.2rem;

    display: flex;
    flex-direction: row;
    align-items: center;

    .periodName {
      .title2Medium();
      color: @palette-color-neutrals-dark;
    }

    .usersCountWrapper {
      margin-left: 1rem;

      display: flex;
      flex-direction: row;
      align-items: center;

      .usersCount {
        .bodyBase2Medium();
        color: @palette-color-neutrals-grey1;
      }

      .usersCountIcon {
        margin-left: 0.4rem;
        .colorSvg(@palette-color-neutrals-grey2);
      }
    }
  }

  .progressDetailsWrapper {
    margin-top: 1.2rem;
    align-items: center;
  }

  .previewProjectionWrapper {
    margin-top: 1.2rem;
    width: 100%;

    .projectionChartWrapper {
      margin-top: 1.2rem;
    }
  }
}
