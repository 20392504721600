.wrapper {
  height: 100%;
  width: 100%;

  .header {
    padding: 1.1rem 0 2.4rem;
    width: 100%;
  }

  .main {
    padding: .4rem 0 1rem;
  }
}
