@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  display: flex;
  flex-flow: column nowrap;

  .intro {
    .bodyBase1Medium();
    color: @palette-color-neutrals-dark;
    margin-bottom: .6rem;
  }

  .content {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    min-height: 2.4rem;

    .value {
      color: @palette-color-neutrals-dark;
      .bodyBase2Bold();
    }

    .button {
      margin: 0;
      margin-left: 1rem;
      padding: .4rem 0;
      height: auto;
      width: auto;

      &.historyButton {
        .icon {
          .colorSvg(@palette-color-neutrals-grey2);
        }

        &:hover {
          .icon {
            .colorSvg(@palette-color-primary-blue);
          }
        }
      }
    }
  }

  .subcontent {
    color: @palette-color-neutrals-dark;
    margin-top: .6rem;
    .caption1();
  }
}
