@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 4.2rem;

  .deactivatedUserWrapper {
    display: flex;
    align-items: center;

    .deactivatedUserIcon {
      .colorSvg(@palette-color-secondary-red);
      min-width: 1.8rem;
    }

    .deactivatedUserLabel {
      margin-left: 1rem;
    }
  }

  .inviteLink button span:last-child {
    margin-left: 1rem;
  }

  .pendingInvitationWrapper {
    display: flex;
    flex-direction: column;

    .pendingInvitationContainer {
      display: flex;
      align-items: center;
      margin-bottom: .6rem;

      .pendingInvitationIcon {
        .colorSvg(@palette-color-secondary-brown-yellow);
        min-width: 1.8rem;
      }
  
      .pendingInvitationLabel {
        margin-left: 1rem;
      }
    }

    .resendInviteLink button {
      height: auto;
      padding: 0;

      span:last-child {
        margin-left: 1rem;
      }
    }
  }

  .connectedUserWrapper {
    display: flex;
    align-items: center;

    .connectedUserIcon {
      .colorSvg(@palette-color-primary-teal);
      min-width: 1.8rem;
    }

    .connectedUserLabel {
      margin-left: 1rem;
    }
  }
}
