@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  height: 100%;
  width: 100%;

  .titleWrapper {
    flex-flow: row wrap;
    justify-content: space-between;

    .titlesContainer {
      display: flex;
      flex-direction: column;
      margin-right: 1.6rem;
      margin-bottom: 1.6rem;
    }

    .actions {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 1.6rem;

      .onboardingUsersBtn {
        margin-left: 1.6rem;
      }
  
      .mergeUsersLink {
        margin-left: auto;
    
        display: flex;
        flex-direction: row;
        align-items: center;
    
        .mergeUsersLinkIcon {
          .colorSvg(@palette-color-neutrals-grey1);
        }
    
        .mergeUsersLinkLabel {
          margin-left: 0.4rem;
          .bodyBase2Medium();
          color: @palette-color-neutrals-grey1;
        }
    
        &:hover {
          .mergeUsersLinkIcon {
            .colorSvg(@palette-color-primary-blue);
          }
    
          .mergeUsersLinkLabel {
            color: @palette-color-primary-blue;
          }
        }
      }
    }
  }
}
