.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .warning {
    margin-bottom: 2rem;
  }

  .connectorsSelect {
    flex: 0 0 auto;

    margin-bottom: 2rem;
    width: 100%;
  }

  .filters {
    flex: 0 0 auto;

    margin-bottom: 2rem;
  }

  .table {
    width: 100%;
    flex: 1;
    position: relative;
    max-height: calc(100% - 14rem);

    .emptyState {
      position: absolute;
      top: 5rem;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
