@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  padding: 2.1rem 1.4rem;
  width: 100%;

  .label {
    margin-bottom: 0.6rem;

    .bodyBase2Medium();
    color: @palette-color-neutrals-grey2;
  }
}
