@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';
@import '~@palette/styles/mixins.less';
@import '~@palette/styles/palette-theme.less';

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 2rem 1.5rem;
  border: 0.1rem solid @palette-color-border-light;
  border-radius: 1.6rem;

  width: 49rem;

  .icon {
    .colorSvg(@palette-color-primary-blue);
  }

  .name {
    margin-left: 1.5rem;

    width: 25rem;

    .bodyBase1Bold();

    .ellipsis();
  }

  .usersCount {
    margin-left: auto;
  }

  .moreMenuWrapper {
    margin-left: 1rem;
    width: 2.4rem;

    &.moreMenuIsOpen {
      .moreMenu {
        display: block;
      }
    }
  }

  @media(min-width: @screen-lg-min) {
    .moreMenuWrapper {
      .moreMenu {
        display: none;
      }
    }

    &:hover {
      border-color: @palette-color-shades-navy-sky-blue;

      .moreMenuWrapper {
        .moreMenu {
          display: block;
        }
      }
    }
  }
}
