@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  .yearSelectorWrapper {
    margin-top: 3.2rem;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .yearLabel {
      margin-left: 0.75rem;

      .headlineH2();
      color: @palette-color-neutrals-dark;
    }

    .yearPicker {
      margin-left: 0.6rem;
      padding: 0;
      border: 0;

      input {
        .headlineH2();
        color: @palette-color-primary-blue;
      }

      :global(.ant-picker-suffix) {
        span {
          .colorSvg(@palette-color-primary-blue);
        }
      }
    }
  }

  .quotas {
    .quotaEdition {
      &:first-child {
        margin-top: 3.2rem;
      }

      &.dayQuotaEdition {
        margin-bottom: 3rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
