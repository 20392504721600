@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  display: flex;
  flex-flow: row wrap;
  align-items: center;

  .linkWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: .5rem;

    .link {
      display: flex;
      flex-direction: row;
      align-items: center;

      .linkIcon {
        .colorSvg(@palette-color-neutrals-grey1);
      }

      .linkLabel {
        margin-left: 0.4rem;

        .bodyBase2Medium();
        color: @palette-color-neutrals-grey1;

        .periodName {
          margin-right: .4rem;

          .bodyBase2Bold();
        }
      }

      &:hover {
        .linkIcon {
          .colorSvg(@palette-color-primary-blue);
        }

        .linkLabel {
          color: @palette-color-primary-blue;
        }
      }
    }

    .separator {
      margin: 0 1.5rem;

      .colorSvg(@palette-color-neutrals-grey2);
    }
  }

  .currentLocationWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: .5rem;

    .currentLocationIcon {
      .colorSvg(@palette-color-neutrals-dark);
    }

    .currentLocationLabel {
      margin-left: 0.4rem;

      .bodyBase2Medium();
      color: @palette-color-neutrals-dark;

      .periodName {
        margin-right: .4rem;

        .bodyBase2Bold();
      }
    }
  }
}
