@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  min-height: 2.5rem;

  .avatarItemWrapper {
    &:not(:first-child) {
      margin-left: 1rem;
    }
  }

  .emptyState {
    color: @palette-color-neutrals-grey2;
    .caption1Italic();
  }
}
