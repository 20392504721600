@import '~@palette/styles/typographies.less';
@import '~@palette/styles/colors.less';
@import '~@palette/styles/mixins.less';

.wrapper {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: flex-end;
  .ellipsis();

  .editedAt {
    .ellipsis();
    .bodyBase1Medium();
    color: @palette-color-neutrals-grey1;
  }

  .buttons {
    display: flex;
    flex-flow: row nowrap;
    margin-left: 2rem;

    .actionButton {
      justify-content: center;
      padding: 0;
      height: auto;

      .actionIcon {
        .colorSvg(@palette-color-neutrals-dark);
      }

      &:hover {
        .actionIcon {
          .colorSvg(@palette-color-primary-blue);
        }
      }
    }
  }
}