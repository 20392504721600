@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  .quotaTableWrapper {
    .quotaInfosWithActions {
      margin-top: 2.8rem;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .actions {
        display: flex;
        flex-direction: row;
        align-items: center;

        .action {
          margin-left: 2rem;

          &:first-child {
            margin-left: 0;
          }

          + .action {
            margin-left: 2rem;
          }
        }
      }
    }

    .quotaTable {
      margin-top: 1.5rem;

      .customQuotaWrapper {
        .customQuotaTargetLabel {
          .bodyBase1Bold();
          color: @palette-color-neutrals-dark;
        }

        .customTeamQuotaWrapper {
          margin-top: 0.5rem;

          display: flex;
          flex-direction: row;
          align-items: center;

          .customQuotaValue {
            margin-right: 0.8rem;

            .bodyBase2MediumItalic();
            color: @palette-color-neutrals-dark;
          }

          .customQuotaValueWrapper {
            display: flex;
            flex-direction: row;
            align-items: center;

            .customQuotaValueType {
              .bodyBase2Bold();
              color: @palette-color-neutrals-dark;
            }

            .customQuotaValue {
              margin-left: 0.8rem;
              margin-right: 0;

              .bodyBase2MediumItalic();
              color: @palette-color-neutrals-dark;
            }
          }

          .customTeamQuotaInput {
            width: 15rem;
          }

          .actionButton {
            &:first-of-type {
              margin-left: 1rem;
            }

            .actionTrashIcon {
              .colorSvg(@palette-color-secondary-red);
            }
          }
        }
      }
    }
  }
}
