@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.modal {
  :global(.ant-modal) {
    min-width: 65rem;
    max-width: 65rem;
  }
}

.hireDatePicker {
  width: 100%;

  :global(.ant-picker-clear) {
    right: 4rem;
    :global(.anticon) {
      .colorSvg(@palette-color-neutrals-grey2);
    }

    &:hover {
      :global(.anticon) {
        .colorSvg(@palette-color-secondary-red);
      }
    }
  }
}

.teamSelector {
  width: 100%;
}
