@import '~@palette/styles/colors.less';

.wrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  .searchContainer {
    margin-bottom: 1rem;

    .inputSearch {
      width: 22rem;
      min-width: 22rem;
    }
  }

  .filterButton {
    min-width: 3.8rem;
    margin: 0 0 1rem 1rem;

    .filterIcon {
      .colorSvg(@palette-color-neutrals-grey2);

      &.white {
        .colorSvg(@palette-color-neutrals-white);
      }
    }
  }
}
