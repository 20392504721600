@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  .notUsed {
    .bodyBase2Medium();
    color: @palette-color-neutrals-grey1;
  }

  .buttonContainer {
    padding: 0;
    height: auto;
    min-height: 2.2rem;
  }
}
