@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';
@import '~@palette/styles/mixins.less';

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  .nameContainer {
    width: calc(100% - 2.9rem);
    display: flex;
    align-items: center;
  }

  .name {
    margin-left: 0.5rem;
    .bodyBase2Medium();
    color: @palette-color-neutrals-grey1;

    .ellipsis();

    &.link {
      text-decoration: none;

      &:hover {
        .bodyBase2Bold();
        color: @palette-color-primary-blue;
        text-decoration: none;
      }
    }

    &.icon {
      .bodyBase2Bold();
      color: @palette-color-primary-blue;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &.clickable {
    &:hover {
      cursor: pointer;

      .name {
        .bodyBase2Bold();
        color: @palette-color-primary-blue;
      }
    }
  }

  &.vertical {
    flex-direction: column;
    justify-content: center;

    .nameContainer {
      justify-content: center;
    }

    .name {
      text-align: center;
      width: auto;
      margin-left: 0;
      margin-top: 1.2rem;
      .headlineH3();
      color: @palette-color-neutrals-dark;
      white-space: break-spaces;
      overflow: visible;

      &.link {
        text-decoration: none;

        &:hover {
          .headlineH3();
          color: @palette-color-primary-blue;
          text-decoration: none;
        }
      }
    }

    &.clickable {
      &:hover {
        .name {
          .headlineH3();
          color: @palette-color-primary-blue;
        }
      }
    }
  }
}
