@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.clearIconWrapper {
  border: 0 !important;
  border-radius: 0 !important;
}

.wrapper {
  height: 4.4rem;
  padding: 1.3rem 1.4rem !important;
  border: 0 solid transparent;
  border-radius: 0;
  .bodyBase2Medium();
  color: @palette-color-neutrals-grey1;

  &::placeholder {
    color: @palette-color-neutrals-grey2;
    border-right-width: 0 !important;
  }

  &:hover {
    border-color: @palette-color-neutrals-grey2;
    border-right-width: 0 !important;
  }

  &:focus {
    border-color: @palette-color-primary-blue;
    border-right-width: 0 !important;
  }

  :global(.ant-input-number-input) {
    height: 1.8rem;

    .bodyBase2Medium();
    color: @palette-color-neutrals-grey1;

    &::placeholder {
      color: @palette-color-neutrals-grey2;
      border-right-width: 0 !important;
    }
  }

  :global(.ant-input-number-input-wrap) {
    height: 1.8rem;
  }

  :global(.ant-input-number-handler-wrap) {
    opacity: 0;
  }

  &:global(.ant-input-number-focused) {
    border-color: @palette-color-primary-blue;
    border-right-width: 0 !important;

    :global(.ant-input-number-input) {
      .bodyBase2Bold();
      color: @palette-color-neutrals-dark;
    }

    :global(.ant-input-number-handler-wrap) {
      opacity: 1;
    }
  }
}
