@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1.3rem 1.2rem 1.3rem 1.4rem;
  width: 100%;

  .label {
    .bodyBase2Medium();
    color: @palette-color-neutrals-dark;
  }
}