@import '~@palette/styles/typographies.less';
@import '~@palette/styles/colors.less';
@import '~@palette/styles/mixins.less';

.wrapper {
  background-color: @palette-color-neutrals-white;
  border: 0.1rem solid @palette-color-border-light;
  border-radius: 1.6rem;

  :global(.ant-collapse-item) {
    border: none;

    :global(.ant-collapse-header) {
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;

      padding: 1.5rem 2rem;

      > div:first-child {
        display: flex;
      }

      .icon {
        .colorSvg(@palette-color-neutrals-dark);

        margin-right: 0;
        transition: transform .24s;

        &.down, &.up {
          transition: transform .36s;
        }

        &.isActive {
          transform: rotate(90deg);

          &.down {
            transform: rotate(-180deg);
          }
          &.up {
            transform: rotate(180deg);
          }
        }
      }
    }

    :global(.ant-collapse-extra) {
      display: flex;
      flex: 1;
      order: -1;
      justify-content: flex-end;
      .ellipsis();
    }

    :global(.ant-collapse-content) {
      margin-top: 0;
      border: none;
      border-top: 0.1rem solid @palette-color-border-light;

      :global(.ant-collapse-content-box) {
        padding: 1.5rem 2rem;
      }
    }
  }

  &.big {
    border: 0.2rem solid @palette-color-border-normal;
    border-radius: 1.2rem;

    :global(.ant-collapse-item) {
      :global(.ant-collapse-header) {
        padding: 1.6rem 2.4rem;
      }

      :global(.ant-collapse-content) {
        border-top: 0.1rem solid @palette-color-border-normal;

        :global(.ant-collapse-content-box) {
          padding: 3.2rem 2.4rem;
        }
      }
    }
  }

  &:global(.ant-collapse-ghost) {
    background-color: transparent;
    border: none;

    :global(.ant-collapse-item) {
      :global(.ant-collapse-header) {
        padding: 0;
        justify-content: flex-end;
        height: 2rem;

        .bodyBase2Bold();
        color: @palette-color-neutrals-grey1;

        .icon {
          .colorSvg(@palette-color-neutrals-grey1);
        }

        > div:first-child {
          align-items: center;
        }
      }

      :global(.ant-collapse-content) {
        margin-top: 1.4rem;
        border-top: none;

        :global(.ant-collapse-content-box) {
          padding: 0;
        }
      }
    }
  }
}
