@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;

  &:not(:last-child) {
    border-bottom: .1rem solid @palette-color-border-light;
  }

  .infosWrapper {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    margin-right: 2rem;
    flex: 3;

    .nameWrapper {
      margin-bottom: .6rem;
    }

    .peopleAndCommissions {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;

      .usersCountWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;

        .usersCount {
          .bodyBase2Medium();
          color: @palette-color-neutrals-grey1;
        }

        .usersCountIcon {
          margin-left: 0.4rem;
          .colorSvg(@palette-color-neutrals-grey2);
        }
      }

      .commissionWrapper {
        margin-left: 3rem;
        display: flex;
        flex-direction: row;
        align-items: center;

        .commissionLabel {
          .bodyBase2Medium();
          color: @palette-color-neutrals-grey2;
          white-space: nowrap;
        }

        .commissionValue {
          .bodyBase1Bold();
          color: @palette-color-neutrals-dark;
          margin-left: 1rem;
        }
      }
    }
  }

  .progressActionsWrapper {
    display: flex;

    .actionsWrapper {
      margin-left: 1rem;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      min-width: 10rem;
    }
  }
}
