@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.loader {
  height: 26rem;
}

.wrapper {
  &.forceHeight {
    height: 26rem;
  }

  .previewHeader {
    width: 100%;
    padding: 0.8rem;

    .code();
    color: @palette-color-neutrals-grey1;
    background-color: @palette-color-fill-grey;
  }

  .previewCell {
    width: 100%;
    padding: 0.8rem;

    .code();
    color: @palette-color-neutrals-dark;
  }
}
