@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  padding-bottom: 1.6rem;
  border-bottom: 0.1rem solid @palette-color-border-normal;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .dependencyInfosWrapper {
    flex: 1;

    .dependencyName {
      .title1Bold();
      color: @palette-color-neutrals-dark;
    }

    .planName {
      margin-top: 0.8rem;

      .inputLabel();
      color: @palette-color-neutrals-dark;
    }

    .strategyDescription {
      margin-top: 0.8rem;

      .inputLabel();
      color: @palette-color-neutrals-dark;
    }

    .options {
      margin-top: 0.8rem;

      .inputLabel();
      color: @palette-color-neutrals-dark;
      white-space: break-spaces;
    }
  }

  .editBtn {
    margin-left: 2rem;
  }
}
