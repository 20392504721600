@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.content {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;

  .section {
    display: flex;
    flex-flow: column nowrap;
    flex: none;
    width: 100%;

    &:not(:last-child) {
      border-bottom: .1rem solid @palette-color-fill-table-hover;
      padding-bottom: 2rem;
      margin-bottom: 4rem;
    }

    .title {
      .title2Bold();
      color: @palette-color-neutrals-dark;
      margin-bottom: 2rem;
    }
  }
}
