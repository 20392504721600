@import '~@palette/styles/colors.less';

.wrapper {
  .editButton {
    display: flex;
    align-items: center;
    justify-content: center;
    color: @palette-color-neutrals-grey1;

    .editIcon {
      margin-right: .4rem;
      .colorSvg(@palette-color-neutrals-grey2);
    }

    &:hover {
      color: @palette-color-primary-blue;

      .editIcon {
        .colorSvg(@palette-color-primary-blue);
      }
    }
  }

  .viewButton {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3rem;

    .viewIcon {
      margin-right: .4rem;
      .colorSvg(@palette-color-primary-blue);
    }
  }

  .pagination {
    margin-top: 4.4rem;
  }
}
