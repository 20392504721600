.wrapper {
  height: 100%;
  width: 100%;
  padding-top: 4rem;

  .breadcrumb {
    margin-left: 4rem;
  }

  .title {
    padding: 3.3rem 4rem 2.2rem 4rem;
  }
}
