.modal {
  :global(.ant-modal) {
    min-width: 65rem;
    width: 90vw !important;
    max-width: 90vw;
  }

  .leftSideContentWrapper {
    padding: 0 1.2rem 2.4rem 2.4rem !important;
  }

  .mainWrapper {
    overflow: auto;
  }

  .mainContentWrapper {
    padding: 0 1.2rem 2.4rem 1.2rem !important;
  }
}
