@import '~@palette/styles/colors.less';

.wrapper {
  max-width: 88rem;
  flex: 1;
  max-height: 100%;

  .warningIcon {
    .colorSvg(@palette-color-secondary-brown-yellow);
  }

  .usersTableWrapper {
    height: calc(100% - 14.2rem);
  }
}
