@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;

  .actionDeleteLink {
    color: @palette-color-secondary-red;
    height: auto;
    padding: 0;
    margin-top: 3.5rem;
    width: auto;

    &.disabled {
      .actionIcon {
        .colorSvg(@palette-color-neutrals-grey2);
      }

      &:hover {
        .actionIcon {
          .colorSvg(@palette-color-neutrals-grey2);
        }
      }
    }

    .actionIcon {
      .colorSvg(@palette-color-secondary-red);
    }

    &:hover {
      color: @palette-color-secondary-red;

      .actionIcon {
        .colorSvg(@palette-color-secondary-red);
      }
    }
  }

  .inputArea {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;

    &:not(.isLastArea) {
      margin-bottom: 1rem;
    }

    .formItem {
      margin-bottom: 0;
      flex: 1.5;

      &:not(:nth-last-child(2)) {
        flex: .5;
      }

      &:not(:last-child) {
        margin-right: 1rem;
      }

      .inputField {
        &:not(.disabled) {
          background-color: @palette-color-neutrals-white;
        }
      }

      .inputFieldError {
        .bodyBase2Medium();
        color: @palette-color-secondary-red;
        margin-top: 0.5rem;
      }
    }
  }
}