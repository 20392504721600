@import '~@palette/styles/colors.less';

.wrapper {
  display: flex;
  flex-direction: column;

  .planItem {
    &:not(:last-child) {
      border-bottom: .1rem solid @palette-color-border-light;
    }
  }
}
