@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  padding: 1.3rem 1.2rem 1.3rem 1.4rem;
  width: 100%;

  .label {
    .bodyBase2Medium();
    color: @palette-color-neutrals-dark;
  }

  .type {
    .caption1();
    color: @palette-color-neutrals-grey2;
  }
}
