@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  .fieldContainer {
    .bodyBase1Medium();
    color: @palette-color-neutrals-dark;
    min-height: 4rem;

    .field {
      white-space: pre-wrap;

      &.title {
        .headlineH1();
      }
    }

    &:hover {
      cursor: pointer;
      background-color: @palette-color-fill-sky;
    }

    .emptyText {
      color: @palette-color-neutrals-grey2;
    }
  }

  .fieldInput {
    margin-top: -1rem;
    margin-bottom: -1rem;
    margin-left: -1.8rem;
    width: calc(100% + 3.6rem);

    &.textarea {
      .bodyBase1Medium();
      color: @palette-color-neutrals-dark;
    
      &:global(.ant-input) {
        .bodyBase1Medium();
        max-width: none;
      }
    }
    
    &.input {
      .headlineH1();
      color: @palette-color-neutrals-dark;
      padding: .4rem 1.6rem;
      line-height: 4.8rem;
    }
  }
}
