@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  .form {
    display: flex;
    flex-flow: column nowrap;

    .content {
      margin-bottom: .4rem;

      .title {
        .title1Bold();
        color: @palette-color-neutrals-dark;
        margin-bottom: 1.6rem;
      
        &:first-child {
          margin-top: 2.6rem;
        }
      }

      .noRulesText {
        margin: 2.6rem 0;
        .bodyBase1Medium();
        text-align: center;
      }

      .canHelpContainer {
        margin-bottom: 1rem;
      }

      .formItem {
        margin-bottom: 2.6rem;

        &:first-child {
          margin-bottom: 1.2rem;
        }
      }

      .formItemRule {
        margin-bottom: 0;
      }

      .formulaHelper {
        margin-bottom: 2rem;
      }

      .switchWrapper {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        align-self: center;
        margin-bottom: 2rem;

        .switchLabel {
          .bodyBase2Medium();
          color: @palette-color-neutrals-dark;
          margin: 0 1rem;
        }
      }

      /**
       * This part is required to override each form field in case we have an error.
       * It's really specific to this form as we managed a custom global validator.
       * The goal is to avoid to have a red border error on each field if only one is needed.
       * Instead, we're using the global error message managed by the reject.
       */
      :global(.ant-form-item-has-error) {
        :global(.ant-input),
        :global(.ant-input-number) {
          border: 0.2rem solid @palette-color-border-normal;
          background-color: @palette-color-neutrals-white;

          &:hover {
            border-color: @palette-color-shades-navy-cloudy-blue;
            border-right-width: 0.2rem !important;
            box-shadow: none;
          }

          &:focus {
            border-color: @palette-color-primary-blue;
            border-right-width: 0.2rem !important;
            box-shadow: none;
          }
        }

        :global(.ant-input-number-focused) {
          border-color: @palette-color-primary-blue !important;
          border-right-width: 0.2rem !important;
          box-shadow: none;
        }

        :global(.ant-select) {
          :global(.ant-select-selector) {
            border-color: @palette-color-border-normal !important;
            background-color: @palette-color-neutrals-white !important;
            border-right-width: 0.2rem !important;

            &:hover {
              border-color: @palette-color-shades-navy-cloudy-blue !important;
              border-right-width: 0.2rem !important;
            }

            &:focus {
              border-color: @palette-color-primary-blue !important;
              border-right-width: 0.2rem !important;
              box-shadow: none;
            }
          }
        }

        :global(.ant-select-focused) {
          :global(.ant-select-selector) {
            border-color: @palette-color-primary-blue !important;
            border-right-width: 0.2rem !important;
            box-shadow: none;

            &:hover {
              border-color: @palette-color-primary-blue !important;
            }
          }
        }
      }

      :global(.ant-form-item-explain-error) {
        .bodyBase2Medium();
        color: @palette-color-secondary-red;
        margin-bottom: 1.6rem;
        text-align: center;
      }
    }

    .addRuleBtn {
      margin-bottom: 1.6rem;
      justify-content: center;
    }

    .disclaimer {
      margin-bottom: 1.6rem;
    }

    .saveRulesBtn {
      margin-bottom: 1.6rem;
      width: 100%;
      justify-content: center;
    }
  }
}
