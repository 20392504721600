@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  .title {
    .bodyBase1Medium();
    color: @palette-color-primary-blue;
  }

  .results {
    .item {
      margin-top: 1rem;

      display: flex;
      flex-direction: row;
      align-items: center;

      min-height: 6.8rem;

      cursor: pointer;
      padding: 1.4rem 2rem;

      border: 0.1rem solid @palette-color-border-normal;
      border-radius: 1.8rem;

      &:hover, &:active {
        border-color: @palette-color-primary-blue;
      }

      .description {
        .bodyBase1Bold();
        color: @palette-color-neutrals-dark;
      }

      .details {
        .bodyBase2Medium();
        color: @palette-color-neutrals-grey1;
      }

      mark {
        background-color: @palette-color-fill-highlight;
        padding: 0;
      }

      .icons {
        display: flex;
        flex-direction: row;
        align-items: center;

        & :global(.anticon) {
          .colorSvg(@palette-color-neutrals-grey2);
        }

        .parentIconWrapper {
          position: relative;
          width: 2.8rem;
          height: 2.8rem;
          margin-left: 1.1rem;

          .parentIcon {
            position: absolute;
            top: -0.2rem;
          }
        }
      }

      .content {
        margin-left: 1.3rem;
      }
    }
  }
}