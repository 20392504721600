@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';
@import '~@palette/styles/palette-theme.less';

.wrapper {
  padding: .8rem 2.4rem;
  border: .1rem solid @palette-color-border-light;
  border-radius: @border-radius-base;
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-bottom: .8rem;
  }

  .header {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    margin: 1.6rem 0 1.4rem;

    .entityIcon {
      display: flex;
      margin-right: .6rem;

      .icon {
        .colorSvg(@palette-color-primary-blue);
      }
    }

    .arrowIcon {
      margin-right: .2rem;
      .colorSvg(@palette-color-neutrals-grey2);
    }

    .title {
      margin-right: .4rem;
      margin-left: .2rem;
      color: @palette-color-neutrals-dark;
      .title2Bold();

      &.last {
        margin-right: 1.2rem;
      }
    }

    .date {
      margin: .8rem 0 .4rem .2rem;
      color: @palette-color-neutrals-grey1;
      .caption1();
    }
  }

  .footer {
    margin-bottom: 1.6rem;

    .userProfile {
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-start;
    }

    .userProfileNameContainer {
      margin-left: .8rem;
      line-height: 2.4rem;
      display: inline;
      align-items: initial;
    }

    .userProfileName {
      margin-left: 0;
      color: @palette-color-neutrals-dark;
      .bodyBase1Bold();
    }

    .contentText {
      margin-left: .5rem;
      color: @palette-color-neutrals-dark;
      .bodyBase1Medium();
      line-height: inherit;

      a {
        .bodyBase1Medium();
        line-height: inherit;
      }
    }
  }
}
