@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.modal {
  :global(.ant-modal) {
    max-width: 62rem;
  }
}

.wrapper {
  .description {
    .bodyBase1Medium();
    color: @palette-color-neutrals-dark;
  }

  .periodWrapper {
    width: 20rem;
    border: 0.1rem solid @palette-color-border-normal;
    border-radius: @border-radius-base;
    margin: 2rem auto;
    text-align: center;

    .editablePeriodYearContent {
      padding: 2rem;
    }

    .bodyBase1Medium();
    color: @palette-color-neutrals-dark;

    &:hover {
      border: 0.1rem solid @palette-color-primary-blue;
    }

    &.popoverVisible {
      border: 0.1rem solid @palette-color-primary-blue;
    }
  }
}
