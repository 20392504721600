@import '~@palette/styles/typographies.less';
@import '~@palette/styles/colors.less';
@import '~@palette/styles/palette-theme.less';

.wrapper {
  width: 28rem;
  max-height: 63rem;

  padding: 2rem;
  border-radius: @border-radius-base;
  border: 0.1rem solid @palette-color-border-normal;
  background-color: @palette-color-fill-table-group;

  .titleWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    .titleIcon {
      color: @palette-color-neutrals-dark;
      font-size: 1.8rem;
    }

    .title {
      margin-left: 1rem;
      overflow-wrap: anywhere;

      .title2Bold();
      color: @palette-color-neutrals-dark;
    }
  }

  .propSearch {
    margin-top: 1.2rem;
  }

  .table {
    margin-top: 1.2rem;
    max-height: 48rem;
  }

  .cell {
    width: 100%;
    word-break: break-all;
  }
}
