@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  max-width: 90rem;
  padding: 3rem 0 6rem;
  margin: 0 auto;

  .description {
    .inputLabel();
    color: @palette-color-neutrals-grey1;
  }

  .dependenciesWrapper {
    margin-top: 2.4rem;

    .dependencies {
      .dependency {
        + .dependency {
          margin-top: 2.4rem;
        }
      }
    }

    .addDependencyBtn {
      margin-top: 2.4rem;
    }
  }
}
