@import '~@palette/styles/colors.less';

.wrapper {
  .approvedWrapper {
    position: relative;

    .cancelApprovalBtn {
      margin-left: .5rem;
      height: 1.6rem;
      width: 1.6rem;
      background-color: @palette-color-fill-sky;
      padding: 0;
      border-radius: 0;
      display: none;
      position: absolute;
      left: .5rem;
      top: 50%;
      transform: translateY(-50%);

      .cancelApprovalBtnIcon {
        .colorSvg(@palette-color-neutrals-grey2);
      }

      &:hover {
        .cancelApprovalBtnIcon {
          .colorSvg(@palette-color-secondary-red);
        }
      }
    }

    &:hover {
      cursor: default;

      .cancelApprovalBtn {
        display: block;
        cursor: pointer;
      }
    }
  }

  .smallButton {
    padding: .6rem 1rem;
    height: auto;
  }
}
