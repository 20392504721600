@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';
@import '~@palette/styles/palette-theme.less';

.wrapper {
  display: flex;
  flex-flow: column wrap;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 0 0;

  @media(min-width: @screen-md-min) {
    flex-flow: row wrap;
    align-items: center;
  }
  
  &:not(:last-child) {
    border-bottom: .1rem solid @palette-color-border-light;
  }

  &:hover {
    .infosWrapper {
      .namePeriodWrapper {
        .nameScopeWrapper {
          .nameWrapper {
            .nameLabel {
              display: none;
            }

            .nameLink {
              display: block;
            }
          }
        }
      }
    }
  }

  .infosWrapper {
    display: flex;
    flex-flow: column nowrap;
    margin-right: 2rem;
    margin-bottom: 1rem;
    flex: 1;

    .namePeriodWrapper {
      display: flex;
      flex-flow: row wrap;

      .nameScopeWrapper {
        margin-right: 2rem;
        margin-bottom: .6rem;
    
        .nameWrapper {
          .nameLabel {
            .bodyBase2Medium();
            color: @palette-color-neutrals-dark;
          }

          .nameLink {
            display: none;
          }
        }
      }
    }

    .peopleWrapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
    
      .usersCountWrapper {
        margin-right: 1rem;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-end;
    
        .usersCount {
          .bodyBase2Medium();
          color: @palette-color-neutrals-grey1;
        }
    
        .usersCountIcon {
          margin-left: 0.4rem;
          .colorSvg(@palette-color-neutrals-grey2);
        }
      }
    
      .frequency {
        margin-right: 1rem;
        .bodyBase2Medium();
        color: @palette-color-neutrals-grey1;
        white-space: normal;
      }

      .scope {
        margin-right: 1rem;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-end;
      }
    }
  }

  .actionsWrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    flex: 1;
    justify-content: space-between;
    margin-bottom: 1rem;
    width: 100%;

    .stepContainer {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      flex: 1;
      margin-right: 2rem;

      @media(min-width: @screen-md-min) {
        justify-content: flex-end;
      }

      .step {
        .bodyBase2Medium();
        color: @palette-color-neutrals-grey2;
        white-space: nowrap;
        margin: .3rem 2rem .3rem 0;

        @media(min-width: @screen-md-min) {
          margin: .3rem 0;
        }
      }
    }

    .action {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0;
      height: auto;

      &.actionStep {
        margin: .3rem 0;

        @media(min-width: @screen-md-min) {
          margin: .3rem 0 .3rem 2rem;
        }
      }

      .actionLabel {
        margin-left: 0.4rem;
        .bodyBase2Medium();
      }

      &:hover {
        .actionIcon {
          .colorSvg(@palette-color-primary-blue);
        }

        .actionLabel {
          color: @palette-color-primary-blue;
        }
      }
    }
  }
}
