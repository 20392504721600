@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  :global(.ant-upload-picture-card-wrapper) {
    width: auto;

    :global(.ant-upload) {
      &:global(.ant-upload-select-picture-card) {
        margin-right: 0;
        margin-bottom: 0;

        &:hover {
          border-color: @palette-color-primary-blue;
        }
      }
    }
  }

  .alert {
    margin-top: 2rem;
    width: 100%;

    :global(.ant-alert-message) {
      display: flex;
      flex-direction: column;
    }
  }

  .uploadedPictureImgWrapper {
    position: relative;
    width: calc(100% - .4rem);
    height: calc(100% - .4rem);

    .uploadedPictureImg {
      width: 100%;
      border-radius: .8rem;
    }
  }

  .uploadWrapper {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: space-between;
    min-height: 4.5rem;

    .iconUpload {
      .colorSvg(@palette-color-primary-blue);
    }

    .labelUpload {
      margin-top: .8rem;
      color: @palette-color-primary-blue;
      .bodyBase2Medium();
    }
  }
}
