@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 0 2rem;

  .select {
    width: 100%;
    margin-top: 0.8rem;

    text-align: center;
  }

  .profile {
    margin-top: 2.5rem;
  }
}
