@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }

  .name {
    .bodyBase2Bold();
    color: @palette-color-neutrals-dark;
    width: 12rem;
    margin-right: 2rem;
    word-break: break-word;
  }

  .description {
    display: flex;
    flex-flow: column nowrap;
    .bodyBase2Medium();
    color: @palette-color-neutrals-grey1;
    max-width: calc(100% - 14rem);

    .scopes {
      margin-top: -.5rem;
      margin-bottom: .5rem;

      span {
        &:first-child {
          margin-left: 0;
        }
      }
    }

    .planName {
      margin-bottom: .5rem;
    }
  }

  .strategy {
    .bodyBase2MediumItalic();
    color: @palette-color-neutrals-grey1;
  }
}