@import '~@palette/styles/fonts.less';

.wrapper {
  position: relative;
  padding: 0 4rem 4rem;

  .topContentsWrapper {
    margin-bottom: 2.6rem;

    .alert {
      a {
        color: inherit;
        font-weight: @palette-font-weight-bold;
        text-decoration: underline;
      }
    }
  }
}
