@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  border: 0.1rem solid @palette-color-border-light;
  border-radius: 1.8rem;
  padding: 1.4rem 2.4rem;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .planInfosWrapper {
    display: flex;
    flex-direction: column;

    .planName {
      .headlineH3();
      color: @palette-color-neutrals-dark;
    }

    .planInfos {
      .bodyBase2Medium();
      color: @palette-color-neutrals-grey1;

      display: flex;
      flex-direction: row;
      align-items: center;

      .avatars {
        margin-left: 1rem;
      }
    }
  }
}
