@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  .section {
    margin-bottom: 2.4rem;

    .sectionTitle {
      .bodyBase1Bold();
      color: @palette-color-primary-blue;
      margin-bottom: 1.6rem;
    }

    .rights {
      .right {
        margin-bottom: 1.2rem;
        display: flex;
        align-items: center;

        .rightDescription {
          margin-left: 0.7rem;

          .inputLabel();
          color: @palette-color-neutrals-dark;
        }

        .scopeSelect {
          width: 52rem;
        }
      }
    }
  }

  .adminRights {
    margin-top: 4rem;

    .adminRightsLabel {
      .title1Bold();
      color: @palette-color-neutrals-dark;
    }

    .adminRightsSections {
      margin-top: 1.6rem;
    }
  }

  .icRights {
    margin-top: 4rem;

    .icRightsLabel {
      .title1Bold();
      color: @palette-color-neutrals-dark;
    }

    .icRightsSections {
      margin-top: 1.6rem;
    }
  }

  .saveButtonWrapper {
    margin-top: 2.8rem;

    .saveButton {
      width: 100%;
      justify-content: center;
    }
  }
}
