@import '~@palette/styles/colors.less';
@import '~@palette/styles/palette-theme.less';

.button {
  padding: 0;
  min-width: 2.4rem;
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 50%;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;

  :global(.anticon) {
    color: @palette-color-neutrals-grey1;
  }

  &:hover {
    background-color: @palette-color-primary-blue;

    :global(.anticon) {
      color: @palette-color-neutrals-white;
    }
  }
}
