@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';
@import '~@palette/styles/mixins.less';

.wrapper {
  .widgetSizeSmall();

  .content {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;

    .valueArea {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      margin: 0 1.5rem;

      .title {
        margin-bottom: 3rem;
        .bodyBase2Bold();
        color: @palette-color-neutrals-dark;
        text-align: center;
      }

      .amount {
        color: @palette-color-neutrals-dark;
        .headlineH3();
        margin-bottom: 1.6rem;
        text-align: center;
      }
    }
  }
}
