@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.modal {
  :global(.ant-modal) {
    max-width: 54rem;
  }

  .title {
    display: flex;
    flex-flow: column nowrap;

    span {
      .title2Medium();
      color: @palette-color-neutrals-grey1;
      margin-top: .4rem;
    }
  }
}

.content {
  .bodyBase1Medium();
  color: @palette-color-neutrals-dark;

  .rowFields {
    display: flex;
    align-items: flex-start;
    flex-flow: row nowrap;
    
    .rowField {
      flex: 1;
      width: 100%;

      &:not(:last-of-type) {
        margin-right: 2.4rem;
      }
    }

    .colFields {
      display: flex;
      justify-content: flex-start;
      flex-flow: column nowrap;
      flex: 1;

      .rowField {
        margin-bottom: .6rem;

        &.rowFieldDate {
          margin-bottom: .2rem;
        }

        &.hidden {
          display: none;
        }
      }

      .labelOnly {
        margin: 0;

        :global(.ant-form-item-control) {
          display: none;
        }
      }

      .addButton {
        height: 3rem;
        align-self: flex-start;
        margin-bottom: .8rem;
      }

      .disclaimer {
        color: @palette-color-neutrals-grey2;
      }

      .disclaimerDate {
        .caption1();
        color: @palette-color-neutrals-grey2;

        .editOriginalFormatLink {
          .caption1Italic();
          color: @palette-color-neutrals-grey2;
          padding: 0;
          margin: 0;
          height: auto;
          display: inline-block;

          &:hover {
            color: @palette-color-primary-blue;
          }
        }
      }
    }
  }
}
