@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';
@import '~@palette/styles/palette-theme.less';

.wrapper, .wrapper:hover, .wrapper:focus {
  background: @palette-color-shades-cg-blue;
  border-radius: @border-radius-base;
  border: none;
}

.wrapper {
  line-height: 1.8rem;
  height: 2.8rem;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  margin: auto;
  box-shadow: none;

  .bodyBase2Medium();
  color: @palette-color-shades-navy-sky-blue;
}