@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  .displayWrapper {
    .bodyBase1Medium();
    color: @palette-color-neutrals-dark;
    min-height: 4rem;
    white-space: pre-wrap;

    &.empty {
      color: @palette-color-neutrals-grey2;
    }

    &:hover {
      cursor: pointer;
      background-color: @palette-color-fill-sky;
    }

    &.disabled {
      cursor: default;
      background-color: transparent;
    }
  }
}

.input {
  margin-top: -1rem;
  margin-bottom: -1rem;
  margin-left: -1.8rem;
  width: calc(100% + 3.6rem);

  .bodyBase1Medium();
  color: @palette-color-neutrals-dark;

  &:global(.ant-input) {
    .bodyBase1Medium();
    max-width: none;
  }
}
