@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';
@import '~@palette/styles/mixins.less';
@import '~@palette/styles/palette-theme.less';

.wrapper {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;

  &:not(:last-child) {
    border-bottom: .1rem solid @palette-color-border-normal;
  }

  .infosWrapper {
    display: flex;
    flex-flow: column nowrap;
    margin-right: 2rem;
    flex: 2;

    .namePeriodWrapper {
      display: flex;
      flex-flow: row wrap;
      align-items: center;

      .nameScopeWrapper {
        margin-right: 2rem;
        margin-bottom: .6rem;

        .nameWrapper {
          display: inline-flex;
          align-items: center;

          .tag {
            margin-left: .8rem;
            text-transform: uppercase;
          }
        }
      }
    }

    .detailsContainer {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: space-between;

      .detailsWrapper {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        .usersCountWrapper {
          margin-right: 1rem;
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: flex-end;

          .usersCount {
            .bodyBase2Medium();
            color: @palette-color-neutrals-grey1;
          }

          .usersCountIcon {
            margin-left: 0.4rem;
            .colorSvg(@palette-color-neutrals-grey2);
          }
        }

        .frequency {
          margin-right: 1rem;
          .bodyBase2Medium();
          color: @palette-color-neutrals-grey1;
          white-space: normal;
        }

        .scope {
          margin-right: 1rem;
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: flex-end;
        }
      }

      .totalCommissionValue {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        .lastPeriodCommission {
          .bodyBase2Medium();
          color: @palette-color-neutrals-grey2;
          white-space: nowrap;
          margin-right: .4rem;
        }

        .lastPeriodCommissionValue {
          .bodyBase2Medium();
          color: @palette-color-neutrals-grey1;
        }
      }
    }
  }

  .actionsWrapper {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-end;
    flex: 1;

    @media(min-width: @screen-md-min) {
      flex-flow: row nowrap;
    }

    .actionButton {
      width: auto;
      padding: 0 0.3rem 0 1rem;
      display: flex;
      align-items: center;

      .settings {
        .colorSvg(@palette-color-neutrals-grey1);

        &:hover {
          .colorSvg(@palette-color-primary-blue);
        }
      }

      &:hover {
        .deletable {
          .colorSvg(@palette-color-secondary-red);
        }
      }
    }
  }
}
