.modal {
  :global(.ant-modal) {
    min-width: 65rem;
    max-width: 65rem;
  }
}

.dayPeriodTypeWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  margin-bottom: 2.8rem;

  .beginAtInput {
    margin-bottom: 0;

    :global(.ant-form-item-label) {
      padding-bottom: 2.7rem;
    }
  }

  .datePicker {
    width: 25rem;
  }

  .frequencyInput {
    width: 25rem;
    margin-left: 6rem;
    margin-bottom: 0;
  }
}
