@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  position: relative;

  padding: 0 4rem 4rem 4rem;

  .selectorActionsWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .selectorWrapper {
      display: flex;
      flex-flow: row wrap;
      align-items: center;

      .periodSelectorWrapper {
        margin-right: 1rem;
      }

      .timeElapsedWrapper {
        margin: 1rem 1rem 1rem 0;
        display: flex;
        flex-direction: row;
        align-items: center;

        .timeElapsedLabel {
          .bodyBase2Medium();
          color: @palette-color-neutrals-grey2;
          white-space: break-spaces;
        }

        .timeElapsedPercentage {
          .bodyBase2Medium();
          color: @palette-color-neutrals-grey1;
        }
      }
    }
  }

  .highlightZone {
    margin-top: 2.8rem;
  }

  .tabs {
    margin-top: 2.8rem;
  }
}
