@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;

  .dragIcon {
    margin-right: 0.6rem;
    .colorSvg(@palette-color-neutrals-grey2);
  }

  .removeButton {
    margin-left: 4.5rem;

    padding: 0;
    min-width: 2.4rem;
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 50%;
    background-color: @palette-color-neutrals-white;
    display: flex;
    align-items: center;
    justify-content: center;

    :global(.anticon) {
      .colorSvg(@palette-color-shades-navy-cloudy-blue);
    }

    &:hover {
      :global(.anticon) {
        .colorSvg(@palette-color-primary-blue);
      }
    }
  }
}
