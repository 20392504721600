@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  .bodyBase2Medium();
  color: @palette-color-neutrals-grey1;
  background-color: transparent;
  width: 100%;
  position: relative;

  cursor: pointer;

  .cellContent {
    width: 100%;
    height: 100%;
    padding: 1.3rem 1.4rem;
  }

  .input {
    padding-right: 2.3rem;
  }

  &.locked {
    cursor: default;
    padding: 1.3rem 1.4rem;
  }

  .lockedIndicator {
    display: none;
  }

  .resetOverwriteBtn {
    position: absolute;
    right: 0.5rem;
    bottom: 0.5rem;
    z-index: 1;
    height: 1.6rem;
    width: 1.6rem;
    background-color: transparent;
    padding: 0;
    border-radius: 0;

    display: none;

    .resetOverwriteBtnIcon {
      .colorSvg(@palette-color-neutrals-grey1);
    }

    &:hover {
      .resetOverwriteBtnIcon {
        .colorSvg(@palette-color-primary-blue);
      }
    }
  }

  &:hover {
    .lockedIndicator {
      display: flex;
    }

    .resetOverwriteBtn {
      display: block;
    }
  }
}
