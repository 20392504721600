@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  border: .1rem solid @palette-color-border-light;
  padding: 3.2rem;
  border-radius: 1.6rem;

  .titleContainer {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: .4rem;

    .title {
      color: @palette-color-neutrals-dark;
      .headlineH2();
    }

    .docBtn {
      padding: 0;
      height: auto;
      margin-top: .8rem;
    }
  }

  .description {
    color: @palette-color-neutrals-grey1;
    .bodyBase2Medium();
  }

  .spaceTop {
    margin-top: 2.4rem;
  }

  .codeCell {
    padding-top: 2.8rem;
    border: 0.1rem solid @palette-color-border-normal;
    border-radius: 0.8rem;
    background-color: @palette-color-fill-grey;
    overflow: hidden;
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.8rem;
  }

  .table {
    margin-top: 1.2rem;
  }
}
