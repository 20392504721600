.modal {
  :global(.ant-modal) {
    min-width: 65rem;
    max-width: 65rem;
  }

  .formItemsGroup {
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 2.8rem;

    .formItemInGroup {
      margin-bottom: 0;
      flex: 1;

      & + .formItemInGroup {
        margin-left: 2.4rem;
      }
    }
  }
}
