@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';
@import '~@palette/styles/palette-theme.less';

.wrapper {
  :global(.ant-drawer-content-wrapper) {
    width: 100% !important;

    @media(min-width: @screen-md-min) {
      width: 80rem !important;
    }
  }

  :global(.ant-drawer-content) {
    :global(.ant-drawer-header) {
      padding: 4.6rem 4rem 2rem;
      border-bottom: .1rem solid @palette-color-border-light;

      :global(.ant-drawer-header-title) {
        flex-direction: row-reverse;
        align-items: flex-start;

        :global(.ant-drawer-close) {
          margin-top: .5rem;
          position: absolute;

          :global(.ant-modal-close-x) {
            width: 2.8rem;
            height: 2.8rem;
            line-height: 2.8rem;
          }

          :global(.anticon) {
            .colorSvg(@palette-color-neutrals-grey1);
          }

          &:hover {
            :global(.anticon) {
              .colorSvg(@palette-color-neutrals-grey3);
            }
          }
        }

        :global(.ant-drawer-title) {
          .headlineH2-2();
          color: @palette-color-neutrals-dark;
          padding-right: 6rem;
        }
      }
    }

    :global(.ant-drawer-body) {
      padding: 0;
      display: flex;
      flex-direction: column;
    }
  }

  .content {
    height: 100%;
    padding: 2rem 4rem 0;
  }

  .loaderContainer {
    padding: 1rem;
  }

  .rawDataContainer {
    padding-bottom: 4rem;
  }
}
