.filterByUserSelectorDropdown {
  .userOption {
    width: 100%;
  }
}

.wrapper {
  .header {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;

    .filters {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 1.4rem;

      .filterByUserSelector {
        width: 28rem;
        min-width: 28rem;
        margin-right: 1.2rem;
        margin-bottom: 1rem;
      }

      .dealSearch {
        width: 28rem;
        min-width: 28rem;
        margin-bottom: 1rem;
      }
    }

    .actions {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 2.4rem;

      .actionLink {
        margin-left: 2rem;
        padding: 0;
        height: 2rem;
      }

      .editColumns {
        margin-left: 2rem;
      }
    }
  }
}
