@import '~@palette/styles/colors.less';
@import '~@palette/styles/palette-theme.less';

.wrapper {
  display: flex;
  flex-flow: row nowrap;
  height: 100%;
  width: 100%;

  .leftSide {
    width: 33rem;
    min-width: 33rem;
    display: flex;
    flex-direction: column;

    .header {
      padding: 4.9rem 4rem 0;
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: space-between;

      .title {
        color: @palette-color-neutrals-dark;
        margin-bottom: 2.4rem;
      }

      .createQuotaButton {
        margin-bottom: 2.4rem;
      }
    }

    .list {
      padding: 2rem 0;
      margin: 0 4rem;
      border-top: .1rem solid @palette-color-border-light;
      overflow-y: auto;
    }
  }

  .main {
    flex: 1;
  }

  @media(min-width: @screen-xl-min) {
    max-width: 100%;
    overflow: hidden;

    .main {
      max-width: 100%;
      overflow: hidden;
    }
  }
}
