@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  .title {
    .title1Bold();
    color: @palette-color-neutrals-dark;
    margin-bottom: 1.6rem;
  }

  .description {
    .bodyBase1Medium();
    color: @palette-color-neutrals-dark;
    margin-bottom: 1.6rem;
  }

  .connectorIdTypeWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .connectorIdTypeInput {
      width: 100%;
    }

    .renameTypeBtn {
      margin-left: 1rem;
    }

    &.withRenameInput {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      .connectorIdTypeWrapper {
        width: 100%;
      }
    }
  }

  .formulaHelper {
    margin-bottom: 2.8rem;
  }

  .actions {
    display: flex;
    flex-direction: column;
    align-items: center;

    .submitBtn {
      width: 100%;
      justify-content: center;
    }
  }
}
