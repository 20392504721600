@import '~@palette/styles/colors.less';

.button {
  padding: 0;
  min-width: 2.4rem;
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 50%;
  background-color: @palette-color-shades-navy-cloudy-blue;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: @palette-color-primary-blue;
  }
}
