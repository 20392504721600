@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';
@import '~@palette/styles/fonts.less';

.wrapper {
  position: relative;
  padding: 0 4rem 4rem;

  .profileWrapper {
    width: 100%;
    min-width: fit-content;
    padding: 1.4rem 2.4rem;

    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;

    border: 0.1rem solid @palette-color-border-normal;
    border-radius: 1.6rem;

    .avatarDisplayNameWrapper {
      margin: 1rem 0;
      display: flex;
      align-items: center;

      .avatarUpload {
        :global(.ant-avatar-string) {
          font-size: 0;
        }

        :global(.ant-btn) {
          bottom: -1rem;
          left: -1rem;
        }

        .avatar {
          :global(.ant-avatar-string) {
            font-size: 1.8rem;
          }
        }
      }

      .displayNameEmailWrapper {
        margin-left: 1.6rem;

        .displayName {
          .bodyBase2Bold();
          color: @palette-color-neutrals-dark;
        }

        .email {
          .caption1();
          color: @palette-color-neutrals-grey1;
        }
      }
    }

    .roleActionWrapper {
      margin: 1rem 0;

      display: flex;
      align-items: center;

      .logout {
        margin-left: 0.8rem;
      }
    }
  }
}
