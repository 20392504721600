@import '~@palette/styles/colors.less';

.wrapper {
  .button {
    justify-content: center;
    padding: 0;
    height: auto;

    &:hover {
      .icon {
        .colorSvg(@palette-color-primary-blue);
      }
    }
  }
}
