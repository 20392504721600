@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  .collapseContainer {
    margin-bottom: 2rem;

    .title {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      .bodyBase2Bold();
      color: @palette-color-secondary-orange;

      .icon {
        margin-left: .4rem;
        .colorSvg(@palette-color-secondary-orange);
      }
    }
  }
}
