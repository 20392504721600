@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  width: calc(100% - .2rem);
  padding: .5rem 0 .5rem 2rem;
  border-left: 0.2rem solid @palette-color-fill-antiflash-white;
  margin-left: 0.2rem;
  margin-top: -.5rem;
}
