@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  .title {
    .bodyBase2Bold();
    color: @palette-color-neutrals-dark;
  }

  .extraDetails {
    flex: 1;
    margin-left: 1rem;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    .totalWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: 2.8rem;
      flex: 1;
      justify-content: flex-end;

      .totalLabel {
        .bodyBase2Medium();
        color: @palette-color-neutrals-grey1;
      }

      .totalAmount {
        margin-left: 0.6rem;

        .bodyBase2Bold();
        color: @palette-color-neutrals-dark;
      }
    }
  }

  .periodsListWrapper {
    .period + .period {
      margin-top: 3rem;
    }
  }
}
