@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .emptyState {
    margin-top: 4.3rem;

    :global(.ant-empty-image) {
      height: 16.2rem;
    }
  }

  .activities {
    overflow: hidden;
    height: 100%;
    max-height: calc(100% - 8rem);
    transition: max-height 300ms ease;

    &.commentFormIsOpen {
      max-height: calc(100% - 19.9rem);
    }
  }

  .commentForm {
    margin-top: 3rem;
    width: calc(100% - 6.5rem);
  }
}
