@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  height: 100%;
  width: 100%;

  // Specific to Left Main Content Layout Page
  max-width: 100% !important;
  min-width: 110rem;

  .leftSideHeader {
    border-bottom: none !important;
  }

  .header {
    padding: 1.9rem 1.6rem 0;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;

    .title {
      margin-bottom: 1.8rem;
      color: @palette-color-neutrals-grey3;
      .headlineH2();
    }
  }
  
  .leftSideContent {
    padding: 2rem 0 !important;
    margin: 0 4rem;
    border-top: .1rem solid @palette-color-border-light;
  }

  .mainContent {
    padding: 3rem 5.6rem !important;
  }

  .main {
    padding: 1rem 0;
  }
}
