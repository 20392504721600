@import '~@palette/styles/colors.less';

.wrapper {
  .deleteBtn {
    height: 1.6rem;
    width: 1.6rem;
    background-color: transparent;
    padding: 0;
    border-radius: 0;

    .deleteBtnIcon {
      .colorSvg(@palette-color-neutrals-grey2);
    }

    &:hover {
      .deleteBtnIcon {
        .colorSvg(@palette-color-secondary-red);
      }
    }
  }
}
