@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  .container {
    &:not(:last-of-type) {
      border-bottom: .1rem solid @palette-color-border-light;
      margin-bottom: 2rem;
    }
  }

  .noPaymentsText {
    .bodyBase2Medium();
    margin-bottom: 2rem;
  }
}