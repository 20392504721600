@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  .title {
    .bodyBase2Bold();
    color: @palette-color-neutrals-dark;
  }

  .extraDetails {
    margin-left: 1rem;
    width: 100%;

    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    .totalWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: 2.8rem;

      .totalLabel {
        .bodyBase2Medium();
        color: @palette-color-neutrals-grey1;
      }

      .totalAmount {
        margin-left: 0.6rem;

        .bodyBase2Bold();
        color: @palette-color-neutrals-dark;
      }
    }
  }

  .periodsListWrapper {
    .period + .period {
      margin-top: 3rem;
    }
  }
}
