@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.modal {
  :global(.ant-modal) {
    max-width: 72rem;
  }

  .wrapper {
    padding: 0 1.3rem;

    .backBtn {
      position: absolute;
      top: 5rem;
      left: 4rem;
      z-index: 10;

      .colorSvg(@palette-color-neutrals-grey1);

      &:hover {
        cursor: pointer;
        .colorSvg(@palette-color-neutrals-grey3);
      }
    }

    .profileWrapper {
      position: absolute;
      top: 5rem;
      left: 0;
      right: 0;
      z-index: 5;
    }

    .panelsWrapper {
      display: grid;
      margin-top: 11.5rem;
    }
  }
}
