@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  .title {
    .headlineH3();
    color: @palette-color-neutrals-dark;
    display: flex;
    align-items: center;

    .icon {
      margin-right: .6rem;
      display: inline-flex;
      
      span {
        .colorSvg(@palette-color-neutrals-dark);
      }
    }
  }
}
