@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  position: relative;

  padding: 0 4rem;

  .charts {
    margin-top: 2.8rem;

    background-color: @palette-color-fill-cultured;
    border-radius: 1.6rem;
    height: 31.5rem;
    width: 100%;
  }

  .periodsList {
    margin-top: 2.8rem;
  }
}
