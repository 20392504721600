@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    .quotaInfosWrapper {
      display: flex;
      align-items: baseline;

      .quotaName {
        .headlineH2();
        color: @palette-color-neutrals-dark;
      }

      .infos {
        margin-left: 0.5rem;
      }
    }

    .actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 1.2rem;
      flex: 1;

      .actionBtn {
        margin-left: 1.2rem;
      }
    }
  }

  .table {
    margin-top: 2rem;
    height: 13rem;
  }
}
