@import '~@palette/styles/colors.less';

.wrapper {
  display: flex;
  flex-direction: column;

  &.isDraft {
    padding: 2rem 0;

    .alertContainer {
      margin-bottom: 1.5rem;
    }

    .draftPlansContainer {
      padding: 0 1rem;
      margin-left: .2rem;
    }
  }

  .draggableIsOver {
    background-color: @palette-color-fill-lavender;
  }
}
