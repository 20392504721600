@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  .content {
    border-left: 0;
    margin-left: 0;
    width: 100%;
    padding: 2rem 1rem;
    border-radius: 1.6rem;

    .subFolders {
      .subFolder {
        border: 0.1rem solid @palette-color-border-light;
        padding: 1.5rem 2rem;
      }
    }

    .resources {
      padding-left: 0;
      border-left: none;

      &.paddingRight {
        padding-right: 1.1rem;
      }

      .resource {
        &:not(:last-child) {
          border-bottom: .1rem solid @palette-color-border-light;
        }

        &::before {
          display: none;
        }
      }
    }
  }

  :global(.ant-collapse) {
    > :global(.ant-collapse-item:last-child) {
      border-radius: 0;
    }
  }
}
