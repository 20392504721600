.wrapper {
  display: flex;
  flex-direction: column;

  .tabs {
    width: 100%;
    margin: 0 auto;

    :global(.ant-tabs-nav) {
      margin-bottom: 2.4rem;
    }
  }

  .list {
    display: flex;
    flex-direction: column;

    .item {
      margin-bottom: .8rem;
    }
  }
}
