@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  overflow: auto;
  height: 100%;

  .variables {
    margin-top: 2rem;
  }

  .formulaScope {
    margin-top: 2rem;
  }
}
