@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.userForPreviewSelectorDropdown {
  .userOption {
    width: 100%;
  }
}

.wrapper {
  .displaySwitchWrapper {
    margin-bottom: 2.8rem;

    display: flex;
    flex-direction: row;
    align-items: center;

    .displaySwitch {
      margin-bottom: 0;
    }

    .displayLabel {
      margin-left: 0.7rem;

      .inputLabel();
      color: @palette-color-neutrals-dark;
    }
  }

  .actions {
    display: flex;
    flex-direction: column;
    align-items: center;

    .submitBtn {
      width: 100%;
      justify-content: center;
    }

    .deleteAction {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .inputLabel();
      color: @palette-color-neutrals-grey2;

      .deleteActionBtn {
        margin-left: 1rem;
      }
    }
  }

  .previewProjectionWrapper {
    margin-top: 2.8rem;

    .userForPreviewSelectorWrapper {
      margin-top: 1.5rem;

      display: flex;
      flex-direction: column;

      .userForPreviewSelectorLabel {
        .inputLabel();
        color: @palette-color-neutrals-grey1;
      }

      .userForPreviewSelector {
        margin-top: 1rem;

        width: 28rem;
        min-width: 28rem;
      }
    }

    .projectionChartWrapper {
      max-width: 62rem;
      margin: 1.5rem auto 0 auto;
    }
  }
}
