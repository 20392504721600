@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.modal {
  :global(.ant-modal) {
    min-width: 60rem;
    max-width: 60rem;
  }
}

.content {
  .bodyBase1Medium();
  color: @palette-color-neutrals-dark;
}
