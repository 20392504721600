.wrapper {
  overflow: auto;
  height: 100%;

  .summary {
    margin-top: 1.8rem;
  }

  .editPayoutSchedule {
    margin-top: 1.8rem;
  }
}
