@import '~@palette/styles/typographies.less';
@import '~@palette/styles/colors.less';

.wrapper {
  &.big {
    .title1Medium();
  }

  .badge {
    background-color: @palette-color-fill-light-green;
    border-radius: .4rem;
    padding: 0 .5rem;
    margin-left: .8rem;

    &.deletion {
      background-color: @palette-color-fill-light-red;
    }
  }
}
