@import '~@palette/styles/colors.less';

  .actionButton {
    width: auto;
    margin: 0;
    margin-left: 1rem;
  
    &.actionTrashButton {
      color: @palette-color-secondary-red;
    }
  
    .actionTrashIcon {
      .colorSvg(@palette-color-secondary-red);
    }
  }