.wrapper {
  ol, ul, li {
    list-style: inherit;
  }
  ol {
    list-style-type: decimal;
  }
  ul {
    list-style-type: disc;
  }
}
