@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';
@import '~@palette/styles/palette-theme.less';

.wrapper {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;

  .inputField {
    margin-bottom: 0;
  }

  .useTiersBtn {
    margin-top: 2rem;
  }

  .inputFieldError {
    .bodyBase2Medium();
    color: @palette-color-secondary-red;
    margin-top: 0.5rem;
  }

  .switchWrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    align-self: center;
    margin-bottom: 2rem;

    .switchLabel {
      .bodyBase2Medium();
      color: @palette-color-neutrals-dark;
      margin: 0 1rem;
    }
  }

  .bracketsWrapper {
    background-color: @palette-color-fill-antiflash-white;
    border-radius: @border-radius-base;
    padding: 1.5rem;
    margin-bottom: 2rem;
    width: 100%;
  }
}