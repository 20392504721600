.wrapper {
  overflow-y: auto;
  height: 100%;
  padding-bottom: 4rem;

  .dataConnectionsList {
    .dataConnectionsListItem + .dataConnectionsListItem {
      margin-top: 1rem;
    }
  }
}
