@import '~@palette/styles/typographies.less';

.wrapper {
  .headerCommissions {
    text-align: center;
    .bodyBase2Bold();
  }

  .headerCentered {
    text-align: center;
  }
}
