@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';
@import '~@palette/styles/palette-theme.less';

.wrapper {
  background-color: @palette-color-fill-table-group;
  border-radius: @border-radius-base;
  padding: 2rem;
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 2rem;

  .title {
    .bodyBase1Bold();
    color: @palette-color-neutrals-grey2;
    margin-bottom: 2.8rem;
  }

  .content {
    display: flex;
    flex-direction: row;
  }
}