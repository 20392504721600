@import '~@palette/styles/palette-theme.less';

.wrapper {
  position: relative;
  padding: 0 4rem 4rem;

  .header {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    margin-bottom: 1.4rem;

    .filters {
      flex: 1;
      display: flex;
      flex-flow: row wrap;
      align-items: flex-start;

      @media(min-width: @screen-lg-min) {
        flex-flow: row nowrap;
      }

      .resourceField {
        margin-right: 1.2rem;
        margin-bottom: 1rem;

        .resourceSearch {
          width: 28rem;
          min-width: 28rem;
        }
      }

      .otherFields {
        display: flex;
        flex-flow: row wrap;
        align-items: flex-start;
        flex: 0.9;

        .selectField {
          width: 26.8rem;
          min-width: 26.8rem;
          margin-right: 1.2rem;
          margin-bottom: 1rem;

          &.rangePicker {
            padding: .8rem;
            padding-right: 6rem;

            &:global(.ant-picker-range) {
              :global(.ant-picker-active-bar) {
                margin-left: .8rem;
              }
            }
          }

          &.selectPlanAlertWrapper {
            padding: 0.8rem 2rem;
            align-items: center;
            border-radius: @border-radius-base;
          }
        }
      }
    }
  }

  .highlightZone {
    margin-bottom: 1.4rem;
  }

  .actions {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    margin: 4rem 0 1rem 0;

    .sortSelect {
      width: 24rem;
      margin-bottom: 2rem;
      margin-right: 2rem;
    }

    .rightSide {
      display: flex;
      flex-flow: row wrap;
      align-items: center;

      .actionLink {
        padding: 0;
        height: 2rem;
        margin-bottom: 2rem;
      }
    }
  }
}
