.wrapper {
  padding-top: 4rem;
  width: 100%;

  .breadcrumb {
    margin-left: 4rem;
  }

  .actions {
    margin-left: auto;
  }
}
