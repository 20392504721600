@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.buttonWrapper {
  &.disabled {
    color: @palette-color-neutrals-grey1 !important;

    :global(.anticon) {
      .colorSvg(@palette-color-neutrals-grey1) !important;
    }
  }
}

.listWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;

  .button {
    margin-left: 0.6rem;

    &.disabled {
      color: @palette-color-neutrals-grey1 !important;

      :global(.anticon) {
        .colorSvg(@palette-color-neutrals-grey1) !important;
      }
    }
  }
}
