.container {
  width: 100%;
}

.wrapper {
  background-clip: padding-box;
  background-color: #fff;
  display: block;
  font-size: 14px;
  list-style: none;
  position: absolute;
  text-align: left;
  z-index: 20000;
  border: none;
  box-shadow: 0px 2px 5px #c3c3c3;
  border-radius: 4px;
  padding: 0;
  max-height: 250px;
  overflow: hidden;
  overflow-y: scroll;
}

.wrapper > li {
  cursor: pointer;
  min-width: 100px;
  padding: 2px 10px;

  &.active {
    background-color: #23b1b5;
  }
}

.wrapper > li.active {
  background-color: #23b1b5;
  color: #fff;
}
