.wrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;

  .detailsWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    .tagWrapper {
      + .tagWrapper {
        margin-left: .8rem;
      }
    }
  }

  .actionButton {
    height: auto;
    margin-left: .8rem;
    padding: .5rem 0;
  }
}
