@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  max-width: 90rem;
  padding: 3rem 0;
  margin: 0 auto;

  .title {
    .title1Bold();
    color: @palette-color-neutrals-dark;
    margin-bottom: 1.6rem;
  }

  .section {
    display: flex;
    flex-flow: column nowrap;
    margin-bottom: 2rem;

    .text {
      .bodyBase1Medium();
      color: @palette-color-neutrals-dark;
      margin-bottom: 1.6rem;
    }
  }
}