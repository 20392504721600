@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  display: flex;
  flex-flow: row nowrap;

  .cardWrapper {
    flex: 1;
    width: 100%;
    max-width: 20rem;
    height: 26rem;
    padding: 5.2rem 2rem 2.4rem 2rem;
    border: 0.2rem solid @palette-color-border-normal;
    border-radius: 1rem;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;

    + .cardWrapper {
      margin-left: 1.2rem;
    }

    .icon {
      .colorSvg(@palette-color-neutrals-grey2);
    }

    .label {
      margin-top: 0.4rem;

      .title2Bold();
      color: @palette-color-neutrals-dark;

      text-align: center;
    }

    .description {
      margin-top: 1.2rem;

      .inputLabel();
      color: @palette-color-neutrals-grey2;

      text-align: center;
    }

    .radio {
      margin-top: auto;
    }

    &:hover {
      border-color: @palette-color-shades-navy-cloudy-blue;

      .icon {
        .colorSvg(@palette-color-shades-navy-cloudy-blue);
      }
    }

    &.selected {
      border-color: @palette-color-primary-blue;

      .icon {
        .colorSvg(@palette-color-primary-blue);
      }
    }

    &.disabled {
      cursor: not-allowed;
    }
  }
}

:global(.ant-form-item-has-error) {
  .wrapper {
    .cardWrapper {
      border-color: @palette-color-border-input-error;

      .icon {
        .colorSvg(@palette-color-border-input-error);
      }
    }
  }
}
