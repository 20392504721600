@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  padding: 4rem 5.6rem;
  display: flex;
  flex-direction: column;
  height: 100%;

  .header {
    display: flex;
    flex-direction: column;
    margin-bottom: 2.6rem;

    .details {
      display: flex;
      flex-flow: row wrap;
      align-items: baseline;
      margin-top: .8rem;

      .plans {
        margin-right: .8rem;
      }
  
      .infos {
        color: @palette-color-neutrals-grey2;
      }
    }
  }

  .actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 2rem;
    max-width: 140rem;

    .yearPicker {
      border: 0;
      background-color: @palette-color-fill-sky;
    }

    .search {
      margin-left: 1.2rem;
      width: 18rem;
      min-width: 18rem;
    }

    .editDefaultValues {
      margin-left: 1.2rem;
    }

    .saveStatusWrapper {
      margin-left: auto;

      display: flex;
      flex-direction: row;
      align-items: center;

      .saveStatusLabel {
        margin-left: 1.2rem;
        .caption1();
        color: @palette-color-primary-teal;

        &.isSaving {
          color: @palette-color-neutrals-grey1;
        }
      }

      .saveStatusIcon {
        margin-left: 0.3rem;
      }
    }

    .lockButton {
      margin-left: 1rem;
    }

    .moreOptionsMenu {
      margin-left: 1rem;
    }
  }

  .archivedTag {
    margin-bottom: 2rem;
  }

  .outOfScopeWarning {
    margin-bottom: 2rem;
  }

  .content {
    flex: 1;
  }
}

.loaderContainer {
  padding: 2rem;
}
