@import '~@palette/styles/colors.less';

.wrapper {
  .content {
    width: 100%;
    height: 100%;
    padding: 2rem;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    background-color: @palette-color-fill-table-group;
    border-radius: 1.4rem;
    margin-bottom: 0;
  }
}
