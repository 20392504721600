@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';
@import '~@palette/styles/mixins.less';
@import '~@palette/styles/palette-theme.less';

.wrapper {
  .widgetSizeSmall();

  .content {
    flex: 1;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;

    .statementItem {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      padding: .8rem 1.2rem;
      background-color: @palette-color-fill-antiflash-white;
      margin-bottom: 1rem;
      border-radius: @border-radius-base;

      .periodName {
        .ellipsis();
        margin-right: .5rem;
      }

      .amount {
        color: @palette-color-primary-blue;
        .bodyBase1Bold();
        height: auto;
        padding: 0;
      }
    }
  }
}
