@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  .title {
    .title1Bold();
    color: @palette-color-neutrals-dark;

    margin-bottom: 1.6rem;
  }

  .futurePastAlert {
    margin-bottom: 2.4rem;
  }

  .nameAndPlan {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: space-between;

    .nameFormItem {
      flex: 1;
    }

    .planFormItem {
      margin-left: 2.4rem;
      flex: 2;
    }
  }

  .strategyWrapper {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: space-between;

    .strategyFormItem {
      flex: 1;
      max-width: 34rem;

      &:not:first-child {
        margin-left: 2.4rem;
      }

      :global(.ant-form-item-extra) {
        order: 1;

        margin-top: 0.2rem;
        padding: 0 1rem;
      }
    }

    .relativePeriodWithExample {
      margin-bottom: 2.8rem;

      .formItemAndExample {
        display: flex;
        flex-flow: row wrap;
        align-items: flex-start;
        justify-content: space-between;
        position: relative;

        .strategyFormItem {
          margin-top: 2.5rem;
          margin-bottom: 0;

          width: 10rem;
          max-width: 10rem;

          :global(.ant-form-item-label) {
            position: absolute;
            top: 0.3rem;
          }
        }

        .example {
          flex: 1;
          margin-left: 1.5rem;

          .bodyBase2Medium();
          color: @palette-color-neutrals-grey1;

          margin-top: 2.5rem;
          height: 5rem;
          display: flex;
          flex-direction: row;
          align-items: center;
        }
      }

      .description {
        margin-top: 0.2rem;
        padding: 0 1rem;

        .caption1();
        color: @palette-color-neutrals-grey1;
      }
    }
  }

  .dependencyOptionsCollapseTitle {
    display: flex;
    flex-direction: row;
    align-items: center;

    .title2Bold();
    color: @palette-color-neutrals-dark;

    .dependencyOptionsCollapseTitleIcon {
      .colorSvg(@palette-color-neutrals-dark);
      margin-right: 0.4rem;
    }
  }

  .dependencyOptions {
    .dependencyOption {
      background-color: @palette-color-fill-antiflash-white;
      border-radius: 2rem;
      padding: 2.4rem;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      + .dependencyOption {
        margin-top: 0.8rem;
      }

      .dependencyOptionInfos {
        width: calc(100% - 5rem);

        .dependencyOptionTitle {
          .bodyBase1Bold();
          color: @palette-color-neutrals-dark;
        }

        .dependencyOptionDescription {
          margin-top: 1.2rem;

          .bodyBase2Medium();
          color: @palette-color-neutrals-grey1;
        }

        &.typeOptionInfos {
          width: calc(100% - 18rem);
        }
      }

      .dependencyOptionForm {
        .dependencyOptionFormItem {
          margin-bottom: 0;

          .typeOptionSelect {
            width: 15rem;
          }
        }
      }
    }
  }

  .actions {
    margin-top: 2.4rem;

    display: flex;
    flex-direction: column;
    align-items: center;

    .submitBtn {
      width: 100%;
      justify-content: center;
    }

    .deleteAction {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .inputLabel();
      color: @palette-color-neutrals-grey2;

      .deleteActionBtn {
        margin-left: 1rem;
      }
    }
  }
}
