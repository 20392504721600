@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.loadingIcon {
  .colorSvg(@palette-color-primary-teal) !important;
}

.wrapper {
  padding-left: 0.5rem;

  .status {
    .title1Bold();
    color: @palette-color-primary-teal;
  }

  .description {
    margin-top: 0.5rem;

    .bodyBase2Medium();
    color: @palette-color-neutrals-dark;
  }
}
