@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.modal {
  :global(.ant-modal) {
    max-width: 72rem;
  }
}

.content {
  .bodyBase1Medium();
  color: @palette-color-neutrals-dark;

  p {
    margin-bottom: 2rem;

    &:last-child {
      margin-bottom: 0;
    }

    .title {
      .bodyBase1Bold();
      display: block;
      margin-bottom: .5rem;

      .icon {
        margin-right: .4rem;
        .colorSvg(@palette-color-neutrals-dark);
      }
    }

    .computeIcon {
      display: inline-flex;
      padding: 0;
      height: auto;
      justify-content: center;
    }
  }
}
