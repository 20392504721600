@import '~@palette/styles/colors.less';
@import '~@palette/styles/palette-theme.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  padding: 2.4rem 1.8rem 1.4rem 1.8rem;
  border-radius: 2.4rem;
  background-color: @palette-color-fill-grey;
  margin-bottom: 2.4rem;

  @media(max-width: @screen-md-max) {
    justify-content: center;
    flex-direction: column;
  }

  .infos {
    display: flex;
    flex-flow: row wrap;
    align-items: center;

    @media(max-width: @screen-md-max) {
      justify-content: center;
    }
  
    .periodName {
      .title1Medium();
      color: @palette-color-neutrals-grey1;
      padding: 0 .6rem;
      margin-bottom: 1rem;

      @media(max-width: @screen-md-max) {
        margin: 0 .8rem 1rem;
      }
    }

    .statementAmount {
      .title1Bold();
      color: @palette-color-neutrals-dark;
      padding: 0 .6rem;
      margin: 0 0 1rem 2.8rem;

      @media(max-width: @screen-md-max) {
        margin: 0 0 1rem;
      }
    }

    .statementPaidAmount {
      padding: 0 .6rem;
      margin: 0 .8rem 1rem;
    }
  }

  .actions {
    display: flex;
    flex-flow: row wrap;
    align-items: center;

    @media(max-width: @screen-md-max) {
      justify-content: center;
    }

    .actionLink {
      padding: 0 .6rem;
      margin: 0 .6rem;
      margin-bottom: 1rem;

      &:first-child {
        padding-left: 0;
      }
    }

    .approvalsListWrapper {
      padding: 0 .6rem;
      margin: 0 .6rem;
      margin-bottom: 1rem;
    }

    .buttonsWrapper {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      margin-left: .6rem;

      @media(max-width: @screen-md-max) {
        justify-content: center;
      }

      .actionButton {
        padding: 0 .6rem;
        margin-bottom: 1rem;
      }
    }
  }
}
