.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 0;
  z-index: 1001; /* To be higher than drawer, modals... */
  display: flex;
  flex-direction: column;
  align-items: center;
}
