@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  .bodyBase1Bold();
  background-color: @palette-color-banners-red;
  color: @palette-color-neutrals-white;
  text-align: center;
  padding: 0.5rem 1.5rem;
}
