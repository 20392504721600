.wrapper {
  .chartWrapper {
    width: 100%;
    padding-bottom: 50%;
    position: relative;

    .chart {
      position: absolute;
      top: 0;
      left: 0.5rem;
      width: calc(100% - 0.5rem);
      height: 100%;

      svg {
        overflow: visible;
      }
    }
  }
}
