@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';
@import '~@palette/styles/palette-theme.less';

.modal {
  :global(.ant-modal) {
    max-width: 90rem;

    @media(min-width: @screen-md-min) {
      min-width: 72rem;
    }

    :global(.ant-modal-content) {
      :global(.ant-modal-body) {
        margin-left: -1rem;
        width: calc(100% + 2rem);
        padding: 0 1rem;
      }

      :global(.ant-modal-footer) {
        padding: 1rem 0 0;
      }
    }
  }

  .switchItemParent {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 2.8rem;

    .switchItem {
      margin-bottom: 0;
    }

    .switchItemLabel {
      .inputLabel();
      color: @palette-color-neutrals-dark;
      margin-left: 1rem;
    }
  }
}
