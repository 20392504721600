.wrapper {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-end;

  .actionButton {
    padding: .4rem 0;
    height: auto;
    margin-left: 1.5rem;
  }
}
