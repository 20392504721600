@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  .infosWrapper {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;

    .nameLinkWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;

      .curvedLineIcon {
        margin-bottom: 1rem;

        .colorSvg(@palette-color-primary-blue);
      }

      .name {
        margin: 0 1.4rem;

        .bodyBase2Medium();
        color: @palette-color-neutrals-grey1;
      }
    }

    .totalWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: 2.8rem;

      .totalLabel {
        .bodyBase2Medium();
        color: @palette-color-neutrals-grey1;
      }

      .totalAmount {
        margin-left: 0.6rem;

        .bodyBase2Medium();
        color: @palette-color-neutrals-dark;
      }
    }
  }

  .dealsTable {
    margin-top: 1rem;
  }
}
