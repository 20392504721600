@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  .alert {
    margin-top: 1rem;
  }

  .listWrapper {
    display: flex;
    flex-direction: row;

    .icon {
      margin-top: .2rem;
      .colorSvg(@palette-color-secondary-light-teal);
    }

    .list {
      padding-left: 2.4rem;
      margin-bottom: 0;
      color: @palette-color-secondary-light-teal;

      li {
        margin-bottom: .8rem;
        list-style: disc;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &.doneListWrapper {
      .icon {
        .colorSvg(@palette-color-primary-blue);
      }

      .list {
        color: @palette-color-neutrals-dark;
      }
    }
  }
}
